.get-started {
  svg {
    width: 50px;
  }
  .card {
    background-color: inherit;
  }
  .card-footer {
    .btn-link {
      font-size: 1.25rem;
      @include media-breakpoint-up(lg) {
        padding: .25rem 1rem;
      }
    }
    .feature-content {
      @include media-breakpoint-up(lg) {
        padding: .515rem 1rem;
      }
    }
  }
}
