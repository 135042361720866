.related-articles {
  .swiper-container-initialized {
    .row { /* break base property */
      flex-wrap: nowrap !important;
    }
  }

  .card {
    @include media-breakpoint-up(md) {
      flex: 1 0 0%;
    }
    .image {
      @include media-breakpoint-down(md) {
        position: relative;
        height: 13rem;
      }
      img {
        @include media-breakpoint-up(lg) {
          min-height: 9rem;
          object-fit: cover;
        }
        @include media-breakpoint-down(md) {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    h3 {
      font-size: 1.525rem;
    }
  }
}
