.interior-split-banner {
  overflow: hidden;

  .content-wrapper {
    display: flex;
  }

  $contentWidth: 620px;

  .content {
    padding-right: 100px;
    width: $contentWidth;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    min-height: 360px;

    &.short {
      min-height: 330px;
    }
  }

  .breadcrumb-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .text {
    @include sectionPadding($desktopOffset: -10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }

  .sr-only {
    color: #000 !important;
  }

  .description {
    font-size: 1.08rem;

    p {
      font-size: inherit;
      margin-bottom: 0.75em;

      &:last-child {
        margin-bottom: 0;
      }

      &:empty {
        display: none !important;
      }

      + .wealth-hero-links {
        padding-top: 20px;
        align-items: flex-start;
      }
    }

    a {
      color: $darkTealColor;
    }

    .wealth-hero-links {
      &:first-child {
        margin-top: calc(0.75em + 20px);
      }
    }

    > p {
      max-width: 480px;
    }

    br {
      content: '';
      display: block;
      font-size: inherit;
      height: 0.75em;
    }
  }

  h1 {
    margin-top: -10px;
    margin-bottom: 0.38em;

    &:last-child {
      margin-bottom: 0;
    }

    sup {
      .sans-serif {
        position: relative;
        top: 3px;
      }
    }
  }

  .cta-wrapper {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: -20px;
  }

  .cta-button,
  .cta-text {
    margin-bottom: 20px;
  }

  .cta-text {
    a[href^='tel'] {
      white-space: nowrap;
    }
  }

  .cta-button {
    flex-grow: 0;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .cta-title {
    display: block;
    color: #6d6d6d;
    font-weight: 600;
    font-size: 0.85rem;
    line-height: 1;
    letter-spacing: 0.022em;
    margin-bottom: 7px;
    text-transform: uppercase;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .cta-link {
    font-size: 1.4rem;
    display: inline-block;
    font-weight: 600;
    white-space: nowrap;
    color: #4d4d4d;
    line-height: 1;

    &:hover {
      color: #161616;
    }
  }

  .hero-image-wrapper {
    flex-grow: 1;
  }

  .hero-image {
    $wrapperWidth: $contentMaxWidth - $sectionPaddingDesktop * 2;
    position: relative;
    height: 100%;
    width: calc(100% + (100vw - #{$wrapperWidth}) / 2);
    object-position: center center;

    img {
      margin: auto;
      object-fit: cover;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-position: inherit;
    }
  }

  @media (max-width: #{$contentMaxWidth}) {
    .content {
      width: 54%;
      padding-right: 60px;
      min-height: 320px;
      margin: 0;
      padding-top: 5px;

      &.short {
        min-height: 290px;
      }
    }

    h1 {
      margin-top: -22px;

      br {
        display: none;
      }
    }

    .hero-image {
      width: calc(100% + 60px);
    }
  }

  @include tablet {
    .content {
      padding-right: $contentPaddingTablet;
      min-height: 260px;

      &.short {
        min-height: 240px;
      }
    }

    .description {
      font-size: 1rem;
    }

    .cta-wrapper {
      margin-top: 27px;
    }
  }

  @include tabletPortrait {
    .content-wrapper {
      flex-direction: column;
    }

    .content {
      order: 2;
      width: 100%;
      padding: 0;
      min-height: 155px !important;
    }

    h1 {
      margin-top: -18px;
    }

    .description {
      > p {
        max-width: none;
      }
    }

    .cta-wrapper {
      margin-top: 23px;
      padding-bottom: 5px;
    }

    .cta-title {
      margin-bottom: 8px;
    }

    .hero-image-wrapper {
      width: calc(100% + #{$contentPaddingTabletPortrait * 2});
      margin-left: -$contentPaddingTabletPortrait;
    }

    .hero-image {
      width: 100%;
      padding-bottom: 36%;
      min-height: 180px;
    }
  }

  @include mobile {
    .content,
    .text {
      min-height: 0;
    }

    .cta-wrapper {
      display: block;
    }

    .cta-button {
      margin-right: 0 !important;

      .btn-primary,
      .btn-outline-primary {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}

.breadcrumb-wrapper {
  background-color: transparent;
  padding-top: 20px;

  .breadcrumb {
    margin-bottom: -3px !important;
    margin-left: 0;
    padding: 0 !important;
    font-size: 14px;
    background-color: transparent !important;

    li {
      margin-bottom: 0;
    }

    a {
      font-size: inherit !important;
      color: $darkTealColor;
    }
  }

  .breadcrumb-item {
    padding: 0 !important;

    &:before {
      display: none !important;
    }

    &:not(:last-child):after {
      content: '>';
      display: inline-block;
      margin-left: 7px;
      margin-right: 8px;
      color: #7b7b7b;
      font-size: 1em;
    }

    + .breadcrumb-item {
      padding-left: 0.3em;
      margin-bottom: 3px;
    }

    .ltr-underline {
      position: relative;

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background-size: 0 1.5px !important;
      }

      &:hover:after {
        background-size: 102% 1.5px !important;
      }
    }
  }

  @include tabletPortrait {
    .breadcrumb {
      font-size: 13px;
    }

    .breadcrumb-item {
      &:not(:last-child):after {
        margin-left: 3px;
        margin-right: 7px;
      }
    }
  }
}
