.component-client-stories {
  .client-stories-shadow {
    @include media-breakpoint-down(md) {
      box-shadow: 0 0 1rem $gray-800;
    }
  }
  .overlay {
    background-color: rgba(36, 125, 126, 0.925);
    .content {
      height: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin: -4rem 0;
    }
    @include media-breakpoint-down(md) {
      margin: -4rem 0 0;
    }
  }
  h2 {
    line-height: 1.1;
  }
  .video-title {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.65px;
    line-height: 1.3;
    @include media-breakpoint-up(lg) {
      min-height: 42px; /* hack: force header height */
    }
    @include media-breakpoint-down(md) {
      font-size: 0.875rem;
    }
  }
  .card-body {
    flex: 0 1 auto; /* hack: adjust footer vertical alignment */
    .video-container {
      img {
        box-shadow: 0.15rem 0.25rem 0.75rem $gray-800;
        @include media-breakpoint-up(lg) {
          img {
            width: 100%;
          }
        }
      }
      span {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $white;
        color: $black;
        font-weight: 500;
        font-size: 0.8125rem;
      }
    }
  }
  .client {
    img {
      width: 80px;
      height: 80px;
    }
    .name {
      font-size: 0.875rem;
      font-weight: 500;
      @include media-breakpoint-down(md) {
        font-size: 0.8125rem;
      }
    }
    .position {
      font-size: 0.875rem;
      @include media-breakpoint-down(md) {
        font-size: 0.625rem;
      }
    }
  }

  // testimonials container
  &.testimonials {
    .client-stories-shadow {
      .col {
        text-align: center;
        .media-body {
          .quotation {
            justify-content: center;
          }
        }
        img + .media-body {
          .quotation {
            justify-content: start;
          }
        }
        .client {
          justify-content: center;
        }
        @include media-breakpoint-down(md) {
          .media > img {
            margin: auto;
          }
        }
      }
      .overlay + .col {
        text-align: left;
        @include media-breakpoint-up(lg) {
          .client {
            justify-content: flex-start;
          }
          img + .media-body {
            .name-position {
              text-align: left;
            }
          }
          .name-position {
            text-align: left;
          }
        }
        @include media-breakpoint-down(md) {
          .media > img {
            margin: initial;
          }
          .client {
            justify-content: start;
          }
        }
      }
      .btn-success {
        align-self: baseline;
      }
    }
    .slick-testimonials {
      .slick-dots {
        list-style: none;
        padding-left: 0;
        display: flex;
      }
    }
    .testimonial-item {
      & > .media > img {
        max-width: 165px;
        width: 100%;
        @include media-breakpoint-up(lg) {
          margin-right: 1.5rem;
        }
      }
      .quotation {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.25;
        @include media-breakpoint-down(md) {
          font-size: 1.175rem;
        }
        .svg-wrapper,
        svg {
          color: $white;
          align-self: start;
          margin-right: 0.75rem;
        }
        .quote {
          & + .svg-wrapper,
          & + svg {
            align-self: flex-end;
          }
          & + svg {
            width: 30px;
          }
        }
      }
      .quote-adjustment {
        margin-left: 2.5rem;
      }
    }
    .slick-dots {
      margin-top: 4rem;
      justify-content: center;
      @include media-breakpoint-down(md) {
        margin-top: 1rem;
      }
      button {
        border: none;
        border-radius: 50%;
        background-color: #a6a7a7;
        font-size: 0;
        width: 0.85rem;
        height: 0.85rem;
        padding: 0;
        margin: 0 0.55rem;
      }
      .slick-active {
        button {
          background-color: $gray-200;
        }
      }
    }
  }
}
