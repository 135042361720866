.card-group {
  @include media-breakpoint-down(md) {
    display: initial;
  }
}

.content-box-row {
  .card-body {
    padding: 2em;

    h3 {
      text-wrap: pretty;

      &.text-lg-center,
      &:not(.h4) {
        font-size: 1.7rem;
        line-height: 1.12;
      }

      &:first-child {
        margin-top: -0.2em !important;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .card-footer {
    padding: 0 2em 2em 2em;
  }
}
