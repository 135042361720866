@mixin count($num) {
  &:first-child:nth-last-child(#{$num}),
  &:first-child:nth-last-child(#{$num}) ~ .card {
    @content;
  }
}

.get-started {
  .section-top {
    .text {
      max-width: 960px;
    }
  }

  .section-content {
    @include sectionContentSpacing(-10px);
  }

  .card-group {
    justify-content: center;
  }

  .card {
    box-shadow: none !important;
    background-color: transparent !important;
    margin-right: 20px;

    .svg-wrapper {
      margin-bottom: 15px;
    }

    @include count(2) {
      max-width: 360px;
    }

    @include count(3) {
      max-width: 310px;
    }

    @include count(4) {
      max-width: 270px;
      margin-right: 15px;
    }

    &:last-child {
      margin-right: 0 !important;
    }

    .card-body,
    .card-footer {
      padding: 0 !important;
    }

    .card-footer {
      margin-top: 5px;
    }

    .btn-primary,
    .btn-outline-primary {
      font-size: 0.8em;
    }

    a[href*='tel'] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  @include tabletPortrait {
    .section-content {
      margin-bottom: 0;
      display: block !important;
    }

    .card {
      margin-right: 0 !important;
      max-width: none !important;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .break-single {
        display: none;
      }

      .btn-primary,
      .btn-outline-primary {
        width: 100%;
        max-width: 255px;
      }
    }
  }
}
