// Apply a theme color to all descendant headings
$text-colors: map-merge($theme-colors, ("white": $white, "body": $body-color, "muted": $text-muted));
@each $color, $value in $text-colors {
  .headings-#{$color} {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      &:not(.btn) {
        color: $value !important;
      }
    }
  }
}