#live-chat-fixed-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #239698;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(calc(100% + 50px));
  animation: slideIn 0.4s ease;
  animation-fill-mode: forwards;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 6px 0px, rgba(0, 0, 0, 0.1) 0 2px 32px 0;
  transition: background-color 0.2s ease 0s;
  cursor: pointer;
  z-index: 999;

  &:hover {
    background-color: #2ca9ab;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  path {
    fill: #fff;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(calc(100% + 50px));
  }
  100% {
    transform: translateY(0);
  }
}

.lp-window-root > .lp_maximized {
  border: none !important;
}

.lp_survey_container {
  outline: none !important;
}

.lp_desktop .lp-window-root > .lp_maximized > .lp_header .lp_title {
  padding-left: 8px !important;
  position: relative;
  top: -1px;
}

.lpc_layout.lpc_layout_maximized.lpc_desktop {
  padding: 10px 8px !important;
}

.lpc_message-area_system.lpc_desktop {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 5px !important;
  padding-bottom: 12px !important;
}

.lp_chat_line_wrapper + .lpc_message-area_system.lpc_desktop {
  margin-bottom: 10px !important;
}

#lp_line_0 {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

#lp_line_0:last-child {
  border-bottom: none !important;
}

.lpc_message-area__timestamp_agent,
.lpc_message-area__timestamp_visitor {
  margin-bottom: 2px !important;
  color: #9a9a9a !important;
  font-weight: 400 !important;
}
