$pSpacing: 8px;
$tableSpacing: 16px;

.footnote,
.disclaimer {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  font-size: 11px;
  line-height: 1.5;

  &:last-child {
    margin-bottom: 0;
  }

  p,
  table,
  ul,
  ol {
    font-size: inherit;
  }

  p,
  table {
    &:last-child {
      margin-bottom: 0 !important;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: $pSpacing;
  }

  table {
    margin-bottom: $tableSpacing;
    margin-top: $tableSpacing - 2px;
    border-color: #cccccc !important;
    border: none;
  }

  td {
    padding: 5px;
    border-color: inherit;
  }

  br,
  & ~ br {
    content: '';
    display: block;
    height: $pSpacing + 4px;

    @at-root {
      .footnote p + br,
      .disclaimer p + br,
      p.footnote + br,
      p.disclaimer + br {
        margin-top: -$pSpacing;
      }
    }

    @at-root {
      .footnote table + br,
      .disclaimer table + br,
      table.footnote + br,
      table.disclaimer + br {
        margin-top: -$tableSpacing + 4px;
      }
    }
  }
}

p.footnote,
p.disclaimer {
  margin-bottom: $pSpacing;
}

table.footnote,
table.disclaimer {
  margin-bottom: $tableSpacing;
  margin-top: $tableSpacing;
}
