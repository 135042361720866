.noe-display-medium,
.noe-display-bold {
  font-family: 'Noe Display Medium', serif;
  font-weight: 500;
}

.font-size-xl {
  font-size: $font-size-xl !important;
}

.font-size-lg {
  font-size: $font-size-lg !important;
}

.font-size-sm {
  font-size: $font-size-sm !important;
}

.footnote {
  font-size: 0.6875rem;
}
