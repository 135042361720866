.checkmark-list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-width: 750px;

  &.hidden {
    opacity: 0;
  }

  > li {
    display: flex;
    width: 100%;
    margin-bottom: 2.6em !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: block;
      background-size: cover;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 13px;
      margin-top: 2px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 94.28 94.28' fill='%23008080' %3E%3Cpath d='M47.14 0a47.14 47.14 0 1047.14 47.14A47.21 47.21 0 0047.14 0zm0 9.43A37.71 37.71 0 119.43 47.14 37.64 37.64 0 0147.14 9.43zm23.33 16.32a4.7 4.7 0 00-3.33 1.39l-30 30-10-10a4.72 4.72 0 00-6.67 6.67l13.34 13.33a4.71 4.71 0 006.66 0L73.8 33.81a4.73 4.73 0 00-3.33-8.06z' /%3E%3C/svg%3E");
    }

    ul {
      margin-top: 0.6em;
      margin-left: 0.8em;
      list-style: initial;
    }
  }

  .card-lead {
    font-size: 1.08em;
    padding-bottom: 1px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.two-col {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    columns: 2;
    column-gap: 70px;
    max-width: 1100px;

    li {
      break-inside: avoid;
      page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
    }
  }

  @include tabletPortrait {
    li {
      margin-bottom: 2.4em !important;

      &:before {
        width: 20px;
        height: 20px;
      }
    }

    &.two-col {
      columns: initial;
      left: 0 !important;
      column-gap: 40px;
    }
  }
}

// Page Builder WYSIWYG
.check-list {
  list-style: none;
  margin-left: 0 !important;
  padding-left: var(--list-indent, 0px) !important;

  > li {
    display: flex;
    width: 100%;

    &:last-child {
      padding-bottom: 0;
    }

    &:before {
      // We have to use unquote for some properties as
      // node-sass doesn't support native CSS round function
      content: '';
      width: var(--check-icon-size);
      width: unquote('round(var(--check-icon-size), 1px)');
      height: var(--check-icon-size);
      height: unquote('round(var(--check-icon-size), 1px)');
      display: block;
      background-size: cover;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: var(--check-icon-offset);
      position: relative;
    }
  }
}

.check-list-teal {
  --check-icon-size: var(--check-size, 1.22em);
  --check-icon-offset: var(--check-offset, 0.9em);

  > li {
    padding-bottom: 0.1em;

    &:before {
      margin-top: 3px;
      background-image: url('data:image/svg+xml,%3Csvg width=%2726%27 height=%2726%27 viewBox=%270 0 26 26%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M13 0C5.82 0 0 5.82 0 13s5.82 13 13 13 13-5.82 13-13S20.18 0 13 0Zm4.875 7.465L20.16 9.75l-8.785 8.785L5.84 13l2.285-2.285 3.25 3.25 6.5-6.5Z%27 fill=%27teal%27/%3E%3C/svg%3E');
    }
  }
}

.check-list-gold {
  --check-icon-size: var(--check-size, 1.65em);
  --check-icon-offset: var(--check-offset, 0.85em);

  > li {
    padding-bottom: 0.4em;

    &:before {
      top: calc(var(--check-icon-size) * -0.07);
      background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.45482 22.2289L16.9428 33.2078L45 0L20.8735 45H13.8253L0 28.3283L7.45482 22.2289Z' fill='%23EEB123'/%3E%3C/svg%3E");
    }
  }
}
