.component-homepage-feature-box-with-bg-image {
  .left {
    a {
      color: $white;
      &:not(.btn) {
        &:hover {
          text-decoration: none;
        }
      }
      &:not(.btn) {
        text-decoration: underline;
      }
    }
  }
  .ltr-underline {
    @extend %link-underline-base;
    font-size: 0.875rem;
    font-weight: 500;
    &:hover {
      color: $green;
    }
  }
}

.bg-image-promo {
  background-size: cover;
  background-position: center;
  background-color: #1b1f1f;

  &.no-image {
    > .row > div:first-child {
      max-width: none;
      flex-grow: 1;
      margin-right: 15px;
    }
  }
}

// Some inner pages use this component as well
body:not(.homepage)
  #main
  > .bg-white
  + .component-homepage-feature-box-with-bg-image {
  padding-top: 0 !important;
}
