.full-width-feature-highlights-content-boxes {
  svg {
    max-width: 75px;
  }
  .card-group {
    @include media-breakpoint-down(sm) {
      display: initial !important;
    }
  }
  .card-footer {
    a {
      margin-top: 1rem;
    }
  }
  .box-columns {
    @include media-breakpoint-up(md) {
      &.even .card {
        flex: 1 0 45%;
      }
    }
    @include media-breakpoint-up(xl) {
      &.odd .card {
        flex: 0 0 30.5%;
      }
    }
    @include media-breakpoint-only(lg) {
      &.odd .card {
        flex: 0 0 29.5%;
      }
    }
    @include media-breakpoint-only(md) {
      &.odd .card {
        flex: 0 0 28.5%;
      }
    }
  }
  .icon-align-left {
    display: flex;
    align-items: flex-start;
    padding: 1rem 0 0.5rem;
    img {
      margin-right: 1rem !important;
    }
    .content {
      flex-grow: 1;
      flex-basis: 0%;
    }
  }
  .icon-align-top {
    text-align: center;
    padding: 0;
    img {
      margin-top: 3rem;
      margin-bottom: 1.5rem;
    }
  }
  .ltr-underline {
    @extend %link-underline-base;
    // font-size: .875rem;
    font-size: 1em;
    font-weight: 500;
    &:hover {
      color: $green;
    }
  }
}
