// Mobile app download links
a.android,
a.ios,
a.macintosh {
  visibility: hidden;
  display: inline-block;

  @at-root body.is-desktop & {
    visibility: visible;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

body {
  &.ios {
    a.ios,
    a.macintosh {
      visibility: visible;
    }

    a.android {
      display: none;
    }
  }

  &.android {
    a.ios,
    a.macintosh {
      display: none;
    }

    a.android {
      visibility: visible;
    }
  }
}

.invisible {
  a.ios,
  a.macintosh,
  a.android {
    visibility: hidden !important;
  }
}
