%lead-base {
  text-transform: uppercase;
  color: $gray-700;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.lead {
  @extend %lead-base;
  font-size: 0.975rem;
  @include media-breakpoint-down(md) {
    font-size: 0.85rem;
  }
}

.lead-sm {
  @extend %lead-base;
  font-size: 0.75rem;
}
.lead-summary {
  @extend %lead-base;
  font-size: 0.825rem;
}

.card-lead {
  text-transform: uppercase;
  font-size: 1.12rem;
  padding-bottom: 0.3rem;
  display: block;
}
