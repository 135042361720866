@media print {
  html {
    font-size: 17px;
  }

  body {
    *,
    .section,
    .content-section {
      background-color: transparent !important;
    }
  }

  * {
    color: #000 !important;
  }

  // Always hidden while printing
  #header,
  #footer,
  #blog-back,
  #blog-tags,
  #blog-related-posts,
  .blog-article-banner,
  .blog-top,
  .blog-headline-wrapper,
  .btn-print,
  .print-button,
  .post-meta,
  .post-toc-wrapper,
  .post-side-toc-wrapper {
    display: none !important;
  }

  // Styles for printing specific page sections
  body.printing-section {
    #blog-category,
    h1.post-title {
      display: none !important;
    }

    .printing-active {
      margin: 0;
    }

    .btn-print {
      + :is(h2, h3, h4, h5) {
        margin-top: 0;
      }
    }
  }

  #blog-intro {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-bottom: 0 !important;
  }

  .section,
  .content-section,
  .blog-section {
    padding: 30px !important;
  }

  .content-wrapper {
    max-width: none !important;
    padding: 0 !important;
  }

  blockquote {
    border: none;
  }

  .post-content {
    margin: 0 !important;
  }

  .blog-callout-box {
    margin: 0;
    background-color: transparent;
  }

  .print-button + h2 {
    margin-top: 0;
    display: block;
    font-size: 30px;
    margin-bottom: 40px;
    border-bottom: 2px solid #000;
    padding-bottom: 20px;
  }

  .post-content {
    blockquote {
      &:before {
        display: none;
      }
    }

    .post-blockquote {
      border-left: 3px solid #000;
      padding-left: 15px;
    }
  }
}
