%hide-container {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
}

%show-container {
  position: initial;
  visibility: visible;
  opacity: 1;
  height: auto;
}

%hide-container-down-sm {
  @include media-breakpoint-down(sm) {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    height: 0;
  }
}

%show-container-down-sm {
  @include media-breakpoint-down(sm) {
    position: initial;
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}

.browse-by-topic {
  // @at-root #faq-topic-list + & {
  //   border-top: 1px solid #efefef;
  // }

  &.faq-browse-by-topic {
    .section-top {
      margin-bottom: 25px !important;
    }
  }

  .topics {
    strong {
      text-transform: uppercase;
      font-size: 0.875rem;
      color: $gray-700;
    }

    a {
      color: $gray-500;
      font-size: 0.875rem;
    }

    .topic-toggle {
      position: absolute;
      top: 1.5rem;
      right: 0.5rem;

      @include media-breakpoint-down(sm) {
        top: 0.45rem;
      }

      span {
        color: $green;
        opacity: 1;
        &:after {
          content: '➜';
        }
      }

      .close {
        transform: rotate(90deg);
      }

      &[aria-expanded='true'] .open {
        display: none;
      }

      &[aria-expanded='false'] .close {
        display: none;
      }

      & + .dropdown {
        @extend %hide-container-down-sm;
      }

      &[aria-expanded='true'] {
        & + .dropdown {
          @extend %show-container-down-sm;
        }
      }
    }

    @media (max-width: 767px) {
      .active {
        strong {
          padding-bottom: 0.3rem;
          border-bottom: 2px solid $black;
          margin-bottom: 0.25rem;
          display: inline-block;
        }
      }
    }

    // detail
    &.detail {
      .dropdown {
        @extend %hide-container;
      }

      .active {
        & ~ .dropdown {
          @extend %show-container;
        }
      }
    }

    > .row {
      > .col-md-3:last-child {
        padding-bottom: 0 !important;
      }
    }
  }
}

.faq-category {
  border-color: transparent !important;
}

.browse-by-topic-nav-item {
  button {
    text-transform: capitalize;
    font-size: 0.875rem;
    color: #4c4c4c;
    font-weight: 500;

    &[aria-expanded='true'] {
      strong {
        padding-bottom: 0.3rem;
        border-bottom: 2px solid $black;
        margin-bottom: 0.25rem;
        display: inline-block;
      }
    }

    @include media-breakpoint-down(sm) {
      span {
        color: $green;
        opacity: 1;
        float: right;
        &:after {
          content: '➜';
        }
      }

      .close {
        transform: rotate(90deg);
      }

      &[aria-expanded='true'] .open {
        display: none;
      }

      &[aria-expanded='false'] .close {
        display: none;
      }
    }
  }
}
