// .related-articles {
//   .content-wrapper {
//     background: yellow;
//   }

//   .text-center {
//     background-color: blue;
//   }
// }

$margin: 15px;

.card-wrapper {
  width: calc(100% + #{$margin * 2});
  margin-left: -$margin;
  justify-content: center;
  margin-bottom: -$margin * 2;

  .card {
    border: none !important;
    margin: 0 $margin $margin * 2 $margin !important;
    width: 100%;
  }
}
