.skip-links {
  font-weight: 600;
  background-color: $primary !important;
  text-align: center;

  &:focus-within {
    padding: 10px;
  }

  a {
    display: inline-block;
    padding: 0.5rem;
    color: $white;
    background-color: $primary !important;

    &:focus {
      text-decoration: underline;
      text-decoration-color: rgba(255, 255, 255, 0.4);
      display: inline-block;
      padding: 5px 18px;
      border-radius: 2px;
      border: 2px dashed #75adad;
      outline: none;
      box-shadow: none !important;
    }
  }
}
