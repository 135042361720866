.standard-features {
  + a {
    @include media-breakpoint-up(md) {
      margin-left: 2.5rem;
    }
  }
  .media {
    .svg-wrapper {
      margin-right: 1rem;
      svg {
        fill: $green;
      }
    }
  }
}
