label {
  font-weight: 600;
  font-size: 0.88rem;
  color: #333;
  text-transform: uppercase;

  &[for*='QuerystringPageUrlFormComponent'] {
    display: none !important;
  }
}

.component-request-form {
  .submit-button {
    display: block;
    margin: 1.5rem auto 0.75rem;
  }
}

.ktc-default-section,
.form-section {
  .form-field {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  & + .submit-button {
    margin: 1.5rem auto 0.75rem auto;
    display: block !important;
  }

  .explanation-text {
    font-size: 0.85em;
    margin-top: 10px;
    margin-bottom: -0.25rem;
  }
}

.form-field {
  &.no-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.reCAPTCHA {
  margin-top: 15px;

  @at-root .form-field + .reCAPTCHA {
    margin-top: 20px;
  }

  .editing-form-control-nested-control {
    min-height: 78px;
  }

  label {
    @extend .sr-only;
  }

  div[id^='google-recaptcha-form'] {
    transition: opacity 0.08s linear;

    &:empty {
      opacity: 0;
    }

    > div {
      margin: auto;
    }
  }

  .field-validation-error {
    text-align: center;
  }
}

.field-validation-error {
  color: #b73434;
  font-size: 0.82rem;
  font-weight: 500;
  font-style: normal;
  padding-top: 5px;
  margin-top: 0.5em;
}

select.form-control {
  appearance: none;
  -webkit-appearance: nont;
  background: #fff
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12'%3E%3Cpath d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z' fill='%239a9a9a'/%3E%3C/svg%3E")
    no-repeat right 1rem center/10px 10px;
  border: 1px solid #cecece;
  vertical-align: middle;

  &:hover,
  &:focus {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12'%3E%3Cpath d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z' fill='%23737373'/%3E%3C/svg%3E");
  }
}
