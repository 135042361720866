@use 'sass:math';

@import 'search-agent-profile';
@import 'search-branch-location';

/* --------------------------- */
/* Search Overlay */
/* --------------------------- */

// Import variables and mixins to prevent webpack
// error when importing SASS variables from JS
@import '../variables';
@import '../mixins';

$mobileSearchSize: 890px;

:export {
  mobileSearchSize: $mobileSearchSize;
}

#site-search-wrapper {
  --result-title-margin: 25px;

  position: fixed;
  top: 0;
  bottom: -40px;
  left: 0;
  right: 0;
  min-height: 100%;
  z-index: 99999;
  visibility: hidden;
  pointer-events: none;
  padding: 0 40px 40px 40px;
  overflow: hidden;
  font-size: 15px;

  &.save-state:not(.open) {
    * {
      pointer-events: none !important;
    }
  }

  .search-top-shadow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    transition: opacity 0.5s;
    opacity: 0;
    height: 330px;
    display: block !important;
    background: linear-gradient(
      180deg,
      #fff 41.48%,
      rgba(#fff, 0.982985) 49.96%,
      rgba(#fff, 0.944287) 56.97%,
      rgba(#fff, 0.887273) 62.69%,
      rgba(#fff, 0.815309) 67.31%,
      rgba(#fff, 0.731762) 71.03%,
      rgba(#fff, 0.64) 74.04%,
      rgba(#fff, 0.543389) 76.51%,
      rgba(#fff, 0.445297) 78.65%,
      rgba(#fff, 0.349091) 80.63%,
      rgba(#fff, 0.258137) 82.66%,
      rgba(#fff, 0.175802) 84.91%,
      rgba(#fff, 0.105455) 87.59%,
      rgba(#fff, 0.0504602) 90.87%,
      rgba(#fff, 0.0141863) 94.94%,
      rgba(#fff, 0) 100%
    );

    @include targetIE {
      display: none !important;
    }
  }

  .search-bg {
    pointer-events: none;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backface-visibility: hidden;
      transform-style: preserve-3d;
    }

    &:before {
      background-color: rgba(#fff, 0.88);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      transform: translateZ(0);
      opacity: 0;
      will-change: opacity;
      transition: opacity 0.6s;
      transition-delay: 0.2s;
    }

    &:after {
      background-color: rgba(#fff, 0.75);
      transform: translate3d(0, -100%, 0);
      will-change: transform;
      transition: transform 0.7s cubic-bezier(0.83, 0.07, 0.3, 1);
    }

    @include targetIE {
      &:before,
      &:after {
        position: fixed !important;
      }
    }
  }

  .fb-spinner-wrapper,
  .spinner {
    position: fixed;
  }

  &:not(.open) {
    .search-bg {
      right: 0 !important;
    }

    #close-search {
      margin-right: 0 !important;
      opacity: 0;
    }
  }

  &:not(.results-visible) {
    .fb-spinner-wrapper,
    .spinner {
      margin-top: 24vh;
    }
  }

  &.animating {
    visibility: visible !important;
  }

  &.open {
    overflow-y: scroll;
    visibility: visible;
    pointer-events: auto;

    .search-bg {
      &:before {
        transition-delay: 0s;
        transition-duration: 0.6;
        opacity: 1;
      }

      &:after {
        transform: translate3d(0, 0, 0);
        transition-duration: 2s;
        transition-timing-function: cubic-bezier(0.22, 0.84, 0, 1);
        transition-delay: 0.03s;
      }
    }

    &.has-query {
      .search-top-shadow {
        opacity: 1;
        transition-delay: 1s;
      }
    }

    &.save-state.animating {
      .search-top-shadow {
        transition: none !important;
      }
    }
  }

  .content-wrapper {
    max-width: 1100px;
    position: relative;
  }
}

#close-search {
  @include disableNativeButton;
  position: fixed;
  z-index: 9999;
  top: 15px;
  right: 15px;
  padding: 20px;
  border-radius: 50%;
  transition: all 0.3s;
  transition-property: background-color, opacity;

  &:after {
    content: 'Close';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    color: #929191;
    font-size: 12px;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.21, 0.95, 0.26, 1);
    transform: translateY(-10px);
    letter-spacing: 0.02em;
    pointer-events: none;
  }

  &:hover {
    background-color: rgba(#dbdbdb, 0.2);

    &:after {
      opacity: 1;
      transition-duration: 0.5s;
      transition-delay: 0.2s;
      transform: translateY(0);
    }
  }

  svg {
    width: 35px;
    height: 35px;
    display: block;
  }
}

/* --------------------------- */
/* Search Input */
/* --------------------------- */

$inputBorderSize: 2px;

#search-input-wrapper {
  position: sticky;
  top: 0;
  z-index: 300;
  margin: 0 auto;
  padding-top: 80px;
  transform: translate3d(0, calc(50vh - 50% - 90px), 0);
  transition: transform 0.9s cubic-bezier(0.53, 0.13, 0.13, 1), opacity 0.4s ease 0.2s,
    background-color 0.5s ease 1s;
  will-change: transform, opacity;
  opacity: 0;
  width: 100%;

  @at-root #site-search-wrapper.open & {
    opacity: 1;
  }

  @at-root #site-search-wrapper.open.save-state.animating & {
    transition-delay: 0s !important;
  }

  @at-root #site-search-wrapper:not(.open) & {
    transition: opacity 0.4s ease 0.2s;
  }

  @at-root #site-search-wrapper.has-query & {
    transform: translate3d(0, 0, 0);
  }

  @at-root #site-search-wrapper.results-open & {
    background-color: #fff;
  }
}

#search-input {
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    margin-top: -20px;
    z-index: 300;
    pointer-events: none;

    path {
      fill: #acacac;
      transition: fill 0.3s;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $inputBorderSize;
    background-color: #494949;
    pointer-events: none;
    transform-origin: left center;
    transform: scaleX(0);

    @at-root #site-search-wrapper.open & {
      transition: transform 1.3s cubic-bezier(0.16, 0.92, 0.17, 1);
    }
  }

  &:focus-within,
  &.has-focus {
    svg {
      path {
        fill: #797979;
      }
    }

    &:after {
      transform: scaleX(1);

      @at-root .animating & {
        transition-delay: 0.3s;
      }
    }
  }
}

#search-box {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  outline: none;
  border-bottom: $inputBorderSize solid #d1d1d1;
  background-color: transparent;
  font-weight: 400;
  color: #444444;
  font-size: 40px;
  letter-spacing: -0.03em;
  padding: 0 0 4px 48px;
  display: block;
  margin: 0;

  @include targetIE {
    transition: border-color 0.3s;

    &:focus {
      border-color: #494949;
    }
  }

  @include placeholder {
    color: #b3b3b3;
  }

  // Remove 'x' clear button added by some browsers
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
}

/* --------------------------- */
/* Search Suggestions */
/* --------------------------- */

$suggestionOffset: 10px;

.suggested-search-terms {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 30px 0 0 0;
  pointer-events: none;
  transition: opacity 0.4s;
  position: absolute;
  top: 100%;

  &.hidden {
    opacity: 0;
    pointer-events: none !important;
  }

  @at-root #site-search-wrapper.open & {
    pointer-events: auto;
  }

  li {
    margin-right: $suggestionOffset;
    margin-bottom: $suggestionOffset;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

$initialTermTransform: scale(0.5);

.search-term {
  @include disableNativeButton;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  padding: 13px 18px 14px 15px;
  line-height: 1.1;
  background-color: rgba(#219798, 0.08);
  color: #426f70;
  font-weight: 500;
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.43, 0.94, 0.25, 1.26);
  animation-fill-mode: forwards;
  transform: $initialTermTransform;
  opacity: 0;
  animation-name: suggestedTermIn;
  will-change: transform, opacity;
  font-family: inherit;
  font-size: 15px;

  @at-root #site-search-wrapper:not(.open):not(.animating) & {
    animation: none !important;
    transform: $initialTermTransform;
  }

  &:not(.search-term-clicked) {
    &:hover {
      background-color: rgba(#166a6b, 0.14);
    }

    &:focus {
      background-color: rgba(#166a6b, 0.17);
    }
  }

  &:before {
    content: '';
    flex-grow: 0;
    flex-shrink: 0;
    width: 19px;
    height: 19px;
    background-size: 19px 19px;
    align-self: flex-start;
    margin-right: 6px;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.19 15.581l-4.117-4.117c.633-.83.99-1.9.99-3.048a5.135 5.135 0 00-5.146-5.145A5.136 5.136 0 002.77 8.417a5.135 5.135 0 005.145 5.146 5.107 5.107 0 003.048-.99l4.117 4.117a.782.782 0 00.555.238c.198 0 .395-.08.554-.238a.766.766 0 000-1.109zM7.916 11.98a3.547 3.547 0 01-3.563-3.562 3.547 3.547 0 013.563-3.563 3.547 3.547 0 013.562 3.563 3.548 3.548 0 01-3.562 3.562z' fill='%23426F70'/%3E%3C/svg%3E");
  }
}

@keyframes suggestedTermIn {
  0% {
    transform: $initialTermTransform;
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes suggestedTermOut {
}

.search-scroll-sentinal {
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  visibility: hidden;
}

/* --------------------------- */
/* Search Results */
/* --------------------------- */

#search-results-content {
  transition: opacity 0.6s;
  will-change: opacity;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  position: relative;

  @at-root #site-search-wrapper.results-visible & {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  @at-root #site-search-wrapper.loading & {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9990;
      background-color: rgba(#fff, 0.7);
    }
  }

  @at-root #site-search-wrapper:not(.open) & {
    opacity: 0;
  }
}

#search-results-wrapper,
.best-bet-results {
  &:not(:empty) {
    padding-top: 10px;
  }
}

#search-results-wrapper {
  margin-bottom: 60px;

  &.hidden {
    display: none;
  }

  // @at-root #site-search-wrapper:not(.open) & {
  //   opacity: 0;
  // }
}

$resultShadow: 0px 1px 4px rgba(#000, 0.08), 0px 10px 35px rgba(#000, 0.02),
  0px 10px 20px rgba(#000, 0.03);
$resultShadowHover: 0px 1px 4px rgba(#000, 0.11), 0px 10px 24px rgba(#000, 0.06),
  0px 2px 6px rgba(#000, 0.07);

.search-result {
  --result-padding: 45px;

  margin-bottom: 35px;
  display: block;
  text-decoration: none !important;
  color: #515151 !important;
  background-color: #fff;
  padding: var(--result-padding);
  border-radius: 3px;
  box-shadow: $resultShadow;

  &:hover,
  &:focus {
    box-shadow: $resultShadowHover;

    .search-result-title {
      color: $tealColor;
    }

    .search-result-url {
      color: #636363;
    }
  }

  @at-root body.using-mouse & {
    &:focus {
      box-shadow: $resultShadowHover !important;
    }
  }

  @at-root #site-search-wrapper:not(.open) & {
    box-shadow: $resultShadow !important;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    font-size: 1.05em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.search-results-headline {
  align-items: center;
  font-size: 1.05em;
  letter-spacing: 0.005em;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  color: #434343;
  margin-bottom: 30px;
  position: sticky;
  top: 154px; // Input height + offset
  padding-top: 35px;
  padding-left: 31px;
  z-index: 8999;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s;
  visibility: hidden;

  @at-root #site-search-wrapper.results-visible & {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  @at-root #site-search-wrapper:not(.open) & {
    opacity: 0;
  }

  @at-root #site-search-wrapper.only-top-results &:not(.best-bet) {
    display: none;
  }

  .text {
    margin-right: 8px;
  }

  .query {
    color: $tealColor;
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 35px;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.793 16.66c-.54 0-.98-.44-.98-.98V3.52c0-.54.44-.98.98-.98h9.64c.54 0 .98.44.98.98v4.54c.54.06 1.06.2 1.54.4V3.52c0-1.4-1.14-2.52-2.52-2.52h-9.64c-1.4 0-2.52 1.14-2.52 2.52v12.14c0 1.4 1.14 2.52 2.52 2.52h6.16c-.5-.44-.94-.96-1.26-1.54h-4.9v.02z' fill='%23434343'/%3E%3Cpath d='M10.793 8.84h-5.18a.78.78 0 000 1.56h3.46a5.353 5.353 0 011.72-1.56zM12.393 5.94a.78.78 0 00-.78-.78h-6a.78.78 0 00-.779.78c0 .44.36.76.78.76h6c.44 0 .78-.34.78-.76zM5.614 12.5c-.42 0-.78.36-.78.78 0 .42.34.78.78.78h2.34v-.221c0-.46.06-.9.16-1.32l-2.5.001v-.02zM19.473 17.88l-2.08-1.74c.44-.68.68-1.46.68-2.32 0-2.4-1.94-4.34-4.34-4.34s-4.34 1.94-4.34 4.34 1.94 4.34 4.34 4.34c.86 0 1.66-.261 2.32-.68l1.74 2.08c.04.06.1.12.16.16.52.42 1.26.36 1.68-.16.44-.5.36-1.26-.16-1.68zm-8.88-4.06c0-1.74 1.4-3.14 3.14-3.14 1.74 0 3.14 1.4 3.14 3.14 0 1.74-1.4 3.14-3.14 3.14-1.72 0-3.14-1.4-3.14-3.14z' fill='%23434343'/%3E%3Cpath d='M14.553 12.5l-1.18 1.34-.439-.5c-.22-.24-.6-.28-.84-.06-.24.22-.28.6-.06.84l.88 1c.12.12.28.2.44.2.18 0 .34-.08.44-.2l1.64-1.86c.22-.24.2-.621-.061-.84-.22-.2-.6-.16-.82.08z' fill='%23434343'/%3E%3C/svg%3E");
  }

  &.best-bet:before {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0a10.003 10.003 0 00-7.072 2.928 10.003 10.003 0 000 14.144 10.003 10.003 0 0014.144 0 10.003 10.003 0 000-14.144A10.003 10.003 0 0010 0zm0 18.002a8.003 8.003 0 010-16.004A8.003 8.003 0 0110 18z' fill='%23434343'/%3E%3Cpath d='M8.588 11.83L5.755 8.998l-1.413 1.418 4.246 4.238 7.07-7.07-1.414-1.412-5.656 5.658z' fill='%23434343'/%3E%3C/svg%3E");
  }
}

.search-result-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 15px;
  margin-bottom: var(--result-title-margin);
}

.search-result-title {
  display: block;
  font-family: $headingFont;
  font-weight: 500;
  color: #323232;
  font-size: 1.85em;
  margin-top: -0.25em;
  margin-bottom: 0;
  line-height: 1.1;

  &:last-child {
    margin-bottom: 0;
  }
}

.search-result-subtitle {
  color: #707070;
  font-size: 15px;
  line-height: 1.2;
  margin-top: 5px;
}

.search-result-bio-header {
  display: block;
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 700;
  color: #444444;
  font-size: 16px;
  line-height: 19.2px;
  text-align: left !important;

  &:last-child {
    margin-bottom: 0;
  }
}

.search-result-category {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.006em;
  color: #505050;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  top: -2px;

  &:before {
    content: '';
    display: block;
    width: 23px;
    height: 23px;
    flex-grow: 0;
    flex-shrink: 0;
    background-size: cover;
    margin-right: 10px;
    position: relative;
  }
}

.search-result-url {
  color: #737373;
  margin-top: 1.8em;
  margin-bottom: -0.3em;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.search-load-more {
  margin: 60px auto 0 auto;
  display: flex !important;
  background-color: rgba(#fff, 0.8);
  min-width: 0 !important;
}

#site-search-wrapper {
  .btn.loading {
    pointer-events: none;
    border-color: #adabab !important;
    color: #828282 !important;
    transition: none !important;
    background-color: rgba(#dcdcdc, 0.12) !important;
  }
}

.search-result-highlight {
  background-color: #e3f5f4;
  border-radius: 3px;
  color: #115556;
  padding: 0px 2px 1px 2px;
  margin: 0 -2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

/* --------------------------- */
/* Result Category Icons */
/* --------------------------- */

.search-result-category {
  &.best-bet:before {
    margin-right: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 2.075a9.425 9.425 0 100 18.85 9.425 9.425 0 000-18.85zm0 1.141a8.283 8.283 0 110 16.566 8.283 8.283 0 010-16.566zm0 1.068a7.216 7.216 0 10-.001 14.432A7.216 7.216 0 0011.5 4.284zm0 1.244l1.554 3.812 4.105.3-3.145 2.656.983 3.998-3.497-2.171-3.498 2.17.984-3.997L5.84 9.64l4.106-.3L11.5 5.528z' fill='%23EDB122'/%3E%3C/svg%3E");
  }

  &.personal:before {
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.499 3.83a7.669 7.669 0 100 15.338 7.669 7.669 0 000-15.338zM2.313 11.5a9.186 9.186 0 1118.372 0 9.186 9.186 0 01-18.372 0z' fill='%231E8182'/%3E%3Cpath d='M11.827 10.877c-1.132-.265-1.438-.408-1.438-.857 0-.316.255-.591 1.02-.591.653 0 1.163.265 1.622.734l1.03-1c-.52-.54-1.112-.907-1.866-1.05v-1.03h-1.428v1.02c-1.071.204-1.857.907-1.857 1.978 0 1.408.918 1.826 2.234 2.132 1.346.306 1.591.51 1.591.97 0 .54-.398.723-1.244.723-.684 0-1.326-.234-1.826-.816l-1.03.918c.438.653 1.214 1.102 2.132 1.245v1.01h1.428v-1.01c1.397-.214 2.02-1.01 2.02-2.173 0-1.55-1.255-1.938-2.388-2.203z' fill='%231E8182'/%3E%3C/svg%3E");
  }

  &.business:before {
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.345 5.035h1.437V6.47H9.345V5.035zM9.345 7.908h1.437v1.437H9.345V7.908zM9.345 10.782h1.437v1.436H9.345v-1.436zM9.345 13.655h1.437v1.437H9.345v-1.437zM12.218 5.035h1.437V6.47h-1.437V5.035zM12.218 7.908h1.437v1.437h-1.437V7.908zM12.218 10.782h1.437v1.436h-1.437v-1.436zM12.218 13.655h1.437v1.437h-1.437v-1.437z' fill='%231E8182'/%3E%3Cpath d='M20.12 19.402H16.53V6.472h1.436v10.775a.718.718 0 101.437 0V5.753a.72.72 0 00-.718-.718h-2.155V2.88a.719.719 0 00-.719-.719H7.19a.718.718 0 00-.719.719v2.155H4.316a.718.718 0 00-.718.718v13.65H2.88a.718.718 0 100 1.436h17.24a.718.718 0 100-1.437zM5.035 6.472H6.47v10.775a.718.718 0 101.437 0V3.597h7.184v15.805h-1.437v-2.155a.72.72 0 00-.718-.718h-2.874a.718.718 0 00-.718.718v2.155h-4.31V6.472zm7.183 12.93h-1.436v-1.437h1.436v1.437z' fill='%231E8182'/%3E%3C/svg%3E");
  }

  &.wealth-management:before {
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.713 3.944a.685.685 0 00-.685.685v12.97c0 .379.307.685.685.685h5.55a.685.685 0 010 1.37h-5.55a2.054 2.054 0 01-2.055-2.055V4.63c0-1.135.92-2.055 2.055-2.055h6.952c.429 0 .841.166 1.15.463l3.25 3.112c.326.314.512.747.512 1.2v3.546c0 .068-.01.134-.029.196a2.974 2.974 0 012.883 2.972v.56h.187c.4 0 .724.325.724.725v4.354c0 .4-.325.723-.724.723h-6.32a.724.724 0 01-.725-.723v-4.354c0-.4.325-.724.724-.724h.187v-.56c0-1.25.77-2.318 1.86-2.759a.682.682 0 01-.137-.41V8.523h-2.633A2.054 2.054 0 0110.52 6.47V3.944H4.713zm10.404 3.194l.015.016h-2.558a.685.685 0 01-.685-.685V4.046l3.228 3.092zm3.062 7.486v-.56a1.721 1.721 0 00-3.443 0v.56h3.443zm-4.353 1.252v3.297h5.263v-3.297h-5.263z' fill='%231E8182'/%3E%3Cpath d='M7.99 10.833c0 .373.255.492 1.197.713.941.22 1.985.543 1.985 1.832 0 .967-.518 1.63-1.68 1.807v.84H8.304v-.84c-.763-.118-1.408-.492-1.773-1.035l.857-.763c.416.483.95.678 1.519.678.704 0 1.035-.152 1.035-.602 0-.382-.204-.551-1.324-.806-1.094-.254-1.858-.602-1.858-1.773 0-.89.653-1.476 1.544-1.646V8.39h1.188v.857c.628.118 1.12.424 1.553.873l-.857.832c-.382-.39-.806-.611-1.35-.611-.636 0-.848.229-.848.492z' fill='%231E8182'/%3E%3C/svg%3E");
  }

  &.about:before {
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.603 4.732c0-1.196.97-2.166 2.166-2.166h11.462c1.196 0 2.166.97 2.166 2.166v15a.701.701 0 01-1.402 0v-15a.764.764 0 00-.764-.764H5.769a.764.764 0 00-.764.764v15a.701.701 0 01-1.402 0v-15z' fill='%231E8182'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.54 10.39a2.245 2.245 0 100-4.491 2.245 2.245 0 000 4.49zm0-1.16a1.086 1.086 0 100-2.172 1.086 1.086 0 000 2.172z' fill='%231E8182'/%3E%3Cpath d='M7.655 16.542a.576.576 0 000 1.15h7.868a.576.576 0 100-1.15H7.655zM7.079 19.858c0-.317.258-.575.576-.575h4.557a.576.576 0 010 1.15H7.655a.576.576 0 01-.576-.575zM10.719 11.082c-1.22 0-2.284.827-2.586 2.008l-.225.884a.576.576 0 101.115.285l.226-.884a1.517 1.517 0 011.47-1.142h1.535c.692 0 1.296.469 1.47 1.139l.222.865a.576.576 0 001.115-.287l-.223-.865a2.669 2.669 0 00-2.584-2.003h-1.535z' fill='%231E8182'/%3E%3C/svg%3E");
  }

  &.resource-center:before {
    top: -1px;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.372.45a.69.69 0 01.699.682l.024 1.84a.69.69 0 11-1.38.017l-.024-1.84a.69.69 0 01.681-.698zm6.539 2.613a.689.689 0 01.503 1.172L17.13 5.552a.692.692 0 01-.99-.964l1.285-1.318a.689.689 0 01.486-.207zM4.903 3.23a.689.689 0 01.49.195l1.318 1.284a.692.692 0 01-.964.99L4.429 4.414a.692.692 0 01.474-1.184zm6.522 1.36c3.16-.04 6.24 2.381 6.285 5.865.028 2.169-.813 3.585-1.564 4.678-.752 1.093-1.342 1.833-1.328 2.985l.036 2.76a1.619 1.619 0 01-1.59 1.63l-3.219.041a1.62 1.62 0 01-1.63-1.589L8.38 18.2c-.015-1.152-.626-1.877-1.404-2.95-.778-1.073-1.656-2.468-1.684-4.636-.044-3.484 2.971-5.982 6.133-6.023zm.018 1.38c-2.449.032-4.805 1.954-4.772 4.627.024 1.824.672 2.818 1.415 3.842.634.872 1.385 1.787 1.606 3.055l3.795-.048c.19-1.274.917-2.208 1.527-3.096.717-1.042 1.34-2.052 1.317-3.877-.035-2.672-2.44-4.533-4.888-4.503zm.29 1.5c.098-.005.204.008.31.032 1.79.427 2.543 1.358 2.565 3.082.067.356-.194.746-.55.812a.727.727 0 01-.813-.557c.013-1.281-.395-1.818-1.674-2.044a.714.714 0 01-.448-.763c.077-.387.316-.547.61-.56V7.47zm8.957 2.062a.69.69 0 11.018 1.38l-1.84.024a.69.69 0 11-.018-1.38l1.84-.024zm-16.558.213a.69.69 0 11.018 1.38l-1.84.023a.69.69 0 11-.018-1.38l1.84-.023zm9.313 8.735l-3.68.047.012.9 3.68-.047-.012-.9zm.023 1.8l-3.68.047.009.616c.002.15.118.229.233.227l3.22-.041a.223.223 0 00.226-.233l-.008-.616z' fill='%231E8182'/%3E%3C/svg%3E");
  }

  &.security-center:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' fill='none' viewBox='0 0 23 23'%3E%3Cpath fill='teal' fill-rule='evenodd' d='M12.037 2.283a.794.794 0 0 0-.537-.208.798.798 0 0 0-.538.208A10.7 10.7 0 0 1 3.39 4.95a.8.8 0 0 0-.804.573A12.3 12.3 0 0 0 11.3 20.9a.8.8 0 0 0 .402 0 12.3 12.3 0 0 0 8.712-15.375.8.8 0 0 0-.804-.573 10.7 10.7 0 0 1-7.572-2.668ZM11.5 3.917a12.3 12.3 0 0 1-7.533 2.645A10.7 10.7 0 0 0 11.5 19.296a10.7 10.7 0 0 0 7.532-12.734A12.3 12.3 0 0 1 11.5 3.917Z' clip-rule='evenodd'/%3E%3Cpath fill='teal' fill-rule='evenodd' d='M11.5 10.491c.441 0 .8.403.8.899v2.69c0 .497-.359.9-.8.9-.443 0-.8-.403-.8-.9v-2.69c0-.496.357-.899.8-.899Z' clip-rule='evenodd'/%3E%3Cpath fill='teal' d='M11.5 8.575a1.758 1.758 0 1 0 0 3.516 1.758 1.758 0 0 0 0-3.516Z'/%3E%3C/svg%3E");
    top: -1px;
    left: 1px;
  }
}

/* --------------------------- */
/* Best Bet Search Results */
/* --------------------------- */

#best-bet-results-wrapper {
  margin-bottom: 30px;

  &.hidden {
    display: none;
  }
}

.best-bet-search-result {
  --result-padding: 45px;

  $borderColor: #cacaca;
  $dashSize: 10px;

  padding: var(--result-padding);
  margin-bottom: 35px;
  background-color: rgba(#fff, 0.7);
  background-image: repeating-linear-gradient(
      to right,
      $borderColor 0%,
      $borderColor 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to right,
      $borderColor 0%,
      $borderColor 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      $borderColor 0%,
      $borderColor 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      $borderColor 0%,
      $borderColor 50%,
      transparent 50%,
      transparent 100%
    );
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: #{$dashSize * 2} 1px, #{$dashSize * 2} 1px, 1px #{$dashSize * 2},
    1px #{$dashSize * 2};

  &:last-child {
    margin-bottom: 0;
  }
}

.search-result-content {
  position: relative;
}

/* --------------------------- */
/* Empty Search Results/Input */
/* --------------------------- */

#search-results-empty,
#search-input-empty {
  padding-top: 100px;
  text-align: center;
  display: none;
  letter-spacing: -0.01em;
  font-size: 1.25rem;
  letter-spacing: -0.02em;

  @at-root #site-search-wrapper:not(.open):not(.animating) & {
    display: none !important;
  }

  .section-header {
    display: block;
    margin-bottom: 7px;
    letter-spacing: 0.02em;
    color: #878787;
  }
}

#search-results-empty {
  color: #565656;
}

#search-input-empty {
  opacity: 0;
  color: #969696;
}

#site-search-wrapper {
  &.no-input {
    #search-results-content {
      > *:not(#search-input-empty) {
        display: none;
      }

      #search-input-empty {
        display: block;
        animation: noInputFadeIn 1s ease 1.2s;
        animation-fill-mode: forwards;
      }
    }
  }

  &.no-results {
    #search-results-content {
      > *:not(#search-results-empty) {
        &:not(.search-feedback-wrapper) {
          display: none;
        }
      }

      #search-results-empty {
        display: block;
      }
    }
  }

  &.loading {
    #search-results-empty,
    #search-input-empty {
      display: none !important;
    }
  }
}

@keyframes noInputFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* --------------------------- */
/* Search Feedback */
/* --------------------------- */

.search-feedback-wrapper {
  position: relative;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
  display: none;

  &.visible {
    display: block;
  }

  div,
  p {
    &:empty {
      display: none;
    }
  }

  p {
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @at-root #site-search-wrapper.no-results & {
    margin-top: 80px;

    .search-feedback-content {
      padding-top: 80px;
      border-top: 1px solid #dedede;
    }

    .search-feedback-sent {
      top: 60px;
    }
  }
}

.search-feedback-content {
  position: relative;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.search-feedback-title {
  font-family: $headingFont;
  font-size: 1.93rem;
  color: #323232;
  line-height: 1.2;
  margin-bottom: 6px;
}

.search-feedback-text {
  color: #484848;
  line-height: 1.4;
}

.search-feedback-button {
  margin-top: 23px;
  font-size: 0.88em;
  padding: 0.7em 1.7em 0.73em;
}

.search-feedback-sent {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.13rem;
  color: #757272;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;

  p {
    font-size: inherit;
    color: inherit;
  }
}

$checkmarkSize: 60px;
$checkMarkGreen: #77d62d;
$checkMarkEase: cubic-bezier(0.65, 0, 0.45, 1);
$checkMarkShowDelay: 0.3s;

.search-feedback-checkmark {
  width: $checkmarkSize;
  height: $checkmarkSize;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 20px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $checkMarkGreen;
  animation: checkMarkFill 0.4s ease-in-out #{$checkMarkShowDelay + 0.4s} forwards,
    checkMarkScale 0.3s ease-in-out #{$checkMarkShowDelay + 0.9s} both;

  circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $checkMarkGreen;
    fill: none;
    animation: checkMarkStroke 0.6s $checkMarkEase $checkMarkShowDelay forwards;
  }

  path {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: checkMarkStroke 0.3s $checkMarkEase #{$checkMarkShowDelay + 0.8s} forwards;
  }
}

// Styles after feedback sent
.search-feedback-wrapper.submitted {
  .search-feedback {
    transition: 0.6s;
    opacity: 0;
    pointer-events: none;
  }

  .search-feedback-sent {
    display: flex;

    p {
      opacity: 0;
      animation: feedbackSentTextFade 1s ease #{$checkMarkShowDelay + 0.9s} forwards;
    }
  }
}

@keyframes checkMarkStroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkMarkScale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes checkMarkFill {
  100% {
    box-shadow: inset 0px 0px 0px #{math.div($checkmarkSize, 2)} $checkMarkGreen;
  }
}

@keyframes feedbackSentTextFade {
  100% {
    opacity: 1;
  }
}

/* --------------------------- */
/* Search Errors */
/* --------------------------- */

#site-search-wrapper.has-error {
  #best-bet-results-wrapper,
  #search-results-wrapper,
  #search-results-empty,
  #search-input-empty,
  .search-feedback-wrapper,
  .search-results-headline {
    display: none !important;
  }
}

.search-error-wrapper {
  padding-top: 35px;
  position: relative;
  z-index: 10;
}

.search-error {
  background-color: #f7eeee;
  color: #7d3636;
  padding: 22px 25px;
  border-radius: 4px;
  font-size: 0.95em;
  line-height: 1.1;
}

/* --------------------------- */
/* Media Queries */
/* --------------------------- */

@media (max-width: 1050px) {
  #site-search-wrapper {
    --result-title-margin: 18px;

    padding: 0 45px 45px 45px;

    .content-wrapper {
      padding: 0;
    }

    .search-top-shadow {
      height: 200px;
    }
  }

  #close-search {
    top: 20px;
    right: 20px;
  }

  #search-input-wrapper {
    padding-top: 30px;
  }

  #search-input {
    margin-right: 70px;

    svg {
      width: 36px;
      height: 36px;
      margin-top: -18px;
    }
  }

  #search-box {
    font-size: 35px;
    padding-left: 43px;
    margin: 0;
    display: block;
  }

  .suggested-search-terms {
    padding-top: 25px;
  }

  .search-results-headline {
    margin-bottom: 20px;
    top: 85px;
  }

  .search-term {
    font-size: 0.95rem;

    &:before {
      width: 18px;
      height: 18px;
      background-size: cover;
    }
  }

  .search-result,
  .best-bet-search-result {
    --result-padding: 35px;
  }

  .search-result {
    p {
      font-size: 0.96rem;
    }
  }

  .search-result-title {
    font-size: 1.7em;
  }

  .search-result-category {
    &:before {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  .search-result-url {
    font-size: 0.84rem;
    color: #737373;
  }
}

@media (max-width: $mobileSearchSize) {
  #site-search-wrapper {
    .search-top-shadow {
      transition-delay: 0s !important;
    }
  }

  #search-input-wrapper {
    transform: none !important;
    transition-property: opacity;
  }

  #search-input {
    margin-right: 60px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  #search-box {
    font-size: 28px;
    padding-left: 38px;
  }

  #close-search {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  .suggested-search-terms {
    padding-top: 20px;
  }

  .search-term {
    padding: 10px 14px 12px 12px;
  }

  .search-results-headline {
    padding-top: 25px;

    &:before {
      top: 25px;
    }
  }

  #best-bet-results-wrapper {
    margin-bottom: 20px;
  }

  #search-input-empty,
  #search-results-empty {
    padding-top: 50px;
  }

  .search-feedback-wrapper {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  #site-search-wrapper.no-results {
    .search-feedback-wrapper {
      margin-top: 50px;

      .search-feedback-content {
        padding-top: 50px;
      }
    }
  }

  .fb-spinner-wrapper,
  .spinner {
    margin-top: 0 !important;
  }
}

@media (max-width: 650px) {
  .search-result-top {
    display: block;
  }

  .search-result-title {
    font-size: 1.9em;
  }

  .search-result-category {
    margin-left: 0;
    margin-top: 10px;
    top: 0;
    color: #777676;

    &:before {
      width: 17px;
      height: 17px;
      margin-right: 6px;
      margin-left: -2px;
    }
  }

  .search-result-url {
    margin-top: 1.3em;
  }

  #search-results-empty {
    text-align: left;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .search-feedback-wrapper {
    margin-top: 60px;
  }

  .search-feedback-content {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }

  #site-search-wrapper.no-results {
    .search-feedback-wrapper {
      margin-top: 35px;

      .search-feedback-content {
        padding-top: 30px;
      }
    }
  }
}

@media (max-width: 500px) {
  #site-search-wrapper {
    padding: 0 25px 25px 25px;
  }

  #close-search {
    right: 10px;
    top: 10px;
    padding: 15px;

    &:after {
      display: none;
    }
  }

  #search-input-wrapper {
    padding-top: 20px;
  }

  #search-input {
    margin-right: 45px;
  }

  .search-result,
  .best-bet-search-result {
    --result-padding: 28px;
  }

  .search-results-headline {
    font-size: 1rem;
    padding-left: 28px;

    &:before {
      width: 19px;
      height: 19px;
      margin-top: -1px;
    }

    &.best-bet {
      &:before {
        width: 17px;
        height: 17px;
      }
    }
  }

  .search-result {
    margin-bottom: 25px;
  }
}

@media (max-width: 460px) {
  #search-input-wrapper {
    padding-top: 24px;
  }

  #search-input {
    svg {
      width: 24px;
      height: 24px;
      margin-top: -15px;
    }
  }

  #search-box {
    font-size: 22px;
    font-weight: 500;
    padding-left: 29px;
    padding-bottom: 5px;
  }
}

@media (max-width: 380px) {
  #search-input {
    margin-right: 35px;

    svg {
      width: 22px;
      height: 22px;
      margin-top: -14px;
    }
  }

  #search-box {
    font-size: 19px;
    padding-left: 24px;

    @include placeholder {
      color: #9e9e9e;
    }
  }

  #close-search {
    right: 8px;
    top: 8px;

    svg {
      width: 22px;
      height: 22px;
    }
  }
}
