#faq-content {
  .content-wrapper {
    max-width: 1070px;
  }

  @include desktop {
    h1 {
      font-size: 3rem;
      line-height: 1.05;
    }
  }
}

#faq-title {
  margin-bottom: 45px;
  border-bottom: 1px solid #e2e0e0;
  padding-bottom: 9px;

  .breadcrumb-wrapper {
    padding-top: 0;
  }

  h1 {
    margin-top: 0.4em;

    &:first-child {
      margin-top: 0;
    }
  }

  @include tablet {
    margin-bottom: $contentPaddingTablet;
  }

  @include tabletPortrait {
    margin-bottom: $contentPaddingTabletPortrait;
  }

  @include mobile {
    margin-bottom: $contentPaddingMobile;
  }
}

#faq-answer {
  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto !important;
    display: block;
  }
}

// Used to anchor to top of FAQ listings.
// Adjusts to accomodate header size
#faq-topic-list {
  position: relative;
  top: -79px;
  pointer-events: none;

  @media (max-width: 1180px) and (min-width: #{$desktopMinWidth}) {
    top: -110px;
  }
}
