.content-box-row {
  .card-group {
    @include media-breakpoint-between(sm, md) {
      display: flex;
      justify-content: space-between;

      .card {
        flex: 0 0 47%;
      }
    }
  }

  .card {
    .ltr-underline {
      @extend %link-underline-base;
      font-size: 1em;
      font-weight: 500;

      &:hover {
        color: $green;
      }
    }

    @include media-breakpoint-down(md) {
      flex: 1 0 100%;
    }

    img {
      object-fit: cover;
      width: 100%;

      @include media-breakpoint-up(lg) {
        height: 10rem;
      }

      @include media-breakpoint-between(sm, md) {
        height: 10rem;
      }
    }

    .card-footer {
      background-color: inherit;
      font-weight: 500;
    }
  }

  .card-body {
    .pb-0:last-child {
      padding-bottom: 2em !important;
    }
  }
}
