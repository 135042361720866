// General
h1,
h2,
h3,
h4,
h5,
p,
div,
ul,
ol {
  max-width: 100%;
}

.flex-column {
  > * {
    max-width: 100%;
  }
}

body {
  padding-top: $menuHeight;

  @media (max-width: 1180px) and (min-width: #{$desktopMinWidth}) {
    padding-top: $menuHeightLarge;
  }

  @media (max-width: 550px) {
    $padding-top: $menuHeightMobile;
  }
}

main .content {
  width: 100%;
}

// Header
.header-logo {
  width: 270px;
  height: 25px;

  @media (max-width: 550px) {
    width: 240px;
    height: 22px;
  }

  @media (max-width: 355px) {
    width: 220px;
    height: 20px;
  }
}

// Footer
#footer {
  .footer-svg {
    width: 170px;
    height: 40px;
  }

  .component-find-a-branch-contact-us {
    .find-local-branch > div {
      padding-left: 0 !important;
    }
  }
}

// Cards
.full-width-feature-highlights-content-boxes,
.get-started {
  .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: auto !important;
  }
}

.card-group {
  &.two-columns {
    max-width: calc(100% + 30px);
  }
}

// Responsive images
.video-container {
  max-width: 100%;
}

// Login modal
#loginModal {
  .modal-content {
    width: 100%;
    max-width: 600px;
  }
}

// Components
.homepage-hero {
  margin-top: 0 !important;
  padding-top: 0 !important;
  height: 0;

  @media (max-width: 900px) {
    height: auto;
  }
}

.interior-split-banner {
  .hero-image {
    max-width: none;
    overflow: hidden;

    img {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      height: 100%;
      min-width: 100%;
      max-width: none;
    }
  }
}

.component-full-width-cta-banner {
  width: 100%;
  overflow: hidden;

  .container {
    @include media-breakpoint-up(md) {
      height: 0;
    }
  }
}

.component-content-box-grid {
  .media {
    svg {
      height: 4.68rem;

      @include tabletPortrait {
        height: 4rem;
      }
    }
  }
}
