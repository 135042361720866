.ktc-editable-area {
  .fr-qi-helper {
    z-index: 99990 !important;

    a.fr-btn.fr-floating-btn {
      svg {
        vertical-align: initial;
      }
    }
  }
}

.TinyMCEEditor {
  min-height: 1.4em;
  outline: none;
  padding-bottom: 32px;

  p {
    text-wrap: unset;
  }
}

.plain-text-editor-input {
  text-wrap: unset;
}

.body.hide-widget-selector kentico-pop-up-container {
  opacity: 0;
  pointer-events: none;
}

.ktc-widget-zone--empty {
  &.loading-widget {
    opacity: 0.5;
    pointer-events: none;
  }
}

.component-placeholder {
  @include pageBuilder {
    position: relative;
    height: 90px;
    appearance: none;
    width: 100%;
    border: none;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent !important;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        127deg,
        #fafafa 25%,
        #ebecf0 25%,
        #ebecf0 50%,
        #fafafa 50%,
        #fafafa 75%,
        #ebecf0 75%,
        #ebecf0 100%
      );
      background-size: 12.52px 16.62px;
      opacity: 0.6;
      transition: opacity 0.2s;
      background-repeat: repeat !important;
    }

    &:after {
      content: attr(placeholder);
      white-space: pre-wrap;
      position: absolute;
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: -0.03em;
      display: block;
      text-align: center;
      line-height: 1.1;
      color: #7e8188;
      font-family: $bodyFont;
      text-transform: uppercase;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -13px;
    }

    &.section-placeholder {
      min-height: 131px;

      &:after {
        margin-top: 0;
      }
    }

    &:is(button, .clickable) {
      cursor: pointer;

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.fb-ktc-no-scroll {
  overflow: hidden !important;
  padding-right: 16px;
}
