@use 'sass:math';
@import 'content-section';

.section {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
}

.content-wrapper {
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
  max-width: var(--content-max-width, var(--content-width));
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.section-top {
  text-align: center;

  *:last-child {
    margin-bottom: 0 !important;
  }

  @mixin accentLineMaxWidth($contentPadding, $singleLine: false) {
    @if $singleLine {
      max-width: calc((100vw - 100% - #{$contentPadding * 2}) - 20px);
    } @else {
      max-width: calc((100vw - 100% - #{$contentPadding * 2}) / 2 - 20px);
    }
  }

  h2,
  .section-headline {
    margin-top: -0.16em;

    &:last-child {
      margin-bottom: -0.16em !important;
    }

    > span {
      display: inline-block;
    }

    &.accent-line {
      > span {
        position: relative;

        @media (min-width: #{$mobileMaxWidth + 1px}) {
          max-width: calc(100% - 100px);
        }

        &:before,
        &:after {
          content: '';
          height: 0.051em;
          display: block;
          background: #edb222;
          position: absolute;
          width: 100px;
          top: 50%;
          transform: translateY(-50%);

          @include accentLineMaxWidth($contentPaddingDesktop);

          @include tablet {
            @include accentLineMaxWidth($contentPaddingTablet);
          }

          @include mobile {
            display: none !important;
          }
        }

        &:before {
          margin-right: 20px;
          right: 100%;
        }

        &:after {
          margin-left: 20px;
          left: 100%;
        }
      }
    }

    @at-root .ktc-widget-body-wrapper & {
      margin-top: 3px !important;
      margin-bottom: 3px !important;
    }
  }

  .text {
    max-width: 900px;
    font-size: 1.08rem;
    margin-left: auto;
    margin-right: auto;

    p,
    li {
      font-size: inherit;
      margin-bottom: 0.75em;

      + br {
        display: none;
      }
    }

    br:not(.single-break):not([data-mce-bogus]) {
      content: '';
      display: block;
      font-size: inherit;
      height: 0.75em;

      + br {
        display: none;
      }
    }

    @at-root .ktc-widget-body-wrapper & {
      margin-bottom: 45px !important;
    }

    @at-root .disable-headline-margin .ktc-widget-body-wrapper & {
      margin-bottom: 0 !important;
    }
  }

  &.text-left {
    text-align: left;

    h2,
    .section-headline {
      &.accent-line {
        > span {
          &:before {
            display: none;
          }

          &:after {
            @include accentLineMaxWidth($contentPaddingDesktop, true);

            @include tablet {
              @include accentLineMaxWidth($contentPaddingTablet, true);
            }
          }
        }
      }
    }

    .text {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include tablet {
    .text {
      font-size: 1rem;
    }
  }

  @include mobile {
    text-align: left !important;
    padding-top: 2px;

    .section-header {
      br {
        display: none;
      }
    }

    .text {
      margin-left: 0;
      margin-right: 0;

      br {
        display: none;
      }
    }
  }
}

.section-content,
.section-bottom {
  @include sectionContentSpacing;

  &:empty {
    display: none;
  }
}

.buttons-wrapper {
  $spacingHorizontal: 14px;
  $spacingVertical: 12px;
  $halfSpacingHorizontal: math.div($spacingHorizontal, 2);

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1em;
  width: calc(100% + #{$spacingHorizontal});
  margin-left: -$halfSpacingHorizontal;
  margin-bottom: -$spacingVertical;

  > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 $halfSpacingHorizontal $spacingVertical $halfSpacingHorizontal;
  }

  a:not(.btn) {
    font-size: inherit;
  }

  &.text-center {
    justify-content: center;
  }

  @at-root {
    .section-bottom &,
    &.section-bottom {
      @include tablet {
        padding-bottom: 15px;
      }

      @include mobile {
        padding-bottom: 2px;
      }
    }
  }

  @include mobile {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;

    > * {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      + a:not(.btn) {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 450px) {
    .btn {
      width: 100%;
    }
  }

  @at-root {
    .section-top + &:not(.section-bottom),
    &.section-cta,
    .section-cta {
      margin-top: 25px;
      padding-bottom: 5px;

      @include tablet {
        margin-top: 20px;
        padding-bottom: 3px;
      }

      @include mobile {
        align-items: flex-start;
        margin-top: 23px;
        padding-bottom: 5px;
      }
    }
  }
}

.full-width-feature-highlights-content-boxes {
  .card-group[data-count='0'] {
    + .summary-section {
      margin-top: 25px;

      @include tabletPortrait {
        margin-top: 15px;
      }
    }
  }
}

.col {
  min-width: 0;
}
