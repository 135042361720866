.comparison-module {
  .btn-group-toggle {
    label {
      color: $gray-450;
      font-size: 0.875rem;
      font-weight: 700;
      padding: 0.5rem 2rem !important;
      align-self: center;
      border: 2px solid $gray-450;

      &:hover,
      &:focus {
        text-decoration: none;

        &:not(.active) {
          border: 2px solid $teal;
          color: $teal;
        }
      }

      &.focus,
      &.active {
        color: $white;
        background-color: $teal;
        border: 2px solid $teal;
        font-weight: bold;
      }
    }

    @include media-breakpoint-down(md) {
      & + .btn-group-toggle {
        margin-top: 1rem;
      }
    }
  }

  .products {
    .d-none {
      width: 0 !important;
      height: 0 !important;
    }

    .product-item {
      h3 {
        line-height: 1.1;
      }

      .image {
        img {
          @include media-breakpoint-up(lg) {
            height: 12rem;
            object-fit: cover;
            width: 100%;
          }

          @include media-breakpoint-down(md) {
            width: 100%;
            height: 13rem;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      .rate-details {
        color: $black;
        font-weight: $font-weight-normal;

        .interest-rate,
        .apr {
          font-size: 1.28125rem;
          line-height: 1.3;

          span {
            font-size: 2.0625rem;
          }
        }

        table {
          color: $black;
          margin: 1rem 0;

          th,
          td {
            padding: 0;
          }

          th {
            font-weight: $font-weight-normal;
          }
        }

        .payment-note {
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  .product-toggle-container {
    z-index: 100;

    @include media-breakpoint-up(lg) {
      justify-content: space-evenly;
    }

    @include media-breakpoint-down(md) {
      .btn-group {
        width: auto;
        margin: auto;
      }
    }
  }

  .swiper-container-initialized {
    .row {
      /* break base property */
      flex-wrap: nowrap !important;
    }
    .card-group {
      @include media-breakpoint-down(sm) {
        display: flex;

        .card {
          flex: 0 0 100%;
        }
      }
    }
  }
}
