.sc-grid-section {
  --content-width: 1180px;
  --gap: 15px;
  --padding: 35px;
  --border-width: 9px;
  --item-color: #dff1f1;
  --circle-img-size: 210px;
  --rectangle-img-min-height: 160px;
  --img-order: 3;

  position: relative;

  @at-root .sc-blog-listing + & {
    padding-top: 0;
    margin-top: calc(var(--section-padding) * -1 + var(--content-padding));
  }

  &.spacing-top-short {
    padding-top: var(--content-padding);

    @at-root .content-section + &,
      .section + & {
      padding-top: 0;
      margin-top: calc(var(--section-padding) * -1 + var(--content-padding));
    }
  }

  &.spacing-bottom-short {
    padding-bottom: var(--content-padding);
  }

  @at-root #security-center-hero + &,
    #security-center-hero + .ktc-editable-area .ktc-section:nth-child(3) & {
    padding-top: 30px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100px;
      background-color: var(--teal-color);
    }

    // .sc-grid-item {
    //   &.span-2:nth-child(1),
    //   &.span-2:nth-child(2),
    //   &.span-3:nth-child(1),
    //   &:nth-child(-n + 3):not(.span-1, .span-2):not(:has(.sc-grid-img)) {
    //     margin-top: calc(var(--section-padding) * -1);
    //   }
    // }
  }

  + .section {
    padding-top: 0;
  }
}

.sc-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);
  font-size: 17px;
  position: relative;
  z-index: 100;

  .text {
    order: 2;
    padding: var(--padding);
    flex-grow: 1;
    width: 100%;
  }

  h2,
  h3 {
    font-size: 26px;
    margin-bottom: 15px;
    margin-top: -0.2em;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @at-root .ktc-section & {
    display: block;
    gap: 0;
  }

  .ktc-widget-zone {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap);

    &:not(.ktc-widget-zone--empty) {
      &:before,
      > .ktc-admin-ui {
        display: none;
      }
    }
  }

  .ktc-widget {
    display: flex;

    *:has(.sc-grid-item) {
      display: contents;
    }

    &:has(.sc-grid-item.span-2) {
      grid-column: span 2;
    }

    &:has(.sc-grid-item.span-3) {
      grid-column: span 3;
    }

    .sc-grid-item {
      width: 100%;
    }
  }

  .TinyMCEEditor {
    position: relative;
    outline: none;
    padding-bottom: 0;
    min-height: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      pointer-events: none;
    }

    &:before {
      border: 1px dashed currentColor;
      opacity: 0.7;
    }

    &:focus-within:before {
      opacity: 1;
    }

    &:hover:after {
      background-color: currentColor;
      opacity: 0.05;
    }

    &:focus-within:after {
      box-shadow: 0 0 0 4px currentColor;
      opacity: 0.12;
      border-radius: 1px;
      transition: none;
      background-color: transparent;
    }
  }
}

.sc-grid-item {
  border: var(--border-width) solid var(--item-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  min-width: 0;

  &.span-2,
  &.span-3 {
    flex-direction: row;
    align-items: normal;
  }

  &.img-left {
    --img-order: 1;
  }

  &.span-2 {
    grid-column: span 2;
  }

  &.span-3 {
    grid-column: span 3;
  }

  &.fill-bg {
    background-color: var(--item-color);
  }

  &.item-red {
    --item-color: #f4cbb2;
  }
}

.sc-grid-cta {
  margin-top: 22px;
  display: flex;

  .btn {
    font-size: 0.83em;
    padding-left: 2.2em;
    padding-right: 2.2em;
  }

  .ltr-underline {
    text-wrap: balance;
    margin: -0.2em 0 -0.3em 0;

    span {
      margin-left: 0.42em;
      top: 0.12em;
    }
  }

  @at-root .item-red & {
    .btn-primary {
      --btn-color: #d0613a;

      background-color: var(--btn-color) !important;
      border-color: var(--btn-color) !important;

      &:hover {
        --btn-color: #b14d2a;
      }

      &:active {
        --btn-color: #8f3c1f;
      }

      &:focus,
      &:active {
        box-shadow: #e9b19d 0px 0px 0px 0.2rem !important;
      }
    }
  }
}

@mixin homePageIntroBoxes($selector: '') {
  @at-root #security-center-hero + .sc-grid-section {
    .sc-grid-item:not(.span-2, .span-3):nth-child(-n + 3) #{$selector} {
      @content;
    }
  }
}

.sc-grid-img {
  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  --wrapper-width: min(var(--content-width), 100vw);
  --item-width: calc(
    var(--wrapper-width) - var(--content-padding) * 2 - var(--border-width) * 2
  );

  margin-top: 0;
  border-radius: 0;
  border: none;
  width: calc((var(--item-width) - var(--gap) * 3) / 3);
  max-width: 100%;
  min-height: var(--rectangle-img-min-height);
  order: var(--img-order);
  background-color: #fff;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;

    @at-root .img-contain & {
      object-fit: contain;
    }
  }

  @include homePageIntroBoxes(&) {
    --img-order: 0;

    margin-top: calc(var(--section-padding) * -1);
    width: var(--circle-img-size);
    height: var(--circle-img-size);
    border: var(--border-width) solid var(--item-color);
    border-radius: 50%;
    min-height: 0;
  }

  @at-root .sc-grid-item.img-circle & {
    --img-order: 0;

    margin-top: calc(var(--section-padding) * -1);
    width: var(--circle-img-size);
    height: var(--circle-img-size);
    border: var(--border-width) solid var(--item-color);
    border-radius: 50%;
    min-height: 0;
  }
}

@media (max-width: 1060px) {
  .sc-grid-section {
    --padding: 30px;
    --circle-img-size: 160px;
    --border-width: 7px;
  }

  .sc-grid {
    font-size: 1rem;

    h2 {
      font-size: 24px;
    }
  }
}

@media (max-width: 875px) {
  .sc-grid-section {
    --circle-img-size: 110px;

    @at-root #security-center-hero + & {
      padding-top: 0;

      &:before {
        height: 60px;
      }
    }
  }

  .sc-grid {
    display: block;

    .ktc-widget-zone {
      display: block;
    }

    .ktc-widget {
      margin-bottom: var(--gap);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sc-grid-item {
    margin-bottom: var(--gap);
    flex-direction: row;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .sc-grid-img {
    width: 50%;

    @include homePageIntroBoxes(&) {
      margin: 25px 0 25px var(--padding);
      border: none;
      align-self: flex-start;
    }
  }
}

@media (max-width: 500px) {
  .sc-grid-section {
    --circle-img-size: 100px;
    --rectangle-img-min-height: 220px;

    &:last-child {
      padding-bottom: var(--gap);
    }
  }

  .sc-grid {
    width: calc(100% + var(--content-padding) * 2);
    margin-left: calc(var(--content-padding) * -1);
    padding: 0 var(--gap);
  }

  .sc-grid-item {
    flex-direction: column !important;

    &.img-contain {
      --rectangle-img-min-height: 160px;
    }
  }

  .sc-grid-img {
    width: 100%;
  }

  @include homePageIntroBoxes {
    display: block;
    padding: var(--padding);

    .sc-grid-img {
      margin: -5px 0 28px -2px;
    }

    .text {
      padding: 0;
    }
  }
}
