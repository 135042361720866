.a2a_kit {
  a {
    svg {
      color: $gray-500;
      height: 1.875rem;
      width: 1.875rem;
    }

    &:hover {
      svg {
        color: $green;
      }
    }
  }
}
