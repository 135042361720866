.card-group {
  // &.columns {
  //   .card {
  //     margin-left: 0.75rem;
  //     margin-right: 0.75rem;
  //     margin-bottom: 1.5rem;
  //     &:first-child {
  //       @include media-breakpoint-up(md) {
  //         margin-left: 0;
  //       }
  //       margin-right: 0.75rem;
  //     }
  //     &:last-child {
  //       margin-left: 0.75rem;
  //       @include media-breakpoint-up(md) {
  //         margin-right: 0;
  //       }
  //     }
  //   }
  // }
  // &.two-columns {
  //   .card {
  //     line-height: 1.5;

  //     margin-top: .75rem;
  //     margin-bottom: .75rem;

  //     @include media-breakpoint-up(md) {
  //       &:nth-child(odd) {
  //         margin-right: .75rem;
  //       }
  //       &:nth-child(even) {
  //         margin-left: .75rem;
  //       }
  //       flex: 0 0 47% !important;
  //     }
  //     @include media-breakpoint-down(sm) {
  //       margin-top: 1rem;
  //       margin-bottom: 1rem;
  //     }
  //     p {
  //       font-size: 0.9rem;
  //       font-weight: 500;
  //     }
  //   }
  // }
  &.columns {
    width: calc(100% + 24px);
    margin-bottom: -24px;

    .card {
      margin: 0 24px 24px 0 !important;
      flex-basis: 100% !important;
      max-width: calc(25% - 24px);
    }

    @media (max-width: 1130px) {
      .card {
        max-width: calc(50% - 24px);
      }
    }

    @include tabletPortrait {
      width: 100%;
      margin-bottom: 0;

      .card {
        margin-right: 0 !important;
        width: 100%;
        max-width: 100%;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }

    // .card {
    //   margin-left: 0.75rem;
    //   margin-right: 0.75rem;
    //   margin-bottom: 1.5rem;
    //   &:first-child {
    //     @include media-breakpoint-up(md) {
    //       margin-left: 0;
    //     }
    //     margin-right: 0.75rem;
    //   }
    //   &:last-child {
    //     margin-left: 0.75rem;
    //     @include media-breakpoint-up(md) {
    //       margin-right: 0;
    //     }
    //   }
    // }
  }

  &.two-columns {
    width: calc(100% + 30px);
    margin-bottom: -30px;

    .card {
      margin: 0 30px 30px 0 !important;
      flex-basis: 100% !important;
      max-width: calc(50% - 30px);
    }

    @include tabletPortrait {
      width: 100%;
      margin-bottom: 0;

      .card {
        margin-right: 0 !important;
        max-width: 100%;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.card-body,
.media-body {
  > .card-lead:first-child {
    line-height: 1.2;
    margin-top: -0.15em;
    padding-bottom: 0.45em;

    + ul,
    + ol {
      margin-top: 0.7rem;
    }
  }
}
