.fb-tooltip {
  font-size: 12px;
  font-weight: 500;
  font-family: $bodyFont;
  color: #fff;
  background-color: #000;
  opacity: 0.78;
  pointer-events: none;
  padding: 7px 12px;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: var(--tooltip-offset-top, 5px);
  margin-left: var(--tooltip-offset-left, 0px);
  width: max-content;
  max-width: 140px;
  z-index: 99999;

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background-color: #000;
    margin-top: -4px;
    z-index: -1;
  }
}

a,
button,
.fb-tooltip-wrapper {
  &:not(:hover) .fb-tooltip {
    @extend .sr-only;
  }
}
