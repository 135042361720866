.search-modal {
  .modal-dialog {
    max-width: 1310px;
  }
  .modal-content {
    @include media-breakpoint-down(sm) {
      height: 90%;
    }
  }
  .modal-body {
    overflow-y: auto;
  }
  .search-term {
    input {
      font-size: 1.5rem;
      color: $gray-600;
      border: 0;
      border-bottom: 2px solid $gray-500;
      font-weight: 500;

      @include placeholder {
        color: #cbcbcb;
      }
    }
  }

  .search-container {
    .search-results {
      a {
        background-color: $gray-100;
        color: $black;
        span {
          color: $green;
        }

        .description {
          b {
            background-color: rgba(20,204,204,.3);
          }

        }
      }
    }
  }
}
