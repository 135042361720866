.browse-by-category {
  @include tickerTape;
}

.blog-list-grid {
  .card-group {
    &[data-count='1'],
    &[data-count='2'],
    &[data-count='3'] {
      justify-content: center;
    }
  }
}
