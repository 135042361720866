.gallery-section {
  .section-top {
    padding-bottom: 40px;
  }
}

.light-gallery-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.gallery-col {
  display: flex;
  flex-direction: column;

  &[data-numCols='1'] {
    flex: 100%;
  }

  &[data-numCols='2'] {
    flex: 50%;
  }

  &[data-numCols='3'] {
    flex: 33.33%;
  }

  &[data-numCols='4'] {
    flex: 25%;
  }

  &[data-numCols='5'] {
    flex: 20%;
  }
}

button.gallery-item {
  border: none;
  background: none;
}

.gallery-item {
  padding: 8px 4px 0px 4px;
  flex-grow: 1;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

button.video-gallery-item {
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 50%;
  }

  &:before {
    border-radius: 50%;
    border: 4px solid rgba(212, 212, 212, 0.95);
    width: 60px;
    height: 60px;
    transform: translate(50%, -50%);
  }

  &:after {
    border: 12px solid transparent;
    border-left: 20px solid rgba(212, 212, 212, 0.95);
    transform: translate(calc(50% + 8px), -50%);
  }

  &:hover {
    &:before {
      border: 4px solid rgba(225, 225, 225, 0.97);
    }
    &:after {
      border-left: 20px solid rgba(225, 225, 225, 0.97);
    }
  }
}

.gallery-items-height-small {
  .gallery-item {
    height: 150px;
  }
}

.gallery-items-height-medium {
  .gallery-item {
    height: 250px;
  }
}

.gallery-items-height-large {
  .gallery-item {
    height: 350px;
  }
}

.lg-outer.lg-grab img.lg-object {
  cursor: auto !important;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: pointer !important;
}

.KenticoAdminPageBuilder {
  .gallery-section {
    div[zone-name='section-headline'] {
      margin-bottom: 30px;
    }
    .gallery-item {
      width: 100%;
    }

    .section-top {
      padding-bottom: 0px;
    }
  }
  .gallery-section:not(.gallery-items-height-large):not(.gallery-items-height-medium):not(.gallery-items-height-small) {
    .gallery-col {
      .ktc-widget-zone {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      .ktc-widget {
        flex-grow: 1;
        & > div:not(.ktc-admin-ui) {
          height: 100%;
          div {
            height: 100%;
          }
        }
      }

      .gallery-item {
        height: 100%;
      }
    }
  }
}

@media (max-width: 800px) {
  .gallery-col {
    &[data-numCols='4'] {
      flex: 50%;
    }

    &[data-numCols='5'] {
      flex: 33.33%;
      &:nth-child(1),
      &:nth-child(2) {
        flex: 50%;
      }
    }
  }
}

@media (max-width: 600px) {
  .gallery-col {
    flex: 100% !important;
  }

  .gallery-items-height-small,
  .gallery-items-height-medium,
  .gallery-items-height-large {
    .gallery-item {
      height: auto;
    }
  }
}
