$width: 100px;

.spinner {
  position: absolute;
  width: $width;
  height: $width;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  pointer-events: none;
  color: $tealColor;
  display: none;

  svg {
    animation: spinnerRotate 2s linear infinite;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: spinnerDash 1.2s ease-in-out infinite;
    stroke-linecap: round;
    stroke: currentColor;
    transform-origin: center center;
  }

  &.paused {
    svg,
    circle {
      animation-play-state: paused;
    }
  }
}

@keyframes spinnerRotate {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spinnerDash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

.fb-spinner-wrapper {
  position: absolute;
  z-index: 9999;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  display: none;
  opacity: 0;
  animation: fadeIn 0.4s ease;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fb-spinner {
  border-radius: 6px;
  animation: fbLoading 2s infinite ease-in-out;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: inherit;
  }

  &:before {
    background-image: url('/Content/breakout-fremont-bank/dist/images/icon-dark.svg');
  }

  &:after {
    background-image: url('/Content/breakout-fremont-bank/dist/images/icon-light.svg');
    transform: rotateY(180deg);
  }
}

@keyframes fbLoading {
  0% {
    transform: perspective(500px) rotateX(0deg) rotateY(0deg);
  }

  23% {
    transform: perspective(500px) rotateX(180deg) rotateY(0deg);
  }

  25% {
    transform: perspective(500px) rotateX(180deg) rotateY(0deg);
  }

  48% {
    transform: perspective(500px) rotateX(180deg) rotateY(-180deg);
  }

  50% {
    transform: perspective(500px) rotateX(180deg) rotateY(-180deg);
  }

  73% {
    transform: perspective(500px) rotateX(0) rotateY(-180deg);
  }

  75% {
    transform: perspective(500px) rotateX(0) rotateY(-180deg);
  }

  98% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
}
