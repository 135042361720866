.search-agent-profile {
  padding-right: 0px;
  display: flex;

  @include tabletPortrait {
    padding-top: 5px;
  }

  @media (max-width: 650px) {
    display: block;
    padding-top: 0;
  }

  @include mobile {
    margin-top: 0px;
  }
}

.search-agent-photo {
  width: 230px;
  max-height: 272px;
  flex: none;
  padding-top: 6px;
  margin-right: 30px;
  max-height: 272px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1300px) {
    width: 200px;
    margin-right: 25px;
  }

  @media (max-width: 650px) {
    padding-top: 10px;
    margin: 0 0 25px 0;
    width: 100%;
    max-height: 500px;
  }

  @include mobile {
    margin-bottom: 22px;
  }
}

.search-agent-content {
  width: 100%;

  .agent-contact-info {
    background-color: transparent;
    margin: 0;
    max-width: none;
    box-shadow: none;
    padding: 0;
    width: 100%;

    > * {
      padding: 0.4em 0 0.4em 36px;

      &:before {
        top: 50%;
        transform: translateY(-50%);
      }

      &:after {
        display: none;
      }
    }
  }
}

.best-bet-agent-bio {
  margin-right: 5px;

  &:empty {
    display: none;
  }
}

.best-bet-agent-request-contact {
  margin-top: 20px;
}
