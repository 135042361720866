.message {
  font-size: var(--message-font-size, 16px);
  padding: var(--message-padding, 16px 22px);
  color: var(--message-text-color, var(--body-text-color));
  background-color: var(--message-bg-color, #fbfbfb);
  border: 1px solid var(--message-border-color, #e2e2e3);
  border-radius: 5px;

  &.hidden {
    display: none;
  }

  &:not(.hidden) + .message {
    margin-top: 15px;
  }

  p {
    margin-bottom: 0;
    margin-top: 0.9em;

    &:first-child,
    &.hidden + p {
      margin-top: 0;
    }
  }
}

.message-error {
  --message-text-color: #94373c;
  --message-bg-color: #fde7e7;
  --message-border-color: #fbc2c2;
}

.message-success {
  --message-text-color: #2e6e2e;
  --message-bg-color: #e7f7e7;
  --message-border-color: #7cbb7c;
}
