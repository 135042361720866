.btn {
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: 600;

  sup {
    font-weight: 400;
  }

  @include media-breakpoint-down(md) {
    padding: 0.35rem 1rem;
  }
}

.btn-primary,
.btn-outline-primary,
.btn-success,
.btn-outline-success-alt {
  padding: 0.63em 1.35em 0.64em 1.35em;

  @include media-breakpoint-down(md) {
    padding: 0.63em 1.35em 0.633em 1.35em;
    line-height: 1.4;
  }

  @at-root {
    .section-bottom .buttons-wrapper &,
    .section-bottom.buttons-wrapper &,
    .section-cta &,
    .cta-button &,
    &.large {
      padding: 0.75em 1.8em 0.76em;
      font-size: 0.87em;
      min-width: 150px;
    }
  }
}

main {
  .btn-outline-primary {
    color: $darkTealColor;
  }
}

.btn-success {
  border-color: $white;

  &:hover {
    color: $green;
    background-color: $white;
    border-color: $white;
  }
}

.btn-outline-success-alt {
  border-color: $white;
  color: $white;
  background-color: transparent;
  &:hover {
    background-color: $white;
    color: $green !important;
  }
}

/* faq button links */
.btn-faq-general {
  background-color: #008080;
  color: $white;
}

.btn-faq-business-banking {
  background-color: #e1702c;
  color: $white;
}

.btn-faq-digital-banking {
  background-color: #176980;
  color: $white;
}

.btn-faq-home-loans {
  background-color: #82afc6;
  color: $white;
}

.btn,
.btn-primary,
.btn-outline-primary {
  &.loading {
    pointer-events: none;
    border-color: #adabab !important;
    color: #828282 !important;
    background-color: rgba(#dcdcdc, 0.12) !important;
    transition: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

// Page builder button widget
.content-btn {
  margin: 4px 4px 4px 0;
  vertical-align: middle;

  + p {
    margin-top: calc(1rem + 2px);
  }

  &:has(+ .ltr-underline) {
    margin-right: 15px;
  }

  @include pageBuilder {
    box-shadow: none !important;
    cursor: text !important;

    &:focus,
    &:active {
      background-color: inherit;
    }

    &.btn-primary {
      background-color: $tealColor !important;
      border-color: $tealColor !important;
      color: #fff !important;
    }

    &.btn-outline-primary {
      background-color: transparent !important;
      border-color: $darkTealColor !important;
      color: $tealColor !important;

      @at-root {
        .text-white &,
        &.text-white {
          color: #fff !important;
          border-color: #fff !important;
        }
      }
    }
  }
}

.btn-print {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #fff;
  font-weight: 600;
  line-height: 1;
  padding: 12px 18px;
  border-radius: 4px;
  border: 1px solid #c7d0db;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #40424d;
  transition: all 0.2s;
  transition-property: border-color, box-shadow, color;
  position: relative;

  &:before {
    content: '';
    width: 18px;
    height: 18px;
    flex: none;
    margin-right: 9px;
    background-color: currentColor;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.736328 8.18178C0.736328 6.78099 1.8719 5.64542 3.27269 5.64542H14.7272C16.128 5.64542 17.2636 6.78099 17.2636 8.18178V11.4545C17.2636 12.8553 16.128 13.9909 14.7272 13.9909H13.0909C12.5938 13.9909 12.1909 13.5879 12.1909 13.0909C12.1909 12.5938 12.5938 12.1909 13.0909 12.1909H14.7272C15.1339 12.1909 15.4636 11.8612 15.4636 11.4545V8.18178C15.4636 7.7751 15.1339 7.44542 14.7272 7.44542H3.27269C2.86601 7.44542 2.53633 7.7751 2.53633 8.18178V11.4545C2.53633 11.8612 2.86601 12.1909 3.27269 12.1909H4.90906C5.40611 12.1909 5.80906 12.5938 5.80906 13.0909C5.80906 13.5879 5.40611 13.9909 4.90906 13.9909H3.27269C1.8719 13.9909 0.736328 12.8553 0.736328 11.4545V8.18178Z' fill='black'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.00906 3.27269C4.00906 1.8719 5.14462 0.736328 6.54542 0.736328H11.4545C12.8553 0.736328 13.9909 1.8719 13.9909 3.27269V6.54542C13.9909 7.04248 13.5879 7.44542 13.0909 7.44542C12.5938 7.44542 12.1909 7.04248 12.1909 6.54542V3.27269C12.1909 2.86601 11.8612 2.53633 11.4545 2.53633H6.54542C6.13874 2.53633 5.80906 2.86601 5.80906 3.27269V6.54542C5.80906 7.04248 5.40611 7.44542 4.90906 7.44542C4.412 7.44542 4.00906 7.04248 4.00906 6.54542V3.27269Z' fill='black'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.00906 11.4545C4.00906 10.0537 5.14462 8.91815 6.54542 8.91815H11.4545C12.8553 8.91815 13.9909 10.0537 13.9909 11.4545V14.7272C13.9909 16.128 12.8553 17.2636 11.4545 17.2636H6.54542C5.14462 17.2636 4.00906 16.128 4.00906 14.7272V11.4545ZM6.54542 10.7181C6.13874 10.7181 5.80906 11.0478 5.80906 11.4545V14.7272C5.80906 15.1339 6.13874 15.4636 6.54542 15.4636H11.4545C11.8612 15.4636 12.1909 15.1339 12.1909 14.7272V11.4545C12.1909 11.0478 11.8612 10.7181 11.4545 10.7181H6.54542Z' fill='black'%3E%3C/path%3E%3C/svg%3E");
  }

  &:hover {
    color: #24262d;
    border-color: #b3bcc6;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15) !important;
  }

  &:focus-visible {
    outline: 2px solid var(--teal-color);
  }

  .spinner {
    left: 19px;
    transform: translateY(-50%);
    color: #40424d;
  }

  &.loading {
    &:before {
      visibility: hidden;
    }

    .spinner {
      display: block;
    }
  }
}

.ktc-widget {
  &[data-widget-type='button-widget'],
  &:is(:has(.content-btn-widget)) {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 33px;
    min-height: 77px !important;
    margin-right: 8px;

    .content-btn {
      margin: 0 !important;
      box-shadow: none !important;
    }
  }
}
