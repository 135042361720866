.no-scroll {
  @include media-breakpoint-down(md) {
    overflow: hidden;
    height: 100%;
  }
}

.logo {
  svg {
    width: 270px;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .navbar-toggler {
    .navbar-close-icon {
      font-size: 1.8rem;
    }

    &[aria-expanded='false'] {
      .navbar-close-icon {
        display: none;
      }
    }

    &[aria-expanded='true'] {
      .navbar-toggler-icon {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
  }
}

.main-navigation {
  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1230px;
    }
  }

  .navbar-collapse {
    @include media-breakpoint-down(md) {
      background-color: $white;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 2.4rem;
    }

    .nav-container {
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 75%;
        overflow-y: auto;
      }
    }

    .main-nav {
      @include media-breakpoint-down(md) {
        position: fixed;
        top: 11rem;
        width: 100%;
        height: 100%;
        visibility: hidden;
        height: 0;
      }
    }

    &.show {
      @include media-breakpoint-down(md) {
        .main-nav {
          visibility: visible;
          height: 100%;
        }
      }
    }
  }
}

.main-nav {
  @include media-breakpoint-down(md) {
    background-color: $gray-200;
  }

  .nav-item {
    & > a {
      @extend %link-underline;
    }

    &.active > a:after {
      border-bottom: 2px solid $green;
      transform: scaleX(1);
    }
  }

  .navbar-nav > li {
    margin: 0 0.25rem;
    &:focus-within {
      @include media-breakpoint-up(lg) {
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
          // outline: 1px solid $gray-300;
        }
      }
    }
  }

  .navbar-nav > .nav-item > a {
    text-transform: uppercase;
    font-size: 0.8125rem;
    font-weight: 600;

    @include media-breakpoint-up(lg) {
      // padding: 1.4rem 0.5rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 0.9375rem;
    }
  }

  .dropdown {
    a {
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
        color: $gray-800;
        font-weight: 500;
      }

      @include media-breakpoint-down(md) {
        display: inline-block;
        letter-spacing: 1px;
        font-size: 0.9375rem;
        color: $gray-800 !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        &:after {
          display: none !important;
        }
      }
    }

    .dropdown-menu {
      pointer-events: none;
    }

    @include media-breakpoint-down(md) {
      & > a {
        padding: 0.75rem 0;
      }
    }

    & > .icon-toggle {
      position: absolute;
      right: 0;
      top: 0.75rem;
    }

    .icon-toggle {
      span {
        color: $green;
        opacity: 1;

        &:after {
          content: '➜';
        }
      }

      .close {
        transform: rotate(90deg);
      }
    }

    .icon-toggle[aria-expanded='false'] {
      .close {
        display: none;
      }
    }

    .icon-toggle[aria-expanded='true'] {
      top: 1rem;

      .open {
        display: none;
      }

      & + .dropdown-menu {
        // expand secondary nav
        @include media-breakpoint-down(md) {
          display: block;
          position: static;
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
          height: 100%;
        }
      }
      & + ul {
        // expand tertiary nav
        visibility: visible;
        height: 100%;
      }
    }

    .header-nav {
      a {
        @include media-breakpoint-down(md) {
          display: inline-block;
          padding: 0.45rem 0;
          font-weight: 400 !important;
          letter-spacing: 0 !important;
        }
      }

      & > li {
        & > .icon-toggle {
          position: absolute;
          right: 0.9275rem;
          top: 0.4rem;
        }

        & > .icon-toggle[aria-expanded='true'] {
          top: 0.575rem;
        }
      }

      ul {
        @include media-breakpoint-down(md) {
          visibility: hidden;
          height: 0;

          a {
            text-transform: capitalize;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        & > li {
          > a,
          > strong {
            border-bottom: 2px solid $green;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 15px;
            letter-spacing: 0.8px;
            padding-bottom: 0.25rem;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      .dropdown-menu {
        transition: all 0.3s;
        display: block !important;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        height: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      position: initial;

      &:hover {
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        }
      }

      ul {
        a {
          font-weight: 400;
        }
      }

      .dropdown-menu {
        display: block !important;
        visibility: hidden;
        opacity: 0;
        top: 4.75rem;

        .header > li > a {
          font-size: 0.9375rem;
          letter-spacing: 0.075rem;
          text-transform: uppercase;
          border-bottom: solid 2px $primary;
          padding-bottom: 0.4rem;
          font-weight: 500;
        }
      }
    }
  }

  .nav-dropdown-items {
    background-color: #f2f2f2;
    // background-color: #f8f8f8;
  }

  .promo-area {
    background-color: #ebebeb;
    // background-color: #f3f3f3;

    h2 {
      font-family: $headingFont;
      font-weight: 500;
    }

    img {
      height: auto;
      max-width: 100%;

      & + p {
        margin-top: 20px;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .ltr-underline {
    @extend %link-underline-base;
    font-size: 0.875rem !important;
    font-weight: 500;

    &:hover {
      color: $green !important;
    }
  }
}
