// By default, SVG icons inherit current font's color and size
.svg-icon {
  flex-shrink: 0;
  fill: currentColor;
  width: 1.25em;
  height: 1.25em;
}

// Don't use current font color override (useful for multi-color SVG icons)
.svg-icon-no-fill {
  fill: initial;
}

// Resize relative to current font (e.g., svg-icon-2x doubles the size)
@for $i from 2 through 10 {
  .svg-icon-#{$i}x {
    width: #{$i}em;
    height: #{$i}em;
  }
}
