// Location map
.locations-map {
  background-color: #f2f2f2;

  .container {
    max-width: none;
    padding: 0;
  }

  .location-search {
    padding: 30px;

    .btn {
      min-width: 120px;
    }
  }

  .location-name {
    pointer-events: none;
  }

  @media (min-width: 992px) {
    .col-lg-8:first-child {
      flex-grow: 1;
      max-width: none;

      + .col-lg-4 {
        max-width: 480px;
      }
    }
  }
}

// Location details page
.location-details {
  #locations-detail-geo-locator,
  #btn-locations-detail-search + p,
  .description {
    display: none;
  }

  #btn-locations-detail-search {
    width: 100%;
    max-width: 120px;
  }

  @media (min-width: 992px) {
    .location-search {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    #location-map {
      min-height: 350px;
    }
  }
}
