@use 'sass:math';

.toggle-wrapper {
  --indicator-offset: 6px;
  --border-radius: 11px;

  display: flex;
  align-items: flex-start;

  @at-root .section-top + .section-content &:first-child {
    margin-top: -15px;

    @media (max-width: 1300px) {
      margin-top: 0;
    }
  }
}

.toggle {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 410px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.toggle-button {
  @include disableNativeButton;
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: auto;
  border: none;
  color: #effdfd;
  font-size: 14px;
  position: relative;
  z-index: 15;
  padding: 17px 8px;
  background-color: transparent;
  transition: color 0.08s linear 0.05s;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.1;
  outline: none;
  box-shadow: none !important;

  &:hover,
  &:focus {
    color: #fff;
  }

  span {
    position: relative;
    z-index: 20;
  }

  @include keyboardFocus {
    outline: 2px solid #fff;
    outline-offset: -10px;
  }

  &.active {
    color: $darkTealColor !important;
  }
}

.toggle-bg-wrapper {
  position: absolute;
  z-index: 10;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: var(--border-radius);
  overflow: hidden;
  background: linear-gradient(130deg, #068181 1.46%, #026868 100%);
  pointer-events: none;
  padding: var(--indicator-offset);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;

    @at-root .toggle:hover & {
      opacity: 0.1;
    }
  }
}

.toggle-bg {
  @extend %smoothAnimation;
  background-color: #f3f8f8;
  position: relative;
  display: block;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.36, 0.26, 0.03, 1);
  will-change: transform;
  border-radius: calc(var(--border-radius) - var(--indicator-offset));
  width: calc(50% - (var(--indicator-offset) / 2));

  &.right {
    transform: translateX(calc(100% + var(--indicator-offset)));
  }
}

.toggle-content-wrapper {
  margin-top: 75px;
}

.toggle-content {
  display: none;
  justify-content: center;

  &.active {
    display: flex;
  }
}

.toggle-wrapper.gray {
  --indicator-offset: 8px;

  .toggle-bg-wrapper {
    background: #ecf0ef;

    &:before {
      transition: opacity 0.15s;
      background-color: rgba(#000, 0.03);
    }
  }

  .toggle {
    &:hover {
      .toggle-bg-wrapper {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  .toggle-bg {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 2px 5px rgba(0, 0, 0, 0.07),
      0 0 0 2.5px $tealColor;
  }

  .toggle-button {
    color: #212121 !important;
    padding: 20px 8px;

    @include keyboardFocus {
      outline-color: $tealColor !important;
    }
  }
}

@media (max-width: 991px) {
  .toggle-content-wrapper {
    margin-top: 50px;
  }
}

@media (max-width: 930px) {
  .toggle-content {
    &.active {
      display: block;
    }
  }
}

@media (max-width: 600px) {
  .toggle-wrapper {
    --indicator-offset: 5px;
  }

  .toggle-content-wrapper {
    margin-top: 35px;
  }

  .toggle-button {
    font-size: 14px;
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

@media (max-width: 400px) {
  .toggle-button {
    font-size: 13px;
  }
}
