$tealColor: #008080;

.comparison-module {
  .product-toggle-container {
    // margin-bottom: 60px;
    @include sectionContentSpacing(0px, 'bottom');

    * {
      text-decoration: none;
    }
  }

  .btn-group-toggle {
    label {
      color: #6f6f6f;
      border: 2px solid #8e8e8e;

      &:hover,
      &:focus {
        text-decoration: none;

        &:not(.active) {
          border: 2px solid $tealColor;
          color: $tealColor;
        }
      }

      &.focus,
      &.active {
        color: #fff;
        background-color: $tealColor;
        border: 2px solid $tealColor;
        font-weight: bold;
      }

      sup {
        font-weight: 500 !important;
      }
    }
  }

  .comparison-module-items {
    margin: 0 auto;
  }

  .card-group {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .card {
    margin: 0 15px 30px 15px !important;
    padding: 0 !important;
  }

  .card-body {
    padding: 40px;
  }

  h3 {
    font-size: 27px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d6d6d6;
    margin-top: -6px;
  }

  .comparison-disclosure {
    margin-top: 35px;
    text-align: center;
    opacity: 0.85;
  }

  @include tablet {
    .card-group {
      display: block !important;
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
    }

    .card {
      margin: 0 auto 30px auto !important;
      display: block;
      width: 100%;
      max-width: 700px;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    h3 {
      font-size: 24px;
    }
  }

  @media (max-width: 600px) {
    .card-body {
      padding: 30px;
    }
  }

  @include mobile {
    h3 {
      font-size: 20px;
    }

    .card {
      margin-bottom: $contentPaddingMobile !important;
    }

    .comparison-disclosure {
      text-align: left;
      margin-top: 30px;
    }
  }

  @media (max-width: 450px) {
    h3 {
      padding: 0;
      border-bottom: none;
      text-align: center;
      margin-top: 0;
    }

    .sample-loan {
      width: calc(100% + 60px);
      margin-left: -30px;

      $borderColor: #ededed;

      .row,
      .adjustable-payments-wrapper {
        border-color: $borderColor;
        padding: 12px 20px !important;

        &:first-child {
          border-top: 1px solid $borderColor;
        }

        &:last-child {
          padding-bottom: 0 !important;
          margin-bottom: -13px !important;
        }
      }

      .adjustable-payments-wrapper {
        border-top: 1px solid $borderColor;
        margin-top: 12px;

        .adjustable-payments > div {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        strong {
          display: block;
        }
      }
    }
  }
}
