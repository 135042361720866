// Mixins used in Fremont Bank SCSS
// These use camel case to avoid potential
// conflicts with variables created by Wakefly

// Wrapper around Wakefly's breakpoint mixin.
// Added so that we can continue using
// camel case naming convention
@mixin breakpointMax($size) {
  @include media-breakpoint-down($size) {
    @content;
  }
}

@mixin breakpointMin($size) {
  @include media-breakpoint-up($size) {
    @content;
  }
}

@mixin tickerTape {
  box-shadow: $quickLinksShadow;
  border-top: 1px solid $quickLinksBorderColor;
  border-bottom: 1px solid $quickLinksBorderColor;
  position: relative;

  h2,
  .h2 {
    position: relative;
    color: $quickLinksHeadingColor;
    letter-spacing: normal !important;
  }
}

@mixin desktop {
  @media (min-width: #{$desktopMinWidth}) {
    @content;
  }
}

@mixin contentUp {
  @media (min-width: #{$contentMaxWidth + 1px}) {
    @content;
  }
}

@mixin content {
  @media (max-width: #{$contentMaxWidth}) {
    @content;
  }
}

@mixin tabletUp {
  @media (min-width: #{$tabletMaxWidth + 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tabletMaxWidth}) {
    @content;
  }
}

@mixin tabletPortraitUp {
  @media (min-width: #{$tabletPortraitMaxWidth + 1px}) {
    @content;
  }
}

@mixin tabletPortrait {
  @media (max-width: #{$tabletPortraitMaxWidth}) {
    @content;
  }
}

@mixin menuLarge {
  @media (max-width: 1180px) and (min-width: #{$desktopMinWidth}) {
    @content;
  }
}

@mixin mobileUp {
  @media (min-width: #{$mobileMaxWidth + 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobileMaxWidth}) {
    @content;
  }
}

@mixin menuMobile {
  @media (max-width: #{$mobileMenuMaxWidth}) {
    @content;
  }
}

@mixin sectionPadding($desktopOffset: 0px) {
  padding-top: $sectionPaddingDesktop + $desktopOffset;
  padding-bottom: $sectionPaddingDesktop + $desktopOffset;

  @media (max-width: #{$contentMaxWidth}) {
    padding-top: $contentPaddingDesktop + 10px;
    padding-bottom: $contentPaddingDesktop + 10px;
  }

  @include tablet {
    padding-top: $sectionPaddingTablet;
    padding-bottom: $sectionPaddingTablet;
  }

  @include tabletPortrait {
    padding-top: $sectionPaddingTabletPortrait;
    padding-bottom: $sectionPaddingTabletPortrait;
  }

  @include mobile {
    padding-top: $sectionPaddingMobile;
    padding-bottom: $sectionPaddingMobile;
  }
}

@mixin contentPadding {
  padding-left: $contentPaddingDesktop;
  padding-right: $contentPaddingDesktop;

  @include tablet {
    padding-left: $contentPaddingTablet;
    padding-right: $contentPaddingTablet;
  }

  @include tabletPortrait {
    padding-left: $contentPaddingTabletPortrait;
    padding-right: $contentPaddingTabletPortrait;
  }

  @include mobile {
    padding-left: $contentPaddingMobile;
    padding-right: $contentPaddingMobile;
  }
}

@mixin sectionContentSpacing($adjustment: 0px, $direction: 'top') {
  margin-#{$direction}: 60px + $adjustment;

  @if $direction == 'bottom' {
    &:last-child {
      margin-bottom: 0;
    }
  } @else {
    &:first-child {
      margin-#{$direction}: 0;
    }
  }

  @media (max-width: #{$contentMaxWidth}) {
    margin-#{$direction}: 55px + $adjustment;
  }

  @include tablet {
    margin-#{$direction}: 45px + $adjustment;
  }

  @include tabletPortrait {
    margin-#{$direction}: 40px + $adjustment;
  }

  @include mobile {
    margin-#{$direction}: $contentPaddingMobile + $adjustment;
  }
}

@mixin smoothGradient($startColor: #000, $direction: 'to bottom') {
  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop * 0.01);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(#{$direction}, $stops);
}

@mixin disableNativeButton($addTransparentBg: true) {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  @if $addTransparentBg {
    background-color: transparent;
  }
}

@mixin highlight {
  @at-root #{&} ::selection {
    @content;
  }

  @at-root #{&} ::-moz-selection {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin targetIE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin targetIOS {
  @at-root body.ios & {
    @content;
  }
}

@mixin keyboardFocus {
  @at-root body:not(.using-mouse) &:focus {
    @content;
  }
}

@mixin graySection {
  @at-root .bg-gray-100 & {
    @content;
  }
}

@mixin removeDefaultStyles {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  text-decoration: none;
}

@mixin preventAnimationWhileResizing {
  @at-root body.resizing & {
    transition: none !important;
    animation: none !important;
  }
}

@mixin kentico {
  @at-root body.KenticoAdmin & {
    @content;
  }
}

@mixin pageBuilder {
  @at-root body.KenticoAdminPageBuilder & {
    @content;
  }
}

/*---------------------------*/
/* Extensions */

%noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%smoothAnimation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
}
