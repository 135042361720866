.inline-search-wrapper {
  position: relative;
  text-align: left;
}

.inline-search-input-wrapper {
  --search-icon-size: 1.4em;
  --search-icon-left: 18px;
  --search-icon-color: #949598;

  position: relative;
  font-size: 16px;

  &:focus-within {
    --search-icon-color: var(--search-icon-color-hover, var(--teal-color));
  }

  .spinner {
    left: auto;
    right: 17px !important;
  }
}

.inline-search-input {
  width: 100%;
  padding: 8px 10px 8px calc(var(--search-icon-left) + var(--search-icon-size) + 8px);
  height: 57px;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  color: #313134;
  font-size: 1em;
  border: 1px solid #c7d0db;
  transition: none;
  font-weight: 500;
  outline: none;
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  outline-offset: -2px;
  backface-visibility: hidden;

  &:focus {
    --outline: var(--outline-color, var(--teal-color));

    outline: 2px solid var(--outline);
    border-color: transparent;
    box-shadow: none;
    border-radius: 5px;
  }

  @at-root .inline-search-wrapper.loading & {
    padding-right: 60px;
  }

  @include placeholder {
    color: #8b8b94;
  }

  @include targetIOS {
    font-size: 16px;
  }
}

.inline-search-icon {
  width: var(--search-icon-size);
  height: var(--search-icon-size);
  position: absolute;
  top: 50%;
  left: var(--search-icon-left);
  transform: translateY(-50%);
  margin-top: 0.0223em;
  pointer-events: none;

  path {
    fill: var(--search-icon-color);
  }
}

.inline-search-results-wrapper {
  margin: 8px 0 0 0;
  padding: 10px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #e3e4e7;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  right: 0;
  font-size: 14px;
  max-height: var(--search-results-max-height, 390px);
  color: #313134;
  overflow: hidden auto;
  box-shadow: 0px 64px 80px 0px rgba(14, 15, 47, 0.09),
    0px 26.738px 33.422px 0px rgba(14, 15, 47, 0.06),
    0px 14.295px 17.869px 0px rgba(14, 15, 47, 0.05),
    0px 8.014px 10.017px 0px rgba(14, 15, 47, 0.05),
    0px 4.256px 5.32px 0px rgba(14, 15, 47, 0.04),
    0px 1.771px 2.214px 0px rgba(14, 15, 47, 0.03);
  scrollbar-width: thin;
  scrollbar-color: #a1a3a6 transparent;

  &.force-hidden {
    display: none !important;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a1a3a6;
  }

  &:empty {
    display: none;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.inline-search-wrapper:not(:focus-within) {
  .inline-search-results-wrapper,
  .spinner {
    display: none !important;
  }
}

.inline-search-result {
  --text-color: #474749;
  --search-icon-color: #c5c6ca;

  padding: 12px 18px 12px 16px;
  color: var(--text-color) !important;
  display: flex;
  text-decoration: none !important;
  position: relative;
  z-index: 1001;

  &:first-child {
    margin-top: 1px;
  }

  &:last-child {
    margin-bottom: 1px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    right: 0;
    border-radius: 5px;
    background-color: #f5f7f9;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
  }

  &:after {
    content: '';
    position: absolute;
    left: 18px;
    right: 18px;
    bottom: 0;
    height: 1px;
    background-color: #dbdce0;
    z-index: -2;
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 2 2' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cline x1='0' y1='1' x2='2' y2='1' stroke='%23000' stroke-width='1' stroke-dasharray='6 6' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E");
  }

  &:last-child:after {
    display: none;
  }

  // &:hover,
  &.active {
    --text-color: #313134;
    --search-icon-color: #868991;

    &:before {
      opacity: 1;
    }
  }
}

.inline-search-result-icon {
  width: 1.3em;
  height: 1.3em;
  position: relative;
  top: 0.15em;
  margin-right: 10px;
  display: block;
  flex: none;
  background-color: var(--search-icon-color);
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' fill='none'%3E%3Cpath d='M36.256 33.337l-9.1-9.1c1.4-1.837 2.188-4.2 2.188-6.738 0-6.3-5.075-11.375-11.375-11.375S6.593 11.201 6.593 17.501c0 6.3 5.075 11.374 11.375 11.374 2.538 0 4.812-.788 6.738-2.187l9.1 9.1c.35.35.788.526 1.226.526.438 0 .874-.176 1.226-.525.698-.7.698-1.75-.002-2.451zm-18.287-7.961a7.842 7.842 0 01-7.875-7.875c0-4.376 3.5-7.875 7.875-7.875 4.376 0 7.875 3.499 7.875 7.875a7.844 7.844 0 01-7.875 7.875z' fill='%23929292'%3E%3C/path%3E%3C/svg%3E");
}

.inline-search-message {
  padding: 15px 22px;
  font-size: 16px;
  display: flex;

  &:before {
    content: '';
    display: block;
    width: var(--search-icon-size, 1.3em);
    height: var(--search-icon-size, 1.3em);
    position: relative;
    background-color: var(--search-icon-color, #868991);
    flex: none;
    margin-right: var(--search-icon-spacing, 15px);
  }
}

.inline-search-no-results {
  &:before {
    top: 0.1em;
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M74.953 14.867a4.998 4.998 0 0 0 0-7.07 4.998 4.998 0 0 0-7.07 0L7.797 67.883a4.998 4.998 0 0 0 3.535 8.535 4.987 4.987 0 0 0 3.535-1.465zm-5.191 49.238a36.195 36.195 0 0 0 7.988-22.73c0-4.324-.758-8.473-2.145-12.32-.96-2.656-4.402-3.367-6.398-1.371l-.172.172c-1.082 1.082-1.398 2.683-.89 4.125 1.421 4.031 1.96 8.472 1.367 13.102-1.63 12.652-11.781 22.8-24.43 24.43-4.63.594-9.07.054-13.102-1.367-1.442-.508-3.043-.192-4.121.89l-.172.172c-2 1.996-1.29 5.438 1.367 6.398a36.268 36.268 0 0 0 12.32 2.145 36.216 36.216 0 0 0 22.73-7.988l24.062 24.062a3.987 3.987 0 0 0 2.828 1.172 3.998 3.998 0 0 0 2.828-6.828zM7.042 53.406a36.423 36.423 0 0 1-2.011-13.574c.777-18.73 16.07-34.023 34.8-34.801 4.774-.2 9.353.527 13.575 2.012 2.797.98 3.652 4.504 1.559 6.597l-.032.036c-1.105 1.101-2.746 1.425-4.219.91a28.346 28.346 0 0 0-13.047-1.348c-12.652 1.629-22.8 11.777-24.43 24.43-.59 4.606-.058 9.031 1.348 13.047.52 1.473.195 3.113-.91 4.215l-.035.035c-2.094 2.094-5.617 1.238-6.598-1.559z' fill-rule='evenodd'/%3E%3C/svg%3E");
  }
}

.inline-search-result-error {
  --search-icon-size: 1.2em;
  --search-icon-color: #eb5c5e;
  --search-icon-spacing: 12px;

  color: #a63739;

  @at-root .inline-search-results-wrapper:has(&) {
    background-color: #fbf6f6;
    border-color: #e8c7c7;
  }

  &:before {
    top: 0.15em;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0Zm1.046 13.891a.84.84 0 0 1-.837.837h-.418a.84.84 0 0 1-.837-.837v-.39a.84.84 0 0 1 .837-.836h.418a.84.84 0 0 1 .837.837v.39Zm0-3.768a.84.84 0 0 1-.837.837h-.418a.84.84 0 0 1-.837-.837V5.355a.84.84 0 0 1 .837-.836h.418a.84.84 0 0 1 .837.836v4.768Z'/%3E%3C/svg%3E");
  }
}
