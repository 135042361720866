body {
  &.using-mouse {
    a,
    button,
    div[tabindex='0'] {
      &:focus {
        box-shadow: unset;
        outline: none;
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      box-shadow: none;
    }
  }

  &:not(.using-mouse) {
    $boxShadow: 0 0 0 2px #fff, 0 0 0 4px rgba(17, 115, 119, 0.42);

    a:not(.btn),
    button:not(.btn) {
      outline: none;

      &:not(.ltr-underline):not(.nav-link) {
        &:focus {
          box-shadow: $boxShadow;
        }
      }

      &.ltr-underline,
      &.nav-link {
        &:focus {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            pointer-events: none;
            left: 0;
            right: 0;
            box-shadow: $boxShadow;
          }
        }
      }

      &.ltr-underline:focus {
        &:before {
          top: 0;
          bottom: 0;
          // bottom: 6px;
        }

        &:after {
          opacity: 0;
        }
      }

      &.nav-link:focus {
        &:before {
          top: 0;
          bottom: 0;

          @media (min-width: 992px) {
            top: 5px;
            bottom: 5px;
            right: 1px;
            left: 1px;
          }
        }

        &:after {
          opacity: 0;
        }
      }
    }

    .utility-nav {
      a:focus:before {
        left: -20px !important;
      }
    }

    #header a:not(.btn).nav-link:focus:before,
    #header a:not(.btn):not(.nav-link):focus,
    #main a:not(.btn):not(.nav-link):focus,
    #main a.ltr-underline:not(.btn):focus,
    #main button:not(.btn):not(.ltr-underline):not(.nav-link):focus {
      box-shadow: 0 0 0 2px #fff;
      outline: 2px solid #8eb4b5;
      outline-offset: 2px;
    }
  }
}

p a:not(.btn):not(.ltr-underline) {
  text-decoration: underline;
  text-decoration-thickness: 0.13em;
  text-decoration-color: rgba(0, 128, 128, 0.28);
  transition: text-decoration-color 0.2s;

  &:hover,
  &:focus {
    transition-duration: 0.15s;
    text-decoration-color: rgba(0, 128, 128, 0.55);
  }

  @at-root body:not(.using-mouse) & {
    &:focus {
      transition: none;
      text-decoration: none;
    }
  }
}
