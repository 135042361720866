.interior-full-width-banner {
  @include media-breakpoint-up(xl) {
    .content {
      margin-left: calc(100% - 33.5rem);
    }
  }
  @include media-breakpoint-up(lg) {
    min-height: 29rem;
    .breadcrumb-wrapper {
      position: absolute;
      top: 1rem;
    }
  }
  @include media-breakpoint-up(md) {
    .breadcrumb-wrapper {
      position: absolute;
      top: 0;
    }
  }
  .row {
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
  }
}
