// Apply a theme color to all descendant links
$text-colors: map-merge($theme-colors, ("white": $white, "body": $body-color, "muted": $text-muted));
@each $color, $value in $text-colors {
  .links-#{$color} {
    a:not(.btn), .btn-link {
      color: $value !important;

      @if $emphasized-link-hover-darken-percentage != 0 {
        &:hover,
        &:focus {
          color: darken($value, $emphasized-link-hover-darken-percentage) !important;
        }
      }
    }
  }
}