#security-center-hero {
  --icon-size: 80px;
  --icon-margin-top: -15px;
  --icon-margin-bottom: 15px;

  background-color: var(--teal-color);
  color: #fff;
  text-align: center;
  font-size: 20px;

  .lock-icon {
    width: var(--icon-size);
    height: var(--icon-size);
    fill: #fff;
    margin: var(--icon-margin-top) 0 var(--icon-margin-bottom) 0;
  }

  h1 {
    color: inherit;
  }

  .text {
    max-width: 800px;
    text-wrap: pretty;
    margin: 20px auto 0 auto;

    + .security-center-search {
      margin-top: 30px;
    }
  }

  .security-center-search {
    margin-top: 40px;
  }

  .inline-search-input-wrapper {
    font-size: 17px;

    &:not(:focus-within) {
      --search-icon-color: #c5dcdc;

      &:hover {
        --search-icon-color: #fff;

        .inline-search-input,
        .inline-search-icon path {
          transition-duration: 0.1s;
        }

        .inline-search-input {
          background-color: #5da7a7;
        }
      }

      .inline-search-input {
        color: #fff;
        background-color: #4f9696;

        @include placeholder {
          color: #c3e4e4;
        }
      }
    }
  }

  .inline-search-input {
    --outline-color: #fff;

    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.06);
    outline-offset: 2px;
    border-radius: 5px;
    outline: none;
    transition: all 0.15s ease-out;
    transition-property: background-color, color;
    border-color: transparent;
  }

  .inline-search-icon {
    path {
      transition: fill 0.15s ease-out;
    }
  }
}

.security-center-search {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  @at-root .section-top & {
    margin-top: 30px;
    max-width: 730px;
  }
}

.sc-blog-listing {
  --content-max-width: 1180px;
  --tile-gap: 20px;
  --tile-max-width: calc(
    (var(--content-max-width) - var(--content-padding) * 2 - var(--tile-gap) * 2) / 3
  );

  .load-more-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--content-padding);
    margin-bottom: 10px;

    .message:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.sc-callout {
  --line-height: 1.53;
  --padding-y: 25px;
  --padding-x: 30px;
  --icon-size: 20px;
  --icon-left: 25px;
  --icon-margin: 16px;

  background-color: var(--bg-color, #fff9eb);
  border-left: 5px solid var(--border-color, #ffc33e);
  border-radius: 4px;
  width: fit-content;
  padding: var(--padding-y) var(--padding-x) var(--padding-y)
    calc(var(--icon-margin) + var(--icon-left) + var(--icon-size));
  margin: 30px 0;
  position: relative;
  line-height: var(--line-height);
  color: var(--text-color, #414040);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0Zm1.046 13.891a.84.84 0 0 1-.837.837h-.418a.84.84 0 0 1-.837-.837v-.39a.84.84 0 0 1 .837-.836h.418a.84.84 0 0 1 .837.837v.39Zm0-3.768a.84.84 0 0 1-.837.837h-.418a.84.84 0 0 1-.837-.837V5.355a.84.84 0 0 1 .837-.836h.418a.84.84 0 0 1 .837.836v4.768Z'/%3E%3C/svg%3E");
    background-color: var(--icon-color, #f3af19);
    width: var(--icon-size);
    height: var(--icon-size);
    position: absolute;
    top: calc(var(--padding-y) + 0.5em * var(--line-height) - var(--icon-size) / 2);
    left: var(--icon-left);
  }

  strong,
  .phone {
    color: var(--bold-color, #383636);
  }

  .phone {
    font-weight: 600;
  }
}

.sc-callout-red {
  --bg-color: #fbf6f6;
  --icon-color: #eb5c5e;
  --border-color: #ff7a7c;
  --text-color: #5a1a1b;
  --bold-color: #431414;

  a:not(.btn):not(.ltr-underline) {
    text-decoration-color: rgba(235, 92, 94, 0.4);

    &:hover {
      text-decoration-color: rgba(235, 92, 94, 0.9);
    }
  }
}

.sc-boxes-wrapper {
  --gap: var(--box-gap, 35px);
  --box-margin: 0px;

  display: flex;
  gap: var(--gap);
  margin: var(--gap) 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.sc-box-item-gray,
.sc-box-item-shadow {
  --margin: var(--box-margin, 35px);
  --box-padding: 40px;

  padding: var(--box-padding);
  margin-top: var(--margin);
  margin-bottom: var(--margin);
  flex-grow: 1;
  width: 100%;
  border-radius: 3px;
  text-wrap: pretty;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-family: var(--font-body);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
  }

  ul,
  ol,
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding: 0;
  }

  li {
    padding-left: 5px;
  }

  a:not(.btn):not(.ltr-underline) {
    text-decoration: underline;
    text-decoration-thickness: 0.13em;
    text-decoration-color: rgba(0, 128, 128, 0.28);
    transition: text-decoration-color 0.2s;

    &:hover,
    &:focus {
      transition-duration: 0.15s;
      text-decoration-color: rgba(0, 128, 128, 0.55);
    }

    @at-root body:not(.using-mouse) & {
      &:focus {
        transition: none;
        text-decoration: none;
      }
    }
  }
}

.sc-box-item-gray {
  background-color: #f9f9f9;
}

.sc-box-item-shadow {
  background-color: #fff;
  box-shadow: 0px 13px 44px 2px rgba(18, 19, 41, 0.04),
    0px 8.426px 25.769px 2px rgba(18, 19, 41, 0.03),
    0px 5.007px 14.015px 2px rgba(18, 19, 41, 0.02),
    0px 2.6px 7.15px 2px rgba(18, 19, 41, 0.02),
    0px 1.059px 3.585px 2px rgba(18, 19, 41, 0.02),
    0px 0.241px 1.731px 2px rgba(18, 19, 41, 0.01);
}

@media (min-width: 992px) {
  #security-center-hero {
    h1 {
      font-size: 56px;
    }
  }
}

@media (max-width: 991px) {
  #security-center-hero {
    --icon-size: 70px;
    --icon-margin-top: 0;

    font-size: 17px;

    .text {
      margin-top: 15px;
      max-width: 700px;
    }

    .inline-search-input-wrapper {
      font-size: 16px;
    }
  }
}

@media (max-width: 900px) {
  .sc-boxes-wrapper {
    --gap: var(--box-gap, 25px);
  }

  .sc-box-item-gray,
  .sc-box-item-shadow {
    --box-padding: 35px;
  }
}

@media (max-width: 780px) {
  .sc-boxes-wrapper {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  #security-center-hero {
    --icon-size: 60px;
    --icon-margin-bottom: 20px;

    text-align: left;

    .security-center-search {
      margin-top: 20px;
    }

    .text + .security-center-search {
      margin-top: 23px;
      margin-bottom: 2px;
    }
  }

  .section-top {
    .security-center-search {
      margin-top: 25px;

      &:last-child {
        margin-bottom: 36px !important;
      }
    }
  }
}

// Additional security center styles
@import 'security-center-grid';
@import 'report-fraud';
