.loan-officers-wrapper {
  max-width: 960px;
  margin: 0 auto;
}

.loan-officer {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid #dcdcdc;

  &:first-child {
    padding-top: 0;
    border-top: none;
  }

  &:last-child {
    padding-bottom: 0;
  }
}



.loan-officer--left-content {
  text-align: left;

  h2 {
    font-size: 30px;
  }

  .officer-title {
    color: #707070;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    margin-top: -2px;
    margin-bottom: 0;

    & > :not(:last-child) {
      &:after {
        content: ",";
      }
    }
  }
}

.loan-officer--right-content {
  display: flex;
  color: #666666;
  font-size: 15px;

  .contact-info {
    margin-right: 30px;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: -1px;

    .email-address {
      display: block;
    }

    .phone-number {
      display: block;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    align-self: center;
  }
}

@media (max-width: 800px) {
  .loan-officers-wrapper {
    margin-top: -3px;
  }

  .loan-officer {
    flex-direction: column;
    padding-top: 25px;
  }

  .loan-officer--left-content {
    h2 {
      font-size: 28px;
    }
  }

  .loan-officer--right-content {
    flex-direction: column;

    .contact-info {
      text-align: left;
      margin-right: 0;
      margin-bottom: 14px;
      padding-top: 8px;

      email-address {
        margin-bottom: 2px;
      }
    }

    .btn {
      display: inline-block;
      width: 140px;
      margin: 0;
      align-self: flex-start;
    }
  }
}
