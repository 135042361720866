.component-content-box-grid {
  .card {
    flex: 0 0 46%;
  }

  .media {
    @include media-breakpoint-down(md) {
      flex-direction: row;
    }

    img {
      max-width: 4.6875rem;
      width: 100%;
    }

    svg {
      width: 4.6875rem;
      height: auto;
      fill: $green;

      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
    }

    a {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
  .ltr-underline {
    @extend %link-underline-base;
    // font-size: 0.875rem;
    font-size: 1em;
    font-weight: 500;

    &:hover {
      color: $green;
    }
  }
}

// Fremont style updates
.component-content-box-grid {
  .card-group {
    width: calc(100% + 30px);
    margin-bottom: -30px;
  }

  .card {
    padding: 45px 40px !important;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    margin-left: 0px !important;
    max-width: calc(50% - 30px);
    flex-basis: 100%;
  }

  .media {
    height: 100%;

    .svg-wrapper {
      margin: -4px 20px 0 2px;
    }
  }

  .media-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 0 !important;

    a:last-child {
      justify-self: flex-end;
      margin-top: auto;
    }
  }

  @include tablet {
    .card-group {
      margin-bottom: 0;
    }

    .card {
      max-width: 100%;
      margin-right: 0 !important;
      padding: 40px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  @include tabletPortrait {
    .media {
      .svg-wrapper {
        margin-left: -0.5rem;

        svg {
          width: 4rem;
        }
      }
    }
  }
}
