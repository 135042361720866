.interior-page-50-50-banner {
  .content {
    @include media-breakpoint-up(xl) {
      margin-left: calc(100% - 33.5rem);
    }
    p {
      @include media-breakpoint-up(md) {
        font-size: 1.115rem;
      }
    }
  }
  .bg-image {
    background-size: cover;
    background-position: 50%;
    position: relative;
    overflow: hidden;
    height: 100%;
    @include media-breakpoint-up(lg) {
      min-height: 29rem;
    }

    img {
      margin: auto;
      object-fit: cover;
      width: 100%;
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .breadcrumb-wrapper {
    @include media-breakpoint-up(lg) {
      position: absolute;
    }
  }
  .cta-content {
    line-height: 1.2;
    .phone {
      font-size: 1.2rem;
    }
  }
}
