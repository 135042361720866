// General Styles
html.disable-scroll {
  scroll-behavior: auto !important;
  height: 100vh;
  overflow: hidden;
}

body {
  min-width: 300px;

  &.initializing {
    *,
    *:before,
    *:after {
      transition: none !important;
      animation: none !important;
    }
  }

  &.disable-scroll {
    width: 100%;
    position: fixed;
    scroll-behavior: auto !important;

    &.is-touch {
      position: static;
    }
  }

  &.complete-animations {
    *,
    *:before,
    *:after {
      transition-duration: 0s !important;
      transition-delay: 0s !important;
      animation-duration: 0s !important;
      animation-delay: 0s !important;
    }
  }
}

.btn-outline-primary {
  color: #008080;
}

// Inner Page Hero
.interior-page-50-50-banner {
  .breadcrumb-wrapper {
    position: static !important;

    .breadcrumb {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;

      li {
        padding-left: 0 !important;

        &:before {
          display: none !important;
        }

        &:not(:last-of-type) {
          padding-right: 0.5rem;

          &:after {
            content: '>';
            display: inline-block;
            padding-left: 0.5rem;
            color: #686868;
          }
        }
      }
    }
  }

  .content {
    flex-direction: column !important;

    h1 {
      &:first-child {
        margin-top: 0 !important;
      }

      + div,
      + p {
        padding-top: 0.7rem !important;
      }
    }

    p {
      font-size: 1.05rem;
    }
  }

  .description {
    color: #505050;
  }

  .cta-content {
    &:empty,
    *:empty {
      display: none !important;
    }

    > div:first-child {
      &.pr-lg-4,
      &.mr-lg-2,
      &.mr-3 {
        padding-right: 0 !important;
        margin-right: 0 !important;

        > *:last-child {
          margin-right: 1.7rem;
        }
      }
    }

    .lead-sm {
      font-weight: 600;
      color: #5b5a5a;
      font-size: 0.77rem;
      display: block;
      margin-bottom: 3px;
    }

    .phone {
      font-size: 1.3rem;
      font-weight: 600;
      display: inline-block;
      color: #4d4d4d;
    }
  }

  @media (min-width: 992px) {
    .bg-image {
      min-height: 430px;
    }
  }

  @media (max-width: 991px) {
    .breadcrumb-wrapper {
      margin-bottom: 12px;
    }

    .content {
      h1 {
        &:not(:last-child) {
          margin-bottom: 3px !important;
        }
      }

      p {
        font-size: 0.9rem;
      }
    }
  }

  @media (max-width: 768px) {
    .breadcrumb-wrapper {
      .breadcrumb {
        li:first-of-type {
          display: flex !important;
        }
      }
    }
  }
}

// Cards
.card {
  box-shadow: $cardShadow;
  transition: all 0.45s;
  transition-property: box-shadow, background-color;
  position: relative;

  &:hover {
    background-color: #fff !important;
    box-shadow: $cardShadowHover !important;
  }

  @at-root {
    .get-started &:not(:hover),
    .bg-white &:not(:hover),
    .section:not(.bg-white):not(.bg-gray-100) & {
      box-shadow: none;
    }
  }

  @at-root {
    .component-client-stories & {
      box-shadow: none !important;
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

// Account for swiper overflow cutting off shadow
.swiper-container {
  padding-bottom: 20px;
}

// Mortgage Rates Bar
.component-mortgage-rates {
  h2 {
    margin-bottom: 0;
  }
}

// Feature bullets
.standard-features {
  > *:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;

    p:last-child {
      margin-bottom: 0 !important;
    }
  }
}

// Dashed lines
.dashed-line,
.dashed-line-before,
.dashed-line-after {
  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-size: 100% 1px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1' class='dashed-line' preserveAspectRatio='none'%3E%3Cline x1='0' y1='0.5' x2='100' y2='0.5' stroke-width='1' stroke-dasharray='6 6' vector-effect='non-scaling-stroke' stroke='%23d4d3d3' /%3E%3C/svg%3E");
  }
}

.dashed-line-before {
  &:after {
    display: none;
  }
}

.dashed-line-after {
  &:before {
    display: none;
  }
}
