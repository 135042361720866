@use 'sass:math';

.full-width-50-50-feature {
  > div:first-child > .text-center:first-child {
    margin-top: 0 !important;

    p {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .card-group {
    max-width: 1080px;
    margin: 60px auto 0 auto;

    + .footnote,
    + .disclaimer {
      margin-top: 45px;
      text-align: center;
    }
  }

  .card {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;

    @mixin positionContent($spacing, $offset: 0px) {
      &:first-child {
        > p,
        > h3 {
          padding-right: math.div($spacing, 2) + $offset !important;
        }
      }

      &:last-child {
        > p,
        > h3,
        .dynamic-rate-feed {
          padding-left: math.div($spacing, 2) - $offset;

          + .btn {
            margin-left: math.div($spacing, 2) - $offset;
          }
        }

        > p,
        > h3 {
          max-width: calc(100% - #{$offset * 2});
        }
      }
    }

    @media (min-width: 1251px) {
      $spacing: 90px;
      $offset: 0;

      @include positionContent(90px);
    }

    @media (max-width: 1250px) and (min-width: 851px) {
      &:first-child {
        @include positionContent(40px, 20px);
      }
    }

    > p {
      flex-grow: 1;
    }
  }

  .dynamic-rate-feed {
    margin-top: auto;
    align-items: flex-end;
    border-top: 3px solid #f7f7f7;
    padding-top: 1.5rem;
    margin-top: 0.5rem !important;
  }

  .rate-item {
    border-right: 2px solid #f1f1f1;
    margin-right: 2rem !important;
    padding-right: 2rem;

    &:last-child {
      margin-right: 0 !important;
      padding-right: 0;
      border-right: none;
    }
  }

  &.bg-gray-100 {
    .dynamic-rate-feed,
    .rate-item {
      border-color: #e2e2e2;
    }
  }

  .rate-type {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem !important;
    color: #747474;
    line-height: 1;
    margin-bottom: 6px;
    letter-spacing: 0.03em !important;
    white-space: nowrap;
    text-align: left;
  }

  .rate-data {
    font-size: 3rem;
  }

  .rate {
    font-weight: 600;
    font-size: inherit !important;
    letter-spacing: -0.03em;
    color: #373737;
  }

  .rate-right {
    display: flex;
    flex-direction: column;
    font-size: 0.4em;
    font-weight: 600;
    color: #717171;
    margin-left: 2px;

    > * {
      font-size: inherit !important;
    }

    sup {
      margin-left: 1px;
      font-size: 0.7em;
    }
  }

  .footnote,
  .disclaimer {
    font-size: 0.85rem;
    color: #6d6b6b;

    sup {
      font-size: 0.68em;
      margin-left: 0.05em;
      top: -0.4em;
    }
  }

  @media (max-width: 1045px) {
    .rate-item {
      margin-right: 1.5rem !important;
      padding-right: 1.3rem;
    }

    .rate-type {
      font-size: 0.85rem !important;
    }

    .rate-data {
      font-size: 2.6rem;
    }

    .rate-right {
      margin-left: 1px;
    }
  }

  @media (max-width: 991px) {
    .disclaimer {
      padding-bottom: 30px;
    }
  }

  @media (max-width: 850px) {
    .card-group {
      flex-direction: column !important;
      flex-wrap: wrap;
    }

    .card {
      margin-top: 0 !important;

      h3 {
        margin-bottom: 0.3em !important;
      }

      p {
        max-width: 660px;
      }

      &:not(:last-child) {
        margin-bottom: 35px !important;
      }
    }

    .dynamic-rate-feed {
      order: 100;
      margin-bottom: 0 !important;
    }

    .rate-data {
      font-size: 2.4rem;
    }

    .card .btn {
      align-self: flex-end !important;
      margin-bottom: -37px;
      position: relative;
      top: 42px;
    }
  }

  @media (max-width: 630px) {
    .rate-item {
      margin-right: 0 !important;
      padding-right: 25px;
      border-right: none;
    }

    .rate-type {
      font-size: 0.8rem !important;
    }

    .rate-data {
      font-size: 2.3rem;
    }
  }

  @media (max-width: 570px) {
    .card-group {
      + .footnote,
      + .disclaimer {
        margin-top: 25px;
        text-align: left;
      }
    }

    .disclaimer,
    .footnote {
      align-self: flex-start;
      text-align: left;
      width: 100%;
    }

    .dynamic-rate-feed {
      order: initial;
      flex-wrap: wrap;
      margin-bottom: 0 !important;
    }

    .rate-item {
      margin-bottom: 15px;
    }

    .card .btn {
      margin-bottom: 0;
      top: 0;
      align-self: flex-start !important;
    }
  }

  @include mobile {
    .rate-item {
      padding-right: 20px;
    }

    .rate-data {
      font-size: 2.1rem;
    }
  }
}
