@use 'sass:math';

$spacing: 26px;

@mixin count($num) {
  .tile:first-child:nth-last-child(#{$num}),
  .tile:first-child:nth-last-child(#{$num}) ~ .tile {
    @content;
  }
}

.tile-wrapper {
  display: flex;
  justify-content: center;
  $width: 1400px;
  width: $width;
  position: relative;
  left: 50%;
  margin-left: math.div(-$width, 2);
  margin-bottom: -$spacing;

  @media (max-width: 1440px) {
    width: calc(100% + #{$spacing});
    margin-left: math.round(-(math.div($spacing, 2)));
    left: 0;
  }
}

.tile {
  background-color: #f9f9f9;
  max-width: calc(33.333% - #{$spacing});
  width: 330px;
  margin: 0 math.div($spacing, 2) $spacing math.div($spacing, 2);
  text-decoration: none !important;
  transition: all 0.45s;
  transition-property: box-shadow, background-color;
  display: block;

  @at-root .bg-gray-100 & {
    background-color: #fff;
    box-shadow: $cardShadow;
  }

  &:hover {
    background-color: #fff !important;
    box-shadow: $cardShadowHover !important;
  }

  *:last-child {
    margin-bottom: 0;
  }

  @at-root {
    .get-started &:not(:hover),
    .bg-white &:not(:hover),
    .section:not(.bg-white):not(.bg-gray-100) & {
      box-shadow: none;
    }
  }
}

.tile-grid-section {
  --content-max-width: calc(1400px + var(--content-padding) * 2);
}

.tile-grid {
  --gap: var(--tile-gap, 25px);

  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(230px, var(--tile-max-width, 325px)));
  gap: var(--gap, 25px);
  justify-content: center;

  .tile {
    margin: 0 !important;
    width: auto !important;
    max-width: none !important;
  }
}

.tile-content {
  padding: 35px;
  display: flex;
  flex-direction: column;
}

.tile-image {
  height: 170px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
    transition: transform 0.45s cubic-bezier(0.25, 0.92, 0.35, 1);
  }
}

.tile-top {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.1;

  &:last-child {
    margin-bottom: 0;
  }

  @at-root a.tile &:hover {
    text-decoration: underline;
    color: #858585;
  }

  * {
    font-size: inherit;
    line-height: inherit;
  }

  h3 {
    margin-top: -0.2em;
  }
}

.tile-text {
  color: $bodyColor;
  flex-grow: 1;

  p:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1440px) {
  .tile {
    width: 280px;
  }
}

@media (max-width: 1360px) {
  .tile-content {
    padding: 30px;
  }

  @include count(1) {
    width: 340px;
  }

  @include count(2) {
    width: 330px;
  }

  @include count(3) {
    width: 310px;
  }
}

@media (max-width: 1270px) {
  .tile {
    width: 260px;
  }
}

@media (max-width: 1240px) {
  $spacing: 18px;

  .tile {
    margin: 0 math.div($spacing, 2) $spacing math.div($spacing, 2);
  }

  .tile-top {
    font-size: 21px;
  }

  .tile-wrapper {
    width: calc(100% + #{$spacing});
    margin-left: -(math.div($spacing, 2));
  }
}

@media (min-width: 1031px) {
  .tile-wrapper {
    &:not([data-count='1']):not([data-count='2']):not([data-count='3']):not([data-count='4']) {
      flex-wrap: wrap;
    }

    &:not([data-count='1']):not([data-count='2']):not([data-count='3']) {
      .tile {
        max-width: calc(25% - #{$spacing});
        width: 330px;
      }
    }
  }
}

@media (max-width: 1030px) {
  $spacing: 26px;

  .tile-wrapper {
    flex-wrap: wrap;
    margin-bottom: -$spacing;
    width: calc(100% + #{$spacing});
    margin-left: math.div(-$spacing, 2);
  }

  .tile {
    width: 100%;
    max-width: 330px;
    margin: 0 math.div($spacing, 2) $spacing math.div($spacing, 2);
  }
}

@media (max-width: 1030px) and (min-width: 700px) {
  @include count(3) {
    width: calc(50% - 26px);
    max-width: 100%;
    margin-right: auto;
  }
}

@media (max-width: 1020px) {
  .tile-top {
    font-size: 21px;
  }
}

@media (max-width: 700px) {
  .tile-grid {
    grid-template-columns: 1fr;
  }

  .tile {
    width: 100% !important;
    max-width: 100% !important;
  }
}

// @include tabletPortrait {
//   .section-content > .tile-wrapper:first-child {
//     margin-top: -15px !important;
//   }
// }

@include mobile {
  .tile-wrapper {
    width: 100%;
    margin: 0 !important;
    display: block;
  }

  .tile-grid {
    gap: var(--content-padding);
  }

  .tile {
    margin: 0 0 $contentPaddingMobile 0;
    width: 100%;
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
