.sample-loan {
  font-size: 0.9rem;
  color: #6f6f6f;

  .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding: 10px 0;
    line-height: 1.35;
    margin: 0 !important;
    flex-wrap: nowrap !important;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .label,
  strong {
    font-weight: 600;
    color: #494949;
  }

  .label {
    padding-right: 10px;
  }

  .value {
    text-align: right;

    @media (min-width: 601px) {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .adjustable-payments-wrapper {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #dcdcdc;
  }

  .adjustable-payments {
    margin-top: 5px;

    > div {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    strong {
      margin-right: 5px;
    }
  }
}

.sample-loan-footnote {
  font-size: 0.8em;
  margin-top: 30px;
  color: #585858;

  p {
    color: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
