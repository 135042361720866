@mixin menuIsOpen {
  @at-root body.mobile-menu-open & {
    @content;
  }
}

@mixin menuIsClosed {
  @at-root body:not(.mobile-menu-open) & {
    @content;
  }
}

.homepage.mobile-menu-open #header {
  background-color: #fff !important;
}

// Menu toggle button
.mobile-menu-toggle {
  @include disableNativeButton;
  display: inline-flex;
  position: relative;
  top: 0;
  right: 0;
  z-index: 999999;
  width: $menuHeight;
  height: $menuHeight;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, opacity 0.3s, transform 0.5s;
  margin: 0;
  padding: 2px 0 0 0;

  &:hover {
    opacity: 0.8;
  }

  > span {
    display: block;
    position: relative;
    height: 3px;
    width: 30px;

    span,
    &:before,
    &:after {
      background-color: #494949;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    span {
      transition: opacity 0.5s, background-color 0.3s;
    }

    &:before,
    &:after {
      content: '';
      transition: transform 0.3s, background-color 0.3s;
    }

    &:before {
      transform: scaleX(1.35) translate(-4px, -8px);
    }

    &:after {
      transform: scaleX(0.65) translate(8px, 8px);
    }
  }

  @include menuIsOpen {
    transform: rotate(270deg);
    background-color: transparent !important;

    > span {
      span {
        opacity: 0;
      }

      &:before {
        transform: scaleX(1) translate(0, -4px) rotate(45deg);
      }

      &:after {
        transform: scaleX(1) translate(0, -4px) rotate(-45deg);
      }
    }
  }
}

#mobile-nav-overlay,
.mobile-utility-login {
  display: none;
}

$promoHeight: 220px;

@include desktop {
  .mobile-menu-toggle {
    display: none;
  }

  .main-navigation {
    .logo {
      transform: translateY(0) !important;
    }
  }
}

// Mobile menu layout
@include tablet {
  .navbar-wrapper {
    padding-left: 0 !important;

    .logo {
      padding-left: 25px;

      @at-root body:not(.homepage) & {
        background-color: #fff;
      }

      @at-root body.homepage.mobile-menu-open:not([data-scroll='0']) & {
        background-color: #fff;
      }

      &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }

      &.no-transition {
        transition: none !important;
      }
    }
  }

  #main-nav-wrapper {
    background: #fff;
    position: fixed;
    top: 0;
    padding-top: $menuHeight;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    pointer-events: none;
    transform: translateY(100%);
    transition: transform 0.45s cubic-bezier(0.3, 0.77, 0.51, 0.99);
    visibility: hidden;

    @include menuIsOpen {
      transform: translateY(0);
      transition-duration: 0.55s;
      transition-timing-function: cubic-bezier(0.15, 0.76, 0.18, 1);
      pointer-events: auto;
      visibility: visible;
    }

    &.animating {
      visibility: visible !important;
    }

    .mobile-search-modal {
      padding: 0 !important;

      .search-trigger {
        border-bottom: none;
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        width: 100%;
        padding: 11px 12px 14px 25px !important;
        text-transform: none;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-right: 15px !important;
        top: 3px;
        position: relative;
      }
    }

    .main-nav,
    .bg-gray-200,
    .nav-dropdown-items {
      background-color: #f7f7f7 !important;
    }
  }

  #mobile-utility-nav {
    background-color: #f7f7f7 !important;

    .navbar-nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 15px;
    }

    .nav-item {
      a,
      > span {
        padding: 22px 12px;
        display: inline-flex;
        align-items: center;
        font-size: 17px;
        color: inherit;

        &:after {
          display: none !important;
        }

        svg,
        path {
          fill: currentColor;
        }

        svg {
          width: 1em;
          height: 1em;
          margin-top: 0;
          margin-right: 5px;
        }
      }
    }
  }

  .mobile-login-modal {
    margin-right: 9px;
    padding-right: 29px;
    border-right: 1px solid #e1e1e1;
  }

  .navbar-wrapper {
    padding-right: 0;

    .logo {
      overflow: hidden;
    }
  }

  .main-navigation {
    position: relative;

    .logo {
      transition: transform 0.5s cubic-bezier(0.24, 0.83, 0.45, 1);
      position: relative;
      z-index: 990;

      @include menuIsClosed {
        transform: translateY(0) !important;
      }
    }
  }

  .main-nav {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    flex-grow: 1;
    height: 100%;
  }

  #mobile-nav-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    display: block;
    overflow-y: scroll;
    opacity: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.4s;
      background-color: #fff;
      opacity: 0;
    }

    @include menuIsOpen {
      pointer-events: auto;
      opacity: 1;

      &:before {
        opacity: 1;
      }
    }
  }

  .smartbanner {
    @include menuIsOpen {
      display: none !important;
    }
  }
}

#mobile-promo-wrapper {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  height: $promoHeight;
  z-index: 9991;

  // Temporarily hide until we have a new design
  display: none !important;
}

.mobile-promo,
.mobile-promo img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.mobile-promo {
  img {
    object-fit: cover;
    max-width: none;
    width: 100%;
    height: 100%;
  }
}

.mobile-promo-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  padding: 25px 30px;
  background-color: rgba(#000, 0.6);
  backdrop-filter: blur(7px);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  * {
    color: #fff !important;
  }

  h2 {
    font-size: 26px;
    letter-spacing: 0.02em !important;
    line-height: 1.1;
  }

  p,
  a.ltr-underline {
    font-size: inherit !important;
    color: #fff !important;

    &:hover {
      color: #fff !important;
    }
  }
}

@media (max-width: #{$mobileMenuMaxWidth}) {
  .mobile-menu-toggle {
    width: $menuHeightMobile - 4px;
    height: $menuHeightMobile;
  }

  #main-nav-wrapper {
    padding-top: $menuHeightMobile;
  }

  .mobile-login-modal {
    margin-right: 8px;
    padding-right: 18px;
  }
}

@media (max-width: 450px) {
  .mobile-login-modal {
    display: none;
  }

  .mobile-utility-login {
    display: inline-flex;
  }

  #mobile-utility-nav {
    .navbar-nav {
      justify-content: flex-start;

      .logo {
        padding-left: 20px;
      }
    }

    li {
      margin: 0;

      a,
      .nav-item > span {
        padding: 20px 8px !important;
      }
    }

    .nav-item a,
    .nav-item > span,
    .mobile-login {
      display: inline-flex;
      border: none;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;

      svg {
        margin-right: 8px;
      }
    }

    .mobile-utility-login {
      margin-left: auto;
    }

    .mobile-login {
      @include disableNativeButton;
      color: $tealColor;

      svg {
        width: 16px;
        height: 16px;
      }

      path {
        fill: currentColor;
      }
    }
  }
}

@media (max-width: 400px) {
  .mobile-menu-toggle {
    left: 6px;

    > span {
      width: 23px;

      &:before {
        transform: scaleX(1.35) translate(-3px, -8px);
      }

      &:after {
        transform: scaleX(0.65) translate(6px, 8px);
      }
    }
  }

  .mobile-promo-content {
    text-align: left;
    align-items: flex-start;
  }
}

@media (max-width: 370px) {
  #mobile-utility-nav {
    .navbar-nav {
      padding: 0 10px;
    }

    li a,
    .nav-item > span {
      padding-left: 6px;
      padding-right: 6px;
    }

    .nav-item a,
    .nav-item > span,
    .mobile-login {
      svg {
        margin-right: 4px;
      }
    }
  }
}

@media (max-width: 355px) {
  .header-logo {
    width: 220px !important;
  }

  #mobile-utility-nav {
    .navbar-nav {
      flex-wrap: wrap;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    li {
      &:nth-child(1) {
        width: calc(100% - 80px);
      }

      &:nth-child(2) {
        order: 3;
      }

      a,
      > span {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
      }
    }

    .nav-item a,
    .mobile-login {
      svg {
        margin-right: 8px;
      }
    }

    .mobile-utility-login {
      order: 2;
    }
  }
}

// Mobile nav item lists
@include tablet {
  .main-nav {
    .navbar-nav > .nav-item:not(:first-child) {
      border-top: 2px solid #dedede;
      padding-top: 2px;
    }

    .navbar-nav {
      padding-top: 5px !important;
    }

    .nav-link {
      font-size: 20px !important;
      padding-bottom: 0;
      display: block;
      width: 100%;

      + .icon-toggle {
        margin-right: -15px;
      }
    }

    .icon-toggle {
      position: absolute !important;
    }

    .header-nav {
      a {
        font-weight: 600 !important;
      }
    }

    li a br {
      display: none !important;
    }

    .dropdown-menu,
    .level2 > li[data-expanded='true'] ul {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 40px;
        bottom: 0;
        width: 3px;
        background-color: #cacaca;
        z-index: 9991;
        pointer-events: none;
      }
    }

    .level2 > li[data-expanded='true'] ul:before {
      top: -2px;
      left: -23px;
      bottom: -1px;
      background-color: #e0dede;
    }

    $borderColor: #dedede;

    .dropdown-menu {
      transition: none !important;

      .col:first-child {
        > ul > li {
          border-top: 1px solid $borderColor;
        }
      }

      .col:last-child {
        li:last-child {
          border-bottom: none !important;
          margin-bottom: 10px;
        }
      }

      &:before {
        top: 50px;
        bottom: 25px;
      }

      .ml-4 {
        margin-left: 25px !important;
      }

      .header-nav {
        margin-bottom: 20px;

        > li {
          > a {
            font-weight: 600 !important;
            border-bottom: none !important;
            font-size: 16px;
          }

          > ul > li:first-child {
            border-top: 1px solid $borderColor;
          }
        }

        a {
          font-weight: 500 !important;
          padding: 12px 0;
          border-bottom: 1px solid $borderColor;
          display: block;
          font-size: 15px;
        }
      }
    }

    .level2 {
      > li:not([data-expanded='true']) {
        border-bottom: 1px solid $borderColor;
        margin-bottom: -19px;
      }
    }

    .dropdown {
      &:not([data-expanded='true']) {
        .dropdown-menu {
          display: none !important;
        }
      }

      .icon-toggle {
        font-size: 16px;
        position: relative;
        width: 60px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 3px !important;
        right: -7px !important;

        span {
          font-size: inherit;
        }
      }

      .level2 {
        .icon-toggle {
          top: 0px !important;
          height: 49px;
        }
      }
    }
  }
}

@include mobile {
  .main-nav {
    padding: 8px !important;

    .nav-link {
      font-size: 17px !important;
    }
  }
}
