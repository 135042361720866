.component-find-a-branch-contact-us {
  .content-title {
    svg {
      font-size: 1.4rem;
      margin-right: 0.28em;
      margin-top: -0.08em;
    }
  }

  h2 {
    font-family: $headingFont;
    font-size: 2.275rem;
    font-weight: 500;

    @include media-breakpoint-down(md) {
      font-size: 1.925rem;
      font-weight: 500;
    }
  }

  $inputSize: 43px;

  .find-local-branch {
    background-size: cover;
    z-index: 1;

    .footer-sign-up-form {
      min-height: $inputSize;
      position: relative;
    }

    input {
      border: none;
      padding: 10px 17px 11px 17px;
      font-weight: 500;
      width: 100% !important;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(22, 77, 77, 0.6);
      }

      @include media-breakpoint-up(lg) {
        min-width: 15rem;
      }
    }

    input,
    button {
      height: $inputSize;
    }

    button {
      background-color: $tealColor !important;
      border-color: $tealColor !important;

      &:hover {
        background-color: #005a5a !important;
        border-color: #005a5a !important;
      }

      @include keyboardFocus {
        z-index: 90;
        position: relative;
        box-shadow: 0 0 0 0.2rem rgba(22, 77, 77, 0.88);
      }
    }

    & > div {
      @include media-breakpoint-up(lg) {
        padding-left: calc(50% - 650px) !important;
      }
    }
  }

  .contact-us {
    background-color: $cyan;
    color: $white;

    a {
      color: $white;
    }

    .client-contact {
      font-size: 1.5rem;
      font-weight: 500;
    }

    .btn-info {
      border-color: $white;
    }

    a.btn-info {
      padding-right: 40px;
      position: relative;

      &:hover {
        &:after {
          border-left: 2px solid #ffd465;
        }
      }

      &:after {
        content: '>';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0px;
        transition: border-left 0.15s ease-in-out;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        border-left: 2px solid white;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.component-find-a-branch-contact-us {
  .newsletter-subtitle {
    padding-top: 4px;
    padding-bottom: 16px;
    font-size: 14px;
  }

  .find-local-branch {
    > .content {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      z-index: 10;
    }

    @media (max-width: 500px) {
      padding: 0 !important;

      > .content {
        align-items: flex-start;
        padding: 3rem;
        margin: 0 !important;
        text-align: left;
        width: 100% !important;
      }

      .newsletter-subtitle {
        max-width: 280px;
        padding-top: 8px;
      }

      .footer-sign-up-form {
        width: 100% !important;
      }
    }
  }

  .signup-submit-message {
    background-color: rgba(62, 79, 92, 0.73);
    color: white;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    font-size: 16px;
  }
}

.safari {
  .component-find-a-branch-contact-us {
    .content-title {
      svg {
        align-self: baseline;
        margin-top: 2px;
      }
    }
  }
}
