#footer {
  background-color: #1c797b;
  overflow-x: hidden;

  a {
    color: $white;
  }

  .footer-svg {
    max-width: 170px;
    width: 100%;
    height: 40px;
  }

  .to-top {
    position: absolute;
    right: 1rem;
    top: -3rem;
    color: $green;
    background-color: $white;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1;

    .up-arrow {
      font-size: 1.5rem;
      display: inline-block;
      transform: rotate(-90deg);

      &:after {
        content: '➜';
      }
    }
  }

  .section-heading {
    font-size: 0.875rem;
  }

  .footer-heading-links {
    @include media-breakpoint-down(md) {
      display: block !important;
      column-count: 2;
      column-fill: balance;
    }
  }

  .footer-nav-links {
    font-size: 0.75rem;
    font-weight: 400;

    li {
      @include media-breakpoint-up(lg) {
        margin: 0.375rem 0;
      }

      img,
      svg {
        vertical-align: text-top;
        width: 15px;
      }
    }
  }

  .mobile-socials-apps {
    svg {
      width: 176px;
      height: 60px;

      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
    }

    li:first-of-type {
      margin-right: 0.5rem;
    }

    a:hover svg {
      color: $white;
    }
  }

  .contact-us {
    a {
      font-size: 0.875rem;
    }
  }

  .company-links {
    font-weight: 400;

    li {
      &:after {
        content: '|';
        color: $white;
        padding: 0 0.35rem 0 0.25rem;
      }

      &:last-of-type:after {
        display: none;
      }
    }
  }
}
