.ltr-underline,
.link-underline-left,
.nav-item > a,
.utility-nav .nav-item > a {
  display: inline-block;
  padding: 0;

  &:after {
    content: '';
    display: block !important;
    width: 100%;
    height: 6px;
    background-image: linear-gradient(to bottom, #008080 100%, transparent 100%);
    background-size: 0% 2px;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    transition: background-size 0.3s !important;
    border-bottom: none !important;
    transform: scaleX(1) !important;
    position: relative;
    padding-bottom: 0 !important;
    z-index: 300;
    pointer-events: none;

    @at-root .text-white & {
      background-image: linear-gradient(to bottom, currentColor 100%, transparent 100%);
    }
  }

  &:hover,
  &:focus-within {
    &:after {
      background-size: 102% 2px;
      background-position-x: left;
      background-position-y: bottom;
    }
  }
}

button {
  &.ltr-underline,
  &.link-underline-left {
    @include disableNativeButton;
  }
}

.content-btn {
  &.ltr-underline,
  &.link-underline-left {
    color: $tealColor;
    display: inline-flex !important;
    align-items: center;
    flex: none;

    @at-root {
      .text-white &,
      &.text-white {
        color: #fff;
      }
    }
  }

  &.ltr-underline {
    .link-arrow,
    span {
      margin-left: 0.42em;
    }
  }

  &.link-underline-left {
    flex-direction: row-reverse;

    .link-arrow,
    span {
      margin: -0.5em 0.42em -0.4em 0;
    }
  }
}

.link-underline-left {
  &:after {
    background-position-x: left;
  }

  &:hover {
    &:after {
      background-position-x: right;
    }
  }
}

main,
#main-nav-wrapper .promo-area {
  a,
  button {
    &.ltr-underline,
    &.link-underline-left {
      display: inline-flex;
      align-items: center;
      position: relative;
      font-weight: 600 !important;
      font-size: 1em !important;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: -3px;
        bottom: -4px !important;
        height: 2px;
      }

      svg {
        color: inherit;
      }

      .link-arrow,
      span {
        margin: -0.6em 0.35em -0.4em 0.15em;
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
      }

      .link-arrow,
      span:after {
        width: 1.1em;
        height: 1.1em;
        transition: transform 0.3s ease;
        position: relative;
        z-index: 200;
        will-change: transform;
        transform: translate3d(0, 0, 0);
        top: 0.02em;
        display: inline-block;
      }

      span {
        display: inline-block;
        transform: none !important;

        &:after {
          content: '' !important;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cpath d='M8.918 12l.965 1.188L14.5 7.5 9.883 1.81l-.965 1.19 2.964 3.65H.5v1.697h11.382L8.918 12z' fill='%23008080'/%3E%3C/svg%3E");
          background-size: cover;
        }
      }

      &:hover {
        .link-arrow,
        span:after {
          transform: translate3d(0.3em, 0, 0);
        }
      }
    }

    &.white,
    &.text-white {
      color: #fff !important;

      span:after {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.03 10.153L12.402 6.97l1.307-1.079L17.925 11l-4.216 5.11-1.307-1.079 2.626-3.183H4.075v-1.695H15.03z' fill='%23ffffff'/%3E%3C/svg%3E");
      }
    }

    &.dark,
    &.text-dark {
      color: #353535 !important;

      &:after {
        background-image: linear-gradient(to bottom, #353535 100%, transparent 100%);
      }

      span:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cpath d='M8.918 12l.965 1.188L14.5 7.5 9.883 1.81l-.965 1.19 2.964 3.65H.5v1.697h11.382L8.918 12z' fill='%23353535'/%3E%3C/svg%3E");
      }
    }

    &.link-underline-left {
      span {
        transform: none !important;
      }

      span:after,
      .link-arrow {
        transform: rotate(180deg);
        margin-top: 0.5em;
        margin-right: 0.2em;
      }

      &:hover {
        span:after,
        .link-arrow {
          transform: rotate(180deg) translate3d(0.25em, 0, 0) !important;
        }
      }
    }
  }

  .text-white {
    a {
      &.ltr-underline,
      &.link-underline-left {
        color: #fff !important;

        span:after {
          background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.03 10.153L12.402 6.97l1.307-1.079L17.925 11l-4.216 5.11-1.307-1.079 2.626-3.183H4.075v-1.695H15.03z' fill='%23ffffff'/%3E%3C/svg%3E");
        }
      }
    }
  }

  .breadcrumb-wrapper {
    .breadcrumb-item {
      a.ltr-underline {
        font-weight: 500 !important;

        &:after {
          top: calc(1em + 6px);
          bottom: auto;
        }
      }
    }
  }
}

.content-box-row {
  .card {
    .card-footer {
      font-size: 1em;
    }
  }
}

#main-nav-wrapper .promo-area {
  a.ltr-underline {
    margin-top: 10px;

    span {
      top: 0.3em;
      margin-left: 0.5em;
    }
  }
}
