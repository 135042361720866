.quick-links-bar {
  @include tickerTape;

  .container {
    max-width: none;
    padding-left: 25px;
    padding-right: 25px;
  }

  .icon-links-list {
    justify-content: flex-start !important;

    li {
      min-width: 6rem;
      margin-bottom: 0;
      font-size: 0.9rem;

      &:last-child a {
        padding-right: 0 !important;
      }

      a {
        align-items: center !important;
        line-height: 1.2;
      }

      .svg-wrapper {
        margin-right: 0.6rem;
      }
    }
  }

  @include desktop {
    padding-top: 3px !important;
    padding-bottom: 3px !important;

    h2,
    .h2 {
      font-size: 1.9rem;
    }

    .container {
      display: flex;
      justify-content: center;
    }

    .icon-links-list {
      align-items: center;

      li {
        padding: 0 !important;

        a {
          padding: 1.5rem !important;
        }
      }
    }
  }

  @include tablet {
    padding-top: 20px;
    padding-bottom: 5px;

    h2,
    .h2 {
      margin-bottom: 5px !important;
    }

    + .section {
      .section-top {
        padding-top: 5px;
      }
    }
  }

  @include mobile {
    + .section {
      .section-top {
        padding-top: 0;
      }
    }
  }
}
