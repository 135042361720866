:root {
  // Colors
  --body-text-color: #{$bodyColor};
  --teal-color: #{$tealColor};
  --gold-color: #{$goldColor};

  // Fonts
  --font-heading: #{$headingFont};
  --font-body: #{$bodyFont};

  // Layout
  --content-width: #{$contentMaxWidth};
  --section-padding: #{$sectionPaddingDesktop};
  --content-padding: #{$contentPaddingDesktop};
  --header-height: 79px;

  @media (max-width: #{$contentMaxWidth}) {
    --section-padding: #{$contentPaddingDesktop + 10px};
  }

  @media (max-width: 1180px) {
    --header-height: 110px;
  }

  @media (max-width: #{$tabletMaxWidth}) {
    --section-padding: #{$sectionPaddingTablet};
    --content-padding: #{$contentPaddingTablet};
    --header-height: 79px;
  }

  @media (max-width: #{$tabletPortraitMaxWidth}) {
    --section-padding: #{$sectionPaddingTabletPortrait};
    --content-padding: #{$contentPaddingTabletPortrait};
  }

  @media (max-width: #{$mobileMenuMaxWidth}) {
    --header-height: 72px;
  }

  @media (max-width: #{$mobileMaxWidth}) {
    --section-padding: #{$sectionPaddingMobile};
    --content-padding: #{$contentPaddingMobile};
  }
}
