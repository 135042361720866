.search-branch {
  --offset: calc(var(--result-padding) * -1);
  --offset-top: calc(var(--result-title-margin) * -1);

  width: calc(100% + var(--result-padding) * 2);
  margin: var(--offset-top) 0 var(--offset) var(--offset);
  display: flex;
}

.search-branch-thumb {
  width: 100%;
  max-width: 440px;
  position: relative;
  margin: -1px -1px -1px 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    object-fit: cover;
    object-position: center center;
  }
}

.search-branch-content {
  flex-grow: 1;
  margin: var(--result-title-margin) var(--result-padding) var(--result-padding)
    var(--result-padding);
  overflow: hidden;
}

.search-branch-address {
  flex-grow: 1;
  font-size: 1.1rem;
  color: #6b6b6b;
  line-height: 1.1;
  margin-bottom: 0;
  text-wrap: pretty;
  line-height: 1.2;

  .line1 {
    font-size: 1.32em;
    color: #484848;
    display: block;
    margin-bottom: 7px;
  }
}

.search-branch-info {
  --item-mx: 15px;
  --item-my: 7px;

  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 24px calc((var(--item-mx) + 4px) * -1) 23px calc((var(--item-mx) + 6px) * -1);
  padding: 15px 20px !important;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: calc(var(--item-mx) + 4px);
    bottom: var(--item-my);
    left: calc(var(--item-mx) + 6px);
    border-radius: 3px;
    background-color: #fbfcfd;
    background-image: repeating-linear-gradient(
        90deg,
        #cacaca 0,
        #cacaca 50%,
        transparent 0,
        transparent
      ),
      repeating-linear-gradient(90deg, #cacaca 0, #cacaca 50%, transparent 0, transparent),
      repeating-linear-gradient(
        180deg,
        #cacaca 0,
        #cacaca 50%,
        transparent 0,
        transparent
      ),
      repeating-linear-gradient(
        180deg,
        #cacaca 0,
        #cacaca 50%,
        transparent 0,
        transparent
      );
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 8px 1px, 8px 1px, 1px 8px, 1px 8px;
    pointer-events: none;
  }

  li {
    display: flex;
    flex-wrap: wrap;
    padding-left: var(--item-mx);
    padding-right: calc(var(--item-mx) + 4px);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 2px;
      top: 4px;
      left: -1px;
      bottom: calc(var(--item-my) + 4px);
      background-color: #cbcacf;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .item {
    display: flex;
    align-items: center;
    color: #008080;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.98rem;
    margin-bottom: var(--item-my);

    a,
    span {
      font-size: inherit;
    }

    svg {
      margin-right: 7px;
      margin-left: 2px;
      width: 1em;
      height: 1em;
      flex: none;
    }
  }

  .details {
    color: #666667;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    position: relative;
    top: -1px;
    padding-left: 4px;
    line-height: 1;
    margin-bottom: var(--item-my);
  }
}

.search-branch-phone {
  margin-left: 1px;
}

.search-branch-open-status {
  margin-right: 7px;
}

.search-branch-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;

  a {
    margin-right: 10px;
    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 1030px) {
  .search-branch-buttons {
    flex-direction: column;
    margin-bottom: 0;

    a {
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 950px) {
  .search-branch-thumb {
    max-width: 390px;
  }
}

@media (max-width: 890px) {
  .search-branch-address {
    font-size: 0.95rem;
    padding-top: 2px;
    margin-bottom: 0;

    .line1 {
      margin-bottom: 8px;
    }
  }

  .search-branch-thumb {
    max-width: 350px;
  }
}

@media (max-width: 800px) {
  .search-branch {
    display: block;
    margin-top: 0;
  }

  .search-branch-thumb {
    max-width: none;
    height: 290px;
    width: 100%;
    display: block;
    margin-top: 0;
    padding: 0 15px 15px 15px;

    img {
      width: 100%;
      height: 100%;
      position: static;
      border-radius: 3px;
    }
  }
}

@media (max-width: 590px) {
  .search-branch-thumb {
    height: 250px;
    padding: 0 10px 10px 10px;
  }
}
