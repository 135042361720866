.blog-search {
  .search-term {
    input {
      font-size: 1.5rem;
      background-color: $gray-100;
      color: $gray-600;
      border: 0;
      border: 2px solid $gray-400;

      &::placeholder {
        font-size: 1rem;
        color: $gray-600;
      }
    }
  }
}

.blog-list-grid {
  .card {
    flex: 0 0 22.25%;

    @include media-breakpoint-up(sm) {
      flex: 0 0 45%;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 45%;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 0 22.25%;
    }
  }

  .load-more {
    @include media-breakpoint-up(md) {
      position: relative;

      a {
        position: absolute;
      }
    }
  }
}

.blog-article-banner {
  background-size: cover;
  background-position: center;
  height: 315px;
  max-width: 1500px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    height: 476px;
  }

  @media (min-width: 1450px) {
    height: 610px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blog-article {
  .category {
    text-transform: uppercase;
    color: $black;
    border-bottom: 2px solid $black;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 0.5rem;
  }

  h1 {
    text-wrap: pretty;
  }

  .author {
    font-size: 0.75rem;
  }

  .testimonial {
    svg {
      color: #838485;
    }

    .blockquote {
      font-size: 2rem;
      margin-bottom: 0 !important;
      border: none;
      padding: 0;
    }

    blockquote {
      font-family: var(--font-heading);
      font-size: 2.3rem;
      font-weight: 500;
      line-height: 1.4;
      margin: 0 !important;

      &:before {
        display: none !important;
      }

      @media (max-width: 570px) {
        font-size: 1.7rem;
      }
    }

    cite {
      color: #ffcf63;
      font-size: 0.6em;
      margin-top: 1.6em;

      &:before {
        display: none;
      }
    }
  }

  .tags {
    font-size: 0.75rem;

    li {
      margin-right: 0.5rem;

      &:after {
        margin-left: 0.5rem;
        content: '/';
      }

      &:last-of-type:after {
        display: none;
      }
    }

    a {
      color: $gray-800;
      font-weight: 500;
    }
  }
  .sources-disclosures {
    font-size: 0.6875rem;
    line-height: 1.8;

    p {
      font-size: 0.6875rem;
    }
  }
}
