.full-width-feature-highlights-content-boxes {
  .section-top {
    position: relative;
    z-index: 200;
  }

  .card-group {
    justify-content: center;
    margin-top: -30px;

    &[data-count='4'] {
      .card {
        max-width: 500px;
      }

      @media (min-width: 1590px) {
        flex-wrap: initial !important;
        position: relative;
        justify-content: center;

        .card {
          max-width: 350px;

          &:first-child {
            margin-left: 0 !important;
          }

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .card {
    margin-top: 30px !important;
    margin-bottom: 0 !important;
    max-width: 360px;

    .svg-wrapper {
      height: 65px;
      margin-bottom: 20px;
      margin-top: 0;
      position: relative;

      * {
        fill: #4c4c4c !important;
      }

      svg {
        flex-grow: 0;
        flex-shrink: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        max-width: 70px;
        max-height: 66px;
      }
    }
  }

  .card-body {
    text-align: left;
    padding: 20px 30px;
  }

  .card-lead {
    padding-bottom: 6px !important;
  }

  .card-footer {
    text-align: left !important;
    display: block;
    padding: 0 30px 15px 30px !important;

    a {
      margin-top: 0;
      margin-left: 0;
    }

    a,
    a span {
      font-size: inherit !important;
    }
  }

  @media (max-width: 1080px) {
    .card {
      .svg-wrapper {
        height: 60px;
        margin-bottom: 15px;

        svg {
          max-height: 60px;
          max-width: 65px;
        }
      }
    }

    .card-body {
      padding: 10px 15px 20px 15px;
    }

    .card-footer {
      padding: 0 15px 2px 15px !important;
    }
  }

  @include tablet {
    .card-group {
      margin-top: 0 !important;
    }

    .card {
      max-width: none !important;
      margin-bottom: 20px !important;
      margin-top: 0 !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
