.fw-feature-2-bullets {
  .media {
    @include media-breakpoint-down(md) {
      flex-direction: row;
    }
    svg {
      margin-right: 1rem;
      height: 1.75rem;
      fill: $green;
    }
  }
}
