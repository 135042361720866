.browse-by-category {
  // border-top: 2px solid #f2f2f2;
  // border-bottom: 2px solid #f2f2f2;
  // box-shadow: 0 .75rem 1rem -1rem #dadada;
  label {
    font-size: .875rem;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid $gray-400;
      &:last-of-type {
        border: none;
      }
    }
    svg {
      width: 2rem;
      height: 2rem;
      margin-right: .25rem;
      color: $gray-900;
      fill: $gray-900;
    }
    &:hover, &.active {
      span, svg {
        color: $green;
        fill: $green;
      }
    }
  }
}
