// Variables used in Fremont Bank SCSS
// These use camel case to avoid potential
// conflicts with variables created by Wakefly

// Fonts
$headingFont: 'Noe Display Medium', serif;
$bodyFont: 'interstate', sans-serif;

// Colors
$tealColor: #008080;
$goldColor: #edb022;
$darkTealColor: #057676;
$headlineColor: #434343;
$bodyColor: #4c4c4c;
$orangeColor: #e1702c;
$darkOrangeColor: #c25615;
$blueColor: #176980;
$lightGrayColor: #fbfbfb;

// Breakpoints
$desktopMinWidth: 992px;
$tabletMaxWidth: $desktopMinWidth - 1px;
$tabletPortraitMaxWidth: 767px;
$mobileMenuMaxWidth: 550px;
$mobileMaxWidth: 500px;

// Menu
$menuHeight: 79px;
$menuHeightLarge: 110px;
$menuHeightMobile: 72px;

// Sections
$contentMaxWidth: 1300px;
$sectionPaddingDesktop: 80px;
$sectionPaddingTablet: 40px;
$sectionPaddingTabletPortrait: 35px;
$sectionPaddingMobile: 30px;

// Content wrappers
$contentPaddingDesktop: 50px;
$contentPaddingTablet: 35px;
$contentPaddingTabletPortrait: 30px;
$contentPaddingMobile: 30px;

// Quick links
$quickLinksHeadingColor: #4e4e4e;
$quickLinksBorderColor: #ececec;
$quickLinksShadow: 0 0 4px rgba(0, 0, 0, 0.02), 0 4px 8px rgba(0, 0, 0, 0.02);

// Cards
$cardShadow: 0px 6px 10px rgba(0, 0, 0, 0.02), 0px 0px 10px rgba(0, 0, 0, 0.02),
  0px 15px 30px rgba(0, 0, 0, 0.02);
$cardShadowHover: 0px 9px 13px rgba(0, 0, 0, 0.05), 0px 0px 13px rgba(0, 0, 0, 0.03),
  0px 16px 35px rgba(0, 0, 0, 0.05);
