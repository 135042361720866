#login-modal {
  .fb-modal-content {
    padding: 0;
    max-width: 1064px;
  }

  .close-button {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 60px;
    height: 60px;
    margin: 0;
    float: none;

    &:before,
    &:after {
      width: 22px;
    }
  }
}

.login-modal-content {
  display: flex;
  overflow: hidden;

  .toggle {
    max-width: 380px;
  }
}

.login-label-mobile {
  display: none;
}

.login-form-alert {
  font-size: 14px;
  border-radius: 5px;
  margin-top: -10px;
  margin-bottom: 30px;
  color: #282727;
  background-color: #ffe7b1;
  padding: 13px 20px 14px 20px;
}

.login-form-wrapper {
  display: flex;
  width: calc(100% + 6px);
  margin-left: -3px;
  margin-top: 1.194375rem;
  padding: 0 3px;
  transition: transform 0.5s cubic-bezier(0.51, 0.04, 0.08, 1) 0.35s;
  @include preventAnimationWhileResizing;
}

#personal-login,
#business-login {
  width: 100%;
  flex: none;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  @at-root #login-modal.open & {
    &.active,
    &:only-child {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }
}

#business-login {
  &.active {
    transform: translateX(-100%);
  }
}

.login-sidebar {
  background-color: #f8f8fa;
  flex: 0 0 auto;
  width: 400px;
  padding: 80px 75px;

  h2 {
    font-size: 38px;
    line-height: 1.15;
  }

  h3 {
    font-size: 17px;
    font-family: $bodyFont;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: normal !important;
    line-height: 1.15;
  }
}

.account-links {
  margin-top: 50px;
  font-size: 16px;

  ul {
    list-style: none;
    margin: 20px 0 0 0;
    padding: 0;
  }

  li {
    margin: 0 !important;
    border-bottom: 1px solid #e5e5e7;

    &:last-child {
      border-bottom: 0;
    }
  }

  a {
    font-family: $bodyFont;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: normal !important;
    display: flex;
    align-items: center;
    line-height: 1;
    color: #3a3a3a;
    text-decoration: none;
    padding: 14px 15px;
    width: calc(100% + 30px);
    margin: 4px 0 4px -15px;
    border-radius: 6px;
    transition: all 0.2s;
    transition-property: background-color, box-shadow, color;

    &:hover {
      color: $darkTealColor;
      background-color: #fff;
      box-shadow: 0 2px 3px rgba(#000, 0.1), inset 0 0 0 2px $tealColor;

      svg,
      path {
        fill: $tealColor;
      }
    }

    img,
    > svg,
    .svg-wrapper {
      width: 24px;
      height: 24px;
      flex: none;
      margin-right: 15px;
    }

    svg,
    path {
      transition: fill 0.2s;
    }

    svg {
      position: relative;

      &.trust-services-icon {
        left: 3px;
      }
    }
  }
}

.account-login {
  flex-grow: 1;
  position: relative;
  padding: 70px 75px;
  width: 100%;
  overflow: hidden;

  h3 {
    font-size: 32px;
    margin-bottom: 40px;
    max-width: calc(100% - 50px);
  }

  label {
    margin-bottom: 11px;
  }
}

.account-login label,
#account-type-toggle-label {
  display: inline-block;
  font-size: 14px;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

#account-type-toggle-label {
  margin-bottom: 10px;
}

.save-account-type {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  font-size: 13px;
  margin-bottom: -1.47em;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease 0.4s;

  @at-root .save-account-type-visible & {
    opacity: 1;
    visibility: visible;
  }

  input {
    margin-right: 11px;
    margin-top: 0.25em;
  }

  label {
    text-transform: none;
    font-weight: 500;
    margin: 0 !important;
    color: #757575;
    font-size: inherit !important;
    margin-top: -1px;
  }
}

.login-form-field-wrapper {
  margin-bottom: 20px;

  .input-wrapper {
    position: relative;
    max-width: 414px;
  }
}

.login-form-input {
  width: 100%;
  padding: 8px 10px 8px 50px;
  height: 57px;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  color: #313134;
  font-size: 15px;
  border: 2px solid #d7d9dd;
  transition: none;
  font-weight: 500;
  outline: none;

  &.login-password {
    padding-right: 50px;

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  &:focus {
    border-color: $tealColor;
    box-shadow: 0 0 0 0.5px $tealColor;
  }

  @include placeholder {
    color: #8b8b94;
    visibility: hidden;
  }

  @include targetIOS {
    font-size: 16px;
  }
}

.login-form-field-icon {
  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 23px;
    width: 16px;
    height: 16px;
    background-size: cover;
    margin-top: -7px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #949598;

    @at-root .login-form-input:focus + & {
      background-color: $tealColor;
    }

    @at-root .username-input & {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 17' fill='%23949598'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.233 6.784a3.1 3.1 0 1 1 6.199-.103 3.1 3.1 0 0 1-6.2.103Zm3.1-1.55a1.55 1.55 0 1 0 0 3.1 1.55 1.55 0 0 0 0-3.1Z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.39 14.205A7.75 7.75 0 1 0 3.275 2.46 7.75 7.75 0 0 0 13.39 14.205ZM8.332 2.133a6.2 6.2 0 0 0-4.74 10.197 3.876 3.876 0 0 1 3.19-1.672h3.1c1.297 0 2.48.645 3.189 1.672A6.2 6.2 0 0 0 8.333 2.134v-.001Zm3.579 11.263a2.325 2.325 0 0 0-2.03-1.188h-3.1a2.324 2.324 0 0 0-2.028 1.188 6.172 6.172 0 0 0 3.58 1.137 6.171 6.171 0 0 0 3.578-1.137Z' /%3E%3C/svg%3E");
    }

    @at-root .password-input & {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23949598'%3E%3Cpath d='M8 12.19a1.528 1.528 0 0 1-1.524-1.523c0-.838.686-1.524 1.524-1.524s1.524.686 1.524 1.524S8.838 12.19 8 12.19ZM3.429 5.333h.761V3.81A3.81 3.81 0 0 1 8 0a3.81 3.81 0 0 1 3.81 3.81v1.523h.761c.838 0 1.524.686 1.524 1.524v7.62c0 .837-.686 1.523-1.524 1.523H3.43a1.528 1.528 0 0 1-1.524-1.524V6.857c0-.838.685-1.524 1.524-1.524Zm6.933-1.523A2.364 2.364 0 0 0 8 1.448 2.364 2.364 0 0 0 5.638 3.81v1.523h4.724V3.81ZM3.429 14.476h9.142V6.857H3.43v7.62Z'/%3E%3C/svg%3E");
    }
  }
}

.toggle-password {
  --icon-color: #9aa0ac;
  --icon-size: 19px;

  @include disableNativeButton(true);
  position: absolute;
  padding: 6px;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  padding: 3px;

  &:before {
    content: '';
    display: block;
    width: var(--icon-size);
    height: var(--icon-size);
    background-color: var(--icon-color);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3E%3Cpath fill-rule='evenodd' d='M9.5 8.445a1.055 1.055 0 1 0 0 2.11 1.055 1.055 0 0 0 0-2.11ZM6.445 9.5a3.055 3.055 0 1 1 6.11 0 3.055 3.055 0 0 1-6.11 0Z' /%3E%3Cpath fill-rule='evenodd' d='M2.745 9.5c1.939 3.154 4.199 4.542 6.755 4.542s4.816-1.388 6.754-4.542C14.316 6.346 12.056 4.958 9.5 4.958c-2.556 0-4.816 1.388-6.755 4.542Zm15.53-.513C16.057 5.113 13.141 2.958 9.5 2.958c-3.647 0-6.565 2.161-8.785 6.046a1 1 0 0 0 0 .992c2.22 3.885 5.138 6.046 8.785 6.046 3.641 0 6.557-2.155 8.775-6.03a.994.994 0 0 0 0-1.026Z'/%3E%3C/svg%3E");

    @at-root .password-input.password-visible & {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3E%3Cpath fill-rule='evenodd' d='M1.668 1.668a1 1 0 0 1 1.414 0l14.25 14.25a1 1 0 0 1-1.414 1.414L1.668 3.082a1 1 0 0 1 0-1.414Z'/%3E%3Cpath fill-rule='evenodd' d='M9.086 7.674a1 1 0 0 1 0 1.415.584.584 0 0 0 .825.825 1 1 0 1 1 1.414 1.415 2.584 2.584 0 1 1-3.653-3.655 1 1 0 0 1 1.414 0Z' clip-rule='evenodd'/%3E%3Cpath fill-rule='evenodd' d='M9.496 4.958a6.494 6.494 0 0 0-1.809.25 1 1 0 1 1-.55-1.922 8.494 8.494 0 0 1 2.365-.328c3.646.001 6.563 2.162 8.783 6.046a1 1 0 0 1 0 .992c-.646 1.13-1.35 2.116-2.116 2.944a1 1 0 0 1-1.468-1.357A13.392 13.392 0 0 0 16.254 9.5C14.316 6.346 12.056 4.958 9.5 4.958h-.004ZM6.078 4.71a1 1 0 0 1-.266 1.39c-1.088.737-2.116 1.855-3.066 3.4 1.938 3.154 4.198 4.542 6.754 4.542 1.335 0 2.553-.375 3.682-1.136a1 1 0 0 1 1.118 1.658 8.425 8.425 0 0 1-4.8 1.478c-3.647 0-6.565-2.161-8.785-6.046a1 1 0 0 1 0-.992c1.136-1.987 2.453-3.528 3.974-4.56a1 1 0 0 1 1.389.266Z'/%3E%3C/svg%3E");
    }
  }

  &:hover {
    --icon-color: #788193;
  }
}

.login-form-general-error {
  --message-font-size: 0.9em;
  --message-padding: 14px 20px;

  margin-bottom: 20px;
}

.login-form-error {
  color: #ba3c3c;
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 15px;
  display: none;

  &.visible {
    display: block;
  }

  @at-root .login-form-panel:not(.active):not(:only-child) & {
    visibility: hidden !important;
  }
}

.recovery-links {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;

  a {
    color: #6f7380;
    text-decoration: underline;
    text-decoration-color: #caccd2;
    margin-right: 13px;

    &:hover {
      color: #656976;
      text-decoration-color: #737681;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.login-button {
  margin-top: 30px;
  padding: 15px 22px;
  font-size: 13px;
  border-radius: 4px;
  // min-width: 320px;
  min-width: 270px;
  opacity: 1 !important;

  &:has(span) {
    min-width: 320px;
  }
}

.account-enroll {
  color: #757575;
  font-size: 14px;
  margin-top: 45px;
  padding-top: 20px;
  border-top: 1px solid #e2e2e3;

  p {
    &:last-child {
      margin-bottom: 0;
    }

    a {
      font-weight: 600;
      text-underline-offset: 2px;
      display: inline-block;
    }
  }
}

.account-enroll-links-wrapper {
  --item-padding: 5px;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px 15px;
  width: calc(100% + var(--item-padding));
  margin-left: calc(var(--item-padding) * -1);
}

.account-enroll-link {
  display: flex;
  text-decoration: none !important;
  color: var(--teal-color);
  font-size: 14px;
  font-weight: 500;
  padding: var(--item-padding);

  &:hover {
    color: #32a1a1;
  }

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    flex: none;
    background-color: currentColor;
    margin-right: 6px;
  }
}

.personal-enroll-link {
  &:before {
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 23 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.499 3.83a7.669 7.669 0 1 0 0 15.338 7.669 7.669 0 0 0 0-15.338zM2.313 11.5a9.186 9.186 0 1 1 18.372 0 9.186 9.186 0 0 1-18.372 0z'/%3E%3Cpath d='M11.827 10.877c-1.132-.265-1.438-.408-1.438-.857 0-.316.255-.591 1.02-.591.653 0 1.163.265 1.622.734l1.03-1c-.52-.54-1.112-.907-1.866-1.05v-1.03h-1.428v1.02c-1.071.204-1.857.907-1.857 1.978 0 1.408.918 1.826 2.234 2.132 1.346.306 1.591.51 1.591.97 0 .54-.398.723-1.244.723-.684 0-1.326-.234-1.826-.816l-1.03.918c.438.653 1.214 1.102 2.132 1.245v1.01h1.428v-1.01c1.397-.214 2.02-1.01 2.02-2.173 0-1.55-1.255-1.938-2.388-2.203z'/%3E%3C/svg%3E");
  }
}

.business-enroll-link {
  &:before {
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 23 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.345 5.035h1.437V6.47H9.345V5.035zm0 2.873h1.437v1.437H9.345V7.908zm0 2.874h1.437v1.436H9.345v-1.436zm0 2.873h1.437v1.437H9.345v-1.437zm2.873-8.62h1.437V6.47h-1.437V5.035zm0 2.873h1.437v1.437h-1.437V7.908zm0 2.874h1.437v1.436h-1.437v-1.436zm0 2.873h1.437v1.437h-1.437v-1.437z'/%3E%3Cpath d='M20.12 19.402h-3.59V6.472h1.436v10.775a.718.718 0 1 0 1.437 0V5.753a.72.72 0 0 0-.718-.718H16.53V2.88a.719.719 0 0 0-.719-.719H7.19a.718.718 0 0 0-.719.719v2.155H4.316a.718.718 0 0 0-.718.718v13.65H2.88a.718.718 0 1 0 0 1.436h17.24a.718.718 0 1 0 0-1.437zM5.035 6.472H6.47v10.775a.718.718 0 1 0 1.437 0V3.597h7.184v15.805h-1.437v-2.155a.72.72 0 0 0-.718-.718h-2.874a.718.718 0 0 0-.718.718v2.155h-4.31V6.472zm7.183 12.93h-1.436v-1.437h1.436v1.437z'/%3E%3C/svg%3E");
  }
}

@media (max-height: 880px) {
  .account-login {
    padding: 65px 73px;

    h3 {
      margin-bottom: 35px;
    }
  }

  .login-sidebar {
    padding: 65px 70px;
  }

  .login-form-alert {
    margin-bottom: 25px;
  }

  .login-form-field-wrapper {
    margin-bottom: 15px;
  }

  .login-form-input {
    height: 54px;
  }

  .account-enroll {
    margin-top: 35px;
  }
}

@media (max-height: 790px) and (min-width: 991px) {
  .account-login {
    padding: 55px 63px;
  }

  .login-sidebar {
    padding: 55px 60px;
  }
}

@media (max-height: 790px), (max-width: 990px) {
  .login-form-field-wrapper {
    margin-bottom: 11px;

    label {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
  }

  .login-form-input {
    @at-root .login-form-field-wrapper:first-child & {
      margin-top: 2px;
    }

    @include placeholder {
      visibility: visible;
    }

    // @at-root .login-form-panel.active & {
    //   @include placeholder {
    //     visibility: visible;
    //   }
    // }

    // @at-root .single-login & {
    //   @include placeholder {
    //     visibility: visible;
    //   }
    // }
  }
}

@media (max-width: 990px) {
  .account-login {
    padding: 50px 58px;
  }

  .login-sidebar {
    padding: 50px 55px;
    width: 340px;

    h2 {
      font-size: 33px;
    }
  }

  .account-links {
    margin-top: 35px;
    font-size: 15px;

    ul {
      margin-top: 15px;
    }
  }

  .account-login {
    h3 {
      font-size: 28px;
      margin-bottom: 25px;
    }
  }

  .login-form-alert {
    margin-top: 30px;
  }
}

@media (min-width: 921px) {
  .save-account-type-visible {
    .login-form-wrapper {
      transform: translateY(22px);
    }
  }
}

@media (max-width: 920px) {
  // .account-login {
  //   padding: 37px 43px 32px 43px;
  // }

  .login-sidebar {
    padding: 40px;
    width: 300px;
  }

  .save-account-type {
    @include preventAnimationWhileResizing;
    will-change: opacity, margin-bottom;
    transition: opacity 0.5s ease 0.4s,
      margin-bottom 0.6s cubic-bezier(0.33, 0.05, 0, 1) 0.3s;

    @at-root .save-account-type-visible & {
      margin-bottom: 5px !important;
    }
  }

  .login-form-wrapper {
    transition: none;
  }

  .login-button {
    min-width: 0 !important;
  }
}

@media (max-width: 765px) {
  #login-modal {
    .close-button {
      top: 25px;
      right: 25px;
    }
  }

  .login-modal-content {
    flex-direction: column;
  }

  .login-sidebar {
    display: contents;
  }

  .login-modal-title {
    display: none;
  }

  .account-links {
    order: 10;
    background-color: #f8f8fa;
    padding: 40px;
    margin: 0;
  }

  .account-login {
    h3 {
      font-size: 31px;
    }
  }

  .login-form-alert {
    margin-top: 25px;
  }
}

@media (max-width: 615px) {
  .login-modal-content {
    .toggle {
      max-width: none;
    }
  }

  .login-form-field {
    .input-wrapper {
      max-width: none;
    }
  }

  .login-button {
    width: 100%;
    padding: 13px 15px;
  }
}

@media (max-width: 520px) {
  .save-account-type {
    span {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  #login-modal {
    .fb-modal {
      align-items: stretch;
    }

    .close-button {
      top: 15px;
      right: 15px;
    }
  }

  .login-modal-content {
    margin: 0;
  }

  .account-login,
  .account-links {
    padding: 35px;
  }

  .account-login {
    flex-grow: 0;

    h3 {
      margin-top: -5px;
    }
  }

  .account-links {
    flex-grow: 1;

    ul {
      margin-bottom: -25px;
    }
  }

  .account-enroll {
    border-top: none;
    padding-top: 0;
    margin-top: 25px;
  }
}

@media (max-width: 465px) {
  .login-label-desktop {
    display: none;
  }

  .login-label-mobile {
    display: inline;
  }
}

@media (max-width: 420px) {
  .account-links,
  .account-login {
    padding: 30px 28px 25px 28px;
  }

  .account-links {
    ul {
      margin-bottom: -20px;
    }
  }
}

@media (max-width: 400px) {
  #login-modal {
    .close-button {
      top: 10px;
      right: 10px;
    }
  }

  .account-login {
    h3 {
      font-size: 28px;
    }
  }
}

@media (max-width: 385px) {
  .login-button {
    span {
      display: none;
    }
  }
}
