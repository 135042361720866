.full-width-50-50-feature {
  .dynamic-rate-feed {
    line-height: 1;
    font-weight: 500;
    // .rate-item {
    //   .rate-type {
    //     text-transform: uppercase;
    //     color: #747474;
    //     font-size: 0.85rem;
    //     letter-spacing: 2px;
    //     @include media-breakpoint-down(md) {
    //       font-size: 0.55rem;
    //     }
    //   }
    //   .rate-data {
    //     color: $black;
    //     .rate {
    //       font-size: 3.5rem;
    //       @include media-breakpoint-down(md) {
    //         font-size: 2rem;
    //       }
    //     }
    //     .percentage,
    //     .apr {
    //       font-size: 1.3rem;
    //       @include media-breakpoint-down(md) {
    //         font-size: 0.8rem;
    //       }
    //     }
    //   }
    // }
    & + a {
      align-self: baseline;
    }
  }
  .ltr-underline {
    @extend %link-underline-base;
    font-size: 0.875rem;
    font-weight: 500;
    &:hover {
      color: $green;
    }
  }
}
