.homepage {
  header {
    &:not(.loaded),
    &.no-bg {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    &:not(.loaded) {
      transition: none !important;
    }
  }
}

header {
  position: fixed;
  position: sticky;
  top: 0;
  z-index: 1040;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transition-property: background-color, box-shadow;
  background-color: $white;
  box-shadow: 0px 1px 6px rgba(#000, 0.04), 0px 10px 11px rgba(#000, 0.01);

  svg {
    display: none;
  }
  svg:not(.alternate) {
    display: block;
  }
}

.dark-bg-adjustment {
  header:not(.bg-white) {
    .main-navigation {
      @include media-breakpoint-up(lg) {
        svg,
        .dropdown > a,
        .utility-nav a {
          color: $white !important;
        }
      }
    }
    .navbar-brand {
      img {
        display: none;
        &.alternate {
          display: block;
        }
      }
    }
  }
  // .navbar-light {
  //   .navbar-close-icon {
  //     color: $white;
  //   }
  //   .navbar-toggler-icon {
  //     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  //   }
  // }

  // .bg-whtie .navbar-light {
  .navbar-light {
    .navbar-close-icon {
      color: $black;
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23000' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
  }
}
