@use 'sass:math';

// General
.content-section {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
  background-size: cover;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  > .content-wrapper:not(:has(*)) {
    display: none;

    + .section-content {
      margin-top: 0;
    }
  }

  &.text-white,
  .text-white {
    label {
      color: inherit;
    }
  }

  &.content-cover-bg {
    background-size: cover !important;
  }

  &.content-tile-bg {
    background-size: initial;
    background-repeat: repeat;
  }
}

.content-section-main {
  &.content-wrapper {
    padding: 0;
  }

  &.hidden-content-section {
    display: none;
  }
}

.content-full-width {
  &.content-wrapper,
  > .content-wrapper {
    max-width: none !important;
  }
}

// Multi-column layouts
.content-row {
  display: flex;

  &.content-1-col {
    justify-content: center;
  }

  @include tabletPortrait {
    flex-direction: column;
  }
}

@mixin col($num) {
  @at-root .content-row {
    .content-#{$num}-col & {
      @content;
    }
  }
}

@mixin hasHeadline {
  @at-root .content-wrapper + .content-section-main & {
    @content;
  }
}

@mixin noHeadline {
  @at-root .content-section-main:first-child & {
    @content;
  }

  @at-root .content-wrapper:not(:has(*)) + .content-section-main & {
    @content;
  }
}

.content-col-wrapper {
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding: $contentPaddingDesktop;
  min-height: var(--min-height-desktop, 0);

  .content-col {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 920px;

    @at-root .content-1-col & {
      max-width: none;
    }

    @include tabletPortraitUp {
      min-height: var(--content-min-height-desktop, 0);
    }

    @include tabletPortrait {
      max-width: none !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @include tabletPortraitUp {
    &.col-align-left {
      .content-col {
        margin-left: 0 !important;
        margin-right: auto !important;
      }
    }

    &.col-align-right {
      .content-col {
        margin-left: auto !important;
        margin-right: 0 !important;
      }
    }

    &.col-align-center {
      .content-col {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }

  &.col-v-align-top {
    justify-content: flex-start;
  }

  &.col-v-align-center {
    justify-content: center;
  }

  &.col-v-align-bottom {
    justify-content: flex-end;
  }

  &.col-bg {
    margin-top: -$sectionPaddingDesktop + $contentPaddingDesktop;
    margin-bottom: -$sectionPaddingDesktop + $contentPaddingDesktop;

    &:first-child {
      margin-left: $contentPaddingDesktop;
    }

    &:last-child {
      margin-right: $contentPaddingDesktop;
    }

    &.col-full-bleed {
      margin-bottom: -$sectionPaddingDesktop;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .content-col {
      margin-left: 0;
    }

    @include hasHeadline {
      margin-top: 0;
    }

    @include contentUp {
      @include noHeadline {
        margin-top: -$sectionPaddingDesktop + $contentPaddingDesktop;

        &.col-full-bleed {
          margin-top: -$sectionPaddingDesktop;
          padding: $sectionPaddingDesktop;
        }
      }
    }

    @at-root .content-2-column & {
      + .col-no-bg {
        margin-left: math.div($contentPaddingDesktop, 2);

        .content-col {
          margin-left: 0;
        }
      }
    }
  }

  &.col-cover-bg {
    background-size: cover !important;
  }

  &.col-tile-bg {
    background-size: initial;
    background-repeat: repeat;
  }

  @include content {
    &.col-bg {
      $sectionPadding: $contentPaddingDesktop + 10px;
      $offset: -$sectionPadding + $contentPaddingDesktop;
      $fullBleedOffset: -$sectionPadding;
      margin-top: $offset;
      margin-bottom: $offset;

      &.col-full-bleed {
        margin-bottom: $fullBleedOffset;
      }

      @include noHeadline {
        @include tabletUp {
          margin-top: $offset;

          &.col-full-bleed {
            margin-top: $fullBleedOffset;
            padding: $sectionPadding;
          }
        }
      }
    }
  }

  @include tablet {
    padding: $contentPaddingTablet;

    // Node Sass uses its own implementation of min() and max(),
    // which doesn't support CSS custom properties. To force it to
    // use the native CSS function we have to interpolate it as
    // a string. This can be solved by upgrading to Dart Sass.
    $minHeightOffset: ($sectionPaddingDesktop - $sectionPaddingTablet) * 2;
    min-height: #{'max(calc(var(--min-height-desktop, 0px) - #{$minHeightOffset}), 0px)'};

    &.col-bg {
      $offset: -$sectionPaddingTablet + $contentPaddingTablet;
      $fullBleedOffset: -$sectionPaddingTablet;
      margin-top: $offset;
      margin-bottom: $offset;

      &:first-child {
        margin-left: $contentPaddingTablet;
      }

      &:last-child {
        margin-right: $contentPaddingTablet;
      }

      &.col-full-bleed {
        margin-bottom: $fullBleedOffset;
      }

      @include noHeadline {
        @include tabletPortraitUp {
          margin-top: $offset;

          &.col-full-bleed {
            margin-top: $fullBleedOffset;
            // padding: $sectionPaddingTablet;
          }
        }
      }

      @at-root .content-2-column & {
        + .col-no-bg {
          margin-left: math.div($contentPaddingTablet, 2);
        }
      }

      @include tabletPortraitUp {
        &.col-full-bleed {
          padding: $sectionPaddingTablet;
        }
      }
    }
  }

  // Switch to vertical rows on small screens
  @include tabletPortraitUp {
    &.col-no-bg {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @include tabletPortrait {
    padding: $contentPaddingTabletPortrait;
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-basis: auto;
    max-width: none !important;
    min-height: var(--min-height-mobile, 0);
    order: var(--row-index-mobile, 100);

    &.col-bg,
    &.col-bg.col-full-bleed {
      margin-top: 0;
      margin-bottom: 0;

      &.first-mobile-row {
        @include noHeadline {
          margin-top: -$sectionPaddingTabletPortrait;
        }
      }

      &.last-mobile-row {
        margin-bottom: -$sectionPaddingTabletPortrait;
      }
    }

    &.col-no-bg {
      &.first-mobile-row {
        padding-top: 0;
      }

      &.last-mobile-row {
        padding-bottom: 0;
      }
    }

    &.no-row-gap {
      padding-top: 0 !important;
    }
  }

  @include mobile {
    padding: $contentPaddingMobile;

    &.col-bg,
    &.col-bg.col-full-bleed {
      &.first-mobile-row {
        @include noHeadline {
          margin-top: -$sectionPaddingMobile;
        }
      }

      &.last-mobile-row {
        margin-bottom: -$sectionPaddingMobile;
      }
    }
  }

  .section-top {
    margin-top: 35px;
    margin-bottom: 30px;
    overflow: hidden;

    .section-header {
      margin-bottom: 0.75em;

      @include pageBuilder {
        margin-bottom: 0;
      }
    }

    .section-headline {
      margin-top: 0;
      margin-bottom: 0.38em;
    }

    @include tablet {
      margin-top: 30px;
      margin-bottom: 25px;
    }

    &.heading-h1 {
      margin-top: 40px;
      margin-bottom: 35px;

      @include tablet {
        margin-top: 35px;
        margin-bottom: 30px;
      }
    }

    &.heading-h3 {
      .text {
        font-size: 1rem;
      }

      @include tablet {
        .section-header {
          font-size: 12px;
        }
      }
    }

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    &:not(.text-center) {
      text-align: left;

      h2,
      .section-headline {
        &.accent-line {
          > span {
            &:before {
              display: none;
            }
          }
        }
      }

      .text {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 30px;
    margin-bottom: 1rem;
    color: $headlineColor;

    &:first-child {
      margin-top: -0.13em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  &.text-white,
  .text-white {
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }

  h1 {
    margin-bottom: 1.2rem;
  }

  h4 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.36rem;
    letter-spacing: -0.025em;
    margin-bottom: 0.8rem;
  }

  .form-section,
  .ktc-default-section {
    .form-field:first-child {
      padding-top: 0 !important;
    }
  }
}

.col-side {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 35%;

  @include col(3) {
    flex-basis: 26%;
  }
}

.form-widget-form,
.mkto-form-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// Kentico Page Builder
.content-section,
.fb-modal-title {
  .ktc-widget-zone {
    &[zone-name='section-headline'] {
      &.ktc-widget-zone--empty {
        > .ktc-admin-ui:first-child {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(
              127deg,
              #fafafa 25%,
              #ebecf0 25%,
              #ebecf0 50%,
              #fafafa 50%,
              #fafafa 75%,
              #ebecf0 75%,
              #ebecf0 100%
            );
            background-size: 12.52px 16.62px;
            opacity: 0.6;
            transition: opacity 0.2s;
          }

          &:after {
            content: '+ \00a0 Add headline';
            position: absolute;
            font-weight: 600;
            font-size: 1.2rem;
            letter-spacing: -0.03em;
            display: block;
            text-align: center;
            line-height: 1;
            background-color: rgba(#9aa8b0, 0.25);
            color: #9c9fa5;
            font-family: $bodyFont;
            text-transform: uppercase;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 14px 25px 14px 20px;
            border-radius: 3px;
            transition: all 0.2s;
            transition-property: color, background-color;
          }
        }

        &:hover {
          & > .ktc-admin-ui:first-child {
            &:before {
              opacity: 1;
            }

            &:after {
              background-color: rgba(#9aa8b0, 0.38);
              color: #6e737e;
            }
          }
        }

        kentico-add-component-button {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transform: none;
        }

        .ktc-component-button,
        .ktc-primary {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          opacity: 0;
        }
      }

      &:not(.ktc-widget-zone--empty) {
        &:after,
        .ktc-border-root--primary:after,
        kentico-add-component-button {
          display: none !important;
        }

        .text {
          div[data-inline-editor] {
            padding-bottom: 2px;
          }
        }
      }
    }
  }
}
