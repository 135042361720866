*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 15px;
}

body {
  font-weight: 500;
  line-height: 1.53;
  color: $bodyColor;
  font-family: $bodyFont;
}

#header,
#main,
#footer {
  font-family: $bodyFont;
}

.sans-serif {
  font-family: $bodyFont !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
.comparison-table table caption,
.noe-display-medium {
  font-family: $headingFont;
  color: $headlineColor;
  font-weight: 500;
  letter-spacing: normal !important;

  @at-root {
    .text-white &,
    .component-find-a-branch-contact-us .contact-us & {
      color: #fff !important;
    }

    body:not(.homepage) & {
      sup {
        margin-left: 0.04em;
        left: 0;
        margin-right: 0.09em;
        font-weight: 500;
      }
    }
  }
}

body:not(.homepage) {
  h1,
  .h1,
  h2,
  .h2 {
    sup {
      top: -0.7em;
      font-size: 0.45em;
    }
  }

  h1,
  .h1 {
    sup {
      font-weight: 500;
    }
  }

  h2,
  .h2 {
    sup {
      top: -0.75em;
      font-size: 0.5em;
    }
  }

  h3,
  .h3,
  .comparison-table table caption,
  .noe-display-medium {
    sup {
      top: -0.8em;
      font-size: 0.55em;
      font-weight: 600;
    }
  }
}

h1,
.h1 {
  font-size: 3.15rem;
}

h2,
.h2 {
  font-size: 2.6rem;
}

main {
  p {
    text-wrap: pretty;
  }

  ul,
  ol {
    margin-left: 1em;
    text-wrap: pretty;

    + p:empty {
      display: none;
    }
  }

  li:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

a {
  &[href*='tel'] {
    user-select: auto;
  }
}

.section-headline {
  margin-top: 0.38em;
  margin-bottom: 0.38em;

  &:first-child {
    margin-top: 0;
  }

  + p {
    font-size: 1.08rem;
  }
}

.text-black {
  color: #424242;
}

.section-header {
  font-weight: 600;
  color: #434343;
  opacity: 0.75;
  letter-spacing: 0.088em;
  font-size: 13px;
  line-height: 1.2;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0;

  textarea {
    text-transform: uppercase;
  }

  &:empty {
    display: none;
  }

  &:not(:empty) {
    + h2,
    + .h2,
    + .section-headline {
      &:not(:empty) {
        margin-top: 0.2em;
      }
    }
  }

  @at-root .text-white & {
    color: #fff;
    opacity: 1 !important;
  }
}

.section-subtitle {
  font-weight: 600;
  text-transform: none;
  color: $headlineColor;
  opacity: 1;
  letter-spacing: normal;
  font-size: 1.18rem;
  margin-bottom: 0.25em;
  display: block;

  @at-root .section-bottom & ~ p {
    max-width: 800px;
  }
}

#main p,
.section-headline {
  &:empty {
    display: none;
  }
}

.small-headline,
h2.small-headline,
h3.small-headline {
  font-family: $bodyFont !important;
  letter-spacing: normal !important;
  font-weight: 600 !important;
  color: $bodyColor;
  font-size: 1.06667em;
  text-transform: uppercase;
}

@include tablet {
  body {
    font-size: 0.9rem;
  }

  h1,
  .h1 {
    font-size: 2.6rem;
    line-height: 1.05 !important;
  }

  h2,
  .h2 {
    font-size: 2.15rem;
  }

  .section-headline + p {
    font-size: 1rem;
  }
}

@include tabletPortrait {
  h1,
  .h1 {
    font-size: 2.3rem;
  }

  h2,
  .h2 {
    font-size: 2rem;
  }
}

@include mobile {
  .section-headline {
    br {
      display: none;
    }
  }
}

// Use black and gold brand colors for text selection
body:not(.KenticoAdmin) {
  @include highlight {
    background-color: #ffc333;
    color: #141414;
  }
}
