@use 'sass:math';

$defaultMaxWidth: 785px;
$breakpoint: 1022px;
$desktopPadding: 66px;
$tabletPadding: 50px;
$mobilePadding: 30px;
$desktopCloseSize: 50px;
$mobileCloseSize: 44px;
$background: rgba(#1c2727, 0.65);

@mixin modalOpen {
  @at-root dialog[open] & {
    @content;
  }

  @at-root .fb-modal-wrapper.open & {
    @content;
  }
}

@mixin backdropAnimation {
  animation: {
    name: backdropFade;
    duration: 0.3s;
    fill-mode: forwards;
    timing-function: ease;
  }
}

dialog.fb-modal-wrapper {
  &::backdrop {
    background-color: $background;
  }

  // The dialog polyfill adds an adjacent element
  // with the .backdrop class. Styles for this must
  // be set separately from the ::backdrop pseudoelement
  // as browsers that don't support <dialog> would
  // ignore the CSS since ::backdrop is invalid
  & + .backdrop {
    background-color: $background;
  }

  &[open] {
    &::backdrop {
      @include backdropAnimation;
    }

    & + .backdrop {
      @include backdropAnimation;
    }
  }
}

.fb-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-overflow-scrolling: touch;
  outline: none !important;

  // Styles for when not using a <dialog> element
  &:not(dialog) {
    z-index: 10001;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $background;
      opacity: 0;
      pointer-events: none;
    }

    &.open {
      visibility: visible;

      &:before {
        @include backdropAnimation;
      }
    }

    &:not(.open) * {
      visibility: hidden !important;
    }
  }
}

.fb-modal {
  width: 100%;
  // height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  -webkit-overflow-scrolling: touch;
  @extend %smoothAnimation;

  @include modalOpen {
    animation: {
      name: backdropFade;
      duration: 0.15s;
      delay: 0.15s;
      fill-mode: forwards;
      timing-function: ease;
    }
  }

  > * {
    pointer-events: auto;
  }

  @media (max-width: 500px) {
    padding: 12px;
  }
}

@keyframes backdropFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fb-modal-content {
  $initialScale: 0.88;
  width: 100%;
  padding: $desktopPadding;
  max-width: $defaultMaxWidth;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  transform: scale($initialScale);
  position: relative;
  will-change: transform;
  pointer-events: auto;

  @include tabletPortrait {
    padding: $tabletPadding;
  }

  @include mobile {
    padding: $mobilePadding;
  }

  @include modalOpen {
    animation: {
      name: modalScale;
      duration: 0.32s;
      delay: 0.15s;
      fill-mode: forwards;
      timing-function: cubic-bezier(0.19, 0.91, 0.24, 1);
    }
  }

  @keyframes modalScale {
    0% {
      transform: $initialScale;
    }

    100% {
      transform: scale(1);
    }
  }

  @media (max-width: 500px) {
    border-radius: 6px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  form > div:first-child > .form-field:first-child {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  label {
    font-size: 0.93333rem;
    letter-spacing: 0.01em;
  }

  .submit-button,
  .form-widget-form input[type='submit'] {
    font-size: 1rem;
    padding: 0.7em 2.2em;
    margin-top: 40px !important;
    margin-bottom: -10px;

    @include pageBuilder {
      margin-bottom: 0 !important;
    }

    @include tabletPortrait {
      margin-top: 30px !important;
    }

    @include mobile {
      margin-top: 25px !important;
      margin-bottom: 4px;
    }
  }

  .form-widget-form {
    margin: 30px 0;

    &:last-child {
      margin-bottom: 0;
    }

    + .form-widget-form {
      padding-top: 20px;
    }

    @include mobile {
      margin: 25px 0;
    }
  }
}

.fb-modal-top {
  padding: 12px 12px 12px 30px;
  width: calc(100% + #{$desktopPadding * 2});
  margin-left: -$desktopPadding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.01), 0px 5px 12px rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid #eeeeee;
  text-transform: uppercase;
  position: relative;
  z-index: 999;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.2;
  flex-shrink: 0;
  flex-grow: 0;

  @media (max-width: 1000px) {
    font-size: 15px;
  }

  @media (max-width: 560px) {
    padding-left: 20px;
  }

  @include mobile {
    width: calc(100% + #{$mobilePadding * 2});
    margin-left: -$mobilePadding;
  }
}

.fb-modal-title {
  margin: 0 #{$desktopCloseSize - 10px} 35px 0;
  padding: 0;
  position: relative;
  top: -0.15em;
  font-size: 33px;
  line-height: 1.1;
  color: #434343;

  @at-root .no-close-button & {
    margin-right: 0;
  }

  h2,
  h2,
  h3 {
    font-size: inherit;
  }

  .section-top {
    &:not(.text-center) {
      text-align: left;

      h2,
      .section-headline {
        &.accent-line {
          > span {
            &:before {
              display: none;
            }
          }
        }
      }

      .text {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @include mobile {
    font-size: 1.8rem;
    margin: 0 #{$mobileCloseSize - 10px} 25px 0;

    .section-header {
      letter-spacing: 0.07em;
      font-size: 0.85rem;
    }
  }
}

.close-button {
  $offset: 35px;
  width: $desktopCloseSize;
  height: $desktopCloseSize;
  margin: #{-$offset} #{-$offset} 0 #{$desktopCloseSize - $offset};
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
  min-height: 0;
  position: relative;
  // position: sticky;
  // top: 25px;
  float: right;
  display: block;
  padding: 0 !important;
  border-radius: 50%;
  background-color: rgba(#fff, 0.9);
  backdrop-filter: blur(3px);
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.2s;

  &:only-child {
    margin-left: auto;
  }

  &:hover {
    background-color: rgba(#f6f6f6, 0.8);
  }

  @include keyboardFocus {
    background-color: transparent;
    box-shadow: none !important;
    outline: 2px solid $tealColor !important;
    outline-offset: -2px !important;

    &:before,
    &:after {
      background-color: $darkTealColor;
    }
  }

  &:before,
  &:after {
    content: '';
    display: block !important;
    position: absolute;
    background-color: #8a8a8a;
    transition: none !important;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 2px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  @include mobile {
    $offset: 18px;
    width: $mobileCloseSize;
    height: $mobileCloseSize;
    margin: #{-$offset} #{-$offset} 0 #{$mobileCloseSize - $offset};
  }

  @include pageBuilder {
    &:focus:not(:focus-visible) {
      outline: none !important;

      &:before,
      &:after {
        background-color: #8a8a8a !important;
      }
    }
  }

  + * {
    margin-top: 0;
  }
}

.iframe-modal-wrapper {
  .fb-modal {
    height: 100%;
  }

  .fb-modal-content {
    height: 100%;
    max-height: 800px;
    max-width: $breakpoint;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: 0px;
    overflow: hidden;
  }

  .fb-modal-top {
    width: 100%;
    margin-left: 0px;
  }

  .close-button {
    width: 46px;
    height: 46px;
    float: none;
    margin: 0px 0px 0px 15px;
  }

  .iframe-wrapper {
    flex-grow: 1;
    overflow: hidden;

    iframe {
      height: 100%;
      width: 100%;
      border: none;
      position: relative;
      display: block;

      @media (min-width: #{$breakpoint - 1px}) {
        width: calc(100% + 2px);
        margin-left: -1px;
      }

      $mobileBreakpoint: 1020px;

      @media (max-width: #{$breakpoint - 2px}) and (min-width: #{$mobileBreakpoint + 1px}) {
        width: $breakpoint;
        left: 50%;
        margin-left: #{math.div(-$breakpoint, 2)};
      }
    }
  }
}

@media (max-width: 670px) {
  .iframe-modal-wrapper {
    .fb-modal {
      padding: 0;
      overflow: hidden;
      align-items: stretch !important;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
    }

    .fb-modal-content {
      border-radius: 0;
      max-height: 100%;
      flex-grow: 1;
      align-self: stretch;
      overflow: hidden;
    }
  }
}

.KenticoAdminPageBuilder {
  &.fb-modal-open {
    .ktc-section-header {
      visibility: hidden !important;
      opacity: 0 !important;
      pointer-events: none !important;
    }

    &.draggable--is-dragging {
      pointer-events: none;

      .ktc-widget-zone--banned {
        &:before,
        kentico-alert-box {
          visibility: hidden;
        }

        > .ktc-admin-ui > .ktc-border {
          border-color: #bdbbbb;
          z-index: 1;
        }
      }

      .fb-modal-wrapper {
        pointer-events: auto;

        .ktc-widget-zone--banned {
          &:before,
          kentico-alert-box {
            visibility: visible;
          }

          > .ktc-admin-ui > .ktc-border {
            border-color: #b12628;
          }
        }
      }
    }
  }

  .close-button {
    position: relative;
    top: 0;
  }
}

.open-modal-settings {
  position: fixed;
  bottom: 25px;
  right: 25px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  padding: 15px 20px;
  border-radius: 4px;
  background-color: rgba(#292929, 0.8);
  color: #fff;
  transition: background-color 0.2s, opacity 0.2s ease 0.5s;
  z-index: 99;
  appearance: none;
  border: none;
  backdrop-filter: blur(2px);
  outline: none !important;
  box-shadow: none !important;
  visibility: hidden;
  opacity: 0;

  @include modalOpen {
    visibility: visible;
    opacity: 1;
  }

  &:hover {
    background-color: rgba(#292929, 0.93);
  }
}

body.screenshot-modal {
  .ktc-border,
  .ktc-dropdown-editor,
  kentico-add-component-button {
    display: none !important;
  }

  .form-widget-form {
    margin-top: 0 !important;
  }

  .fb-modal-content {
    padding: 20px;
    border-radius: 0;
    min-height: 600px;
  }

  .fb-modal-title {
    margin-bottom: 10px;

    &:empty {
      display: none;
    }
  }
}
