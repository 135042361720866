.alert-bar {
  color: #fff;

  a {
    color: #fff;
    text-underline-position: under;
    text-decoration-thickness: 2px !important;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.65) !important;
    &:hover {
      text-decoration-color: rgba(255, 255, 255, 0.65) !important;
      opacity: 0.85;
    }
  }
}

.alert-high {
  background-color: #c84d4d;

  .alert-bar-svg {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgODQgODQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQxLjY2OCAwQzE4LjY4NCAwIDAgMTguNzAzIDAgNDEuNjY4YzAgMjIuOTg0IDE4LjY4NCA0MS42NjggNDEuNjY4IDQxLjY2OCAyMi45NjUgMCA0MS42NjgtMTguNjg0IDQxLjY2OC00MS42NjhDODMuMzM2IDE4LjcwMyA2NC42MzMgMCA0MS42NjggMHptMCA3Mi4wNTFhNy4wNTUgNy4wNTUgMCAwIDEtNy4wNS03LjA1IDcuMDUzIDcuMDUzIDAgMCAxIDcuMDUtNy4wNTJjMy44ODMgMCA3LjA1IDMuMTY4IDcuMDUgNy4wNTFzLTMuMTY3IDcuMDUxLTcuMDUgNy4wNTF6bTYuODUyLTI0Ljg0OGE2LjgzNyA2LjgzNyAwIDAgMS02Ljg1MiA2LjU2NiA2Ljg2NCA2Ljg2NCAwIDAgMS02Ljg2Ny02LjU2NmwtMS4yMzUtMjcuNDQ5YTguMDA1IDguMDA1IDAgMCAxIDIuMjUtNS45NjUgNy45OTEgNy45OTEgMCAwIDEgNS44NTItMi41YzIuMjM0IDAgNC4zMTYuODgzIDUuODUyIDIuNSAxLjU1IDEuNjE3IDIuMzMyIDMuNzM1IDIuMjM0IDUuOTY1TDQ4LjUyIDQ3LjIwM3oiIGZpbGw9IiNmZmYiLz48L3N2Zz4=');
  }
}

.alert-medium {
  background-color: #fdb108;
  color: #444444;

  .alert-bar-svg {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgODQgODQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQxLjY2OCAwQzE4LjY4NCAwIDAgMTguNzAzIDAgNDEuNjY4YzAgMjIuOTg0IDE4LjY4NCA0MS42NjggNDEuNjY4IDQxLjY2OCAyMi45NjUgMCA0MS42NjgtMTguNjg0IDQxLjY2OC00MS42NjhDODMuMzM2IDE4LjcwMyA2NC42MzMgMCA0MS42NjggMHptMCA3Mi4wNTFhNy4wNTUgNy4wNTUgMCAwIDEtNy4wNS03LjA1IDcuMDUzIDcuMDUzIDAgMCAxIDcuMDUtNy4wNTJjMy44ODMgMCA3LjA1IDMuMTY4IDcuMDUgNy4wNTFzLTMuMTY3IDcuMDUxLTcuMDUgNy4wNTF6bTYuODUyLTI0Ljg0OGE2LjgzNyA2LjgzNyAwIDAgMS02Ljg1MiA2LjU2NiA2Ljg2NCA2Ljg2NCAwIDAgMS02Ljg2Ny02LjU2NmwtMS4yMzUtMjcuNDQ5YTguMDA1IDguMDA1IDAgMCAxIDIuMjUtNS45NjUgNy45OTEgNy45OTEgMCAwIDEgNS44NTItMi41YzIuMjM0IDAgNC4zMTYuODgzIDUuODUyIDIuNSAxLjU1IDEuNjE3IDIuMzMyIDMuNzM1IDIuMjM0IDUuOTY1TDQ4LjUyIDQ3LjIwM3oiIGZpbGw9IiM0NDQiLz48L3N2Zz4=');
  }

  .alert-close-btn {
    svg {
      fill: #444444;
    }
  }

  a {
    text-decoration-color: rgba(78, 78, 78, 0.6) !important;
    color: #444444;

    &:hover {
      text-decoration-color: rgba(78, 78, 78, 0.6) !important;
    }
  }
}

.alert-low {
  background-color: #2b2c2e;
  .alert-bar-svg {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOTAgOTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQ1IDBDMjAuMTQ4IDAgMCAyMC4xNDggMCA0NXMyMC4xNDggNDUgNDUgNDUgNDUtMjAuMTQ4IDQ1LTQ1UzY5Ljg1MiAwIDQ1IDB6bTAgMTYuNTYyYTggOCAwIDEgMSAwIDE2IDggOCAwIDAgMSAwLTE2em0xMiA1NC44NzVDNTcgNzIuNTQzIDU2LjEwNSA3MyA1NSA3M0gzNWMtMS4xMDUgMC0yLS40NTctMi0xLjU2M3YtMWMwLTEuMTA1Ljg5NS0yLjQzOCAyLTIuNDM4aDJWNDNoLTJjLTEuMTA1IDAtMi0uNDU3LTItMS41NjJ2LTFjMC0xLjEwNS44OTUtMi40MzggMi0yLjQzOGgxNmMxLjEwNSAwIDIgMS4zMzIgMiAyLjQzOHYyNy41NjJoMmMxLjEwNSAwIDIgMS4zMzIgMiAyLjQzOHYxeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');
  }
}

.alert-bar-content {
  max-width: $contentMaxWidth;
  padding: 20px 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.93333rem;
}

.alert-bar-text {
  p {
    margin-bottom: 0.1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.alert-bar-icon {
  margin-right: 8px;
  margin-top: 2px;
  align-self: flex-start;

  img {
    width: 16px;
    height: 16px;
  }
}

.alert-bar-svg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  flex-grow: 0;
  flex-shrink: 0;
}

.alert-close-btn {
  border: 0;
  padding: 0;
  background-color: transparent;
  margin-left: 15px;
  opacity: 0.9;
  line-height: 1;
  flex-grow: 0;
  flex-shrink: 0;

  &:hover {
    opacity: 0.7;
  }

  svg {
    fill: #fff;
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1300px) {
  .alert-bar-content {
    padding: 20px 25px;
  }
}

@media (max-width: 500px) {
  .alert-close-btn {
    margin-left: 10px;
  }
}
