.multi-column-section-feature {
  .columns {
    > div {
      @include media-breakpoint-up(md) {
        border-right: 2px solid $gray-400;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    ul {
      margin: 0;
    }
  }

  .lead:first-child {
    font-weight: 600;
    display: block;

    &:not(:last-child) {
      margin-bottom: 10px;
      font-size: 1rem;
    }
  }
}
