.comparison-table-wrapper {
  .content-wrapper {
    max-width: 1450px;
  }

  .th {
    display: table-cell !important;
  }
}

.comparison-table {
  table {
    margin-left: auto;
    margin-right: auto;
    max-width: 1380px;

    &:first-child {
      margin-top: 10px;
    }

    caption {
      font-family: 2.25rem;
      font-family: $headingFont;
      font-weight: 500;
      color: $gray-900;
      caption-side: top;
      margin-bottom: 0.5em;

      @include tablet {
        font-size: 1.6rem;
      }
    }

    thead {
      &.desktop {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &.mobile {
        select {
          margin-bottom: 3px;
        }

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      th,
      td {
        border-bottom-width: 2px !important;
        border-bottom-color: #212121;
        font-size: 0.98rem;
        padding-bottom: 0.7rem !important;
        padding-top: 0 !important;

        @include media-breakpoint-down(lg) {
          vertical-align: middle;
        }
      }
    }

    th,
    td {
      border-top: none !important;
      border-bottom: 1px solid #d2d2d2;
      font-size: 0.88rem;
      line-height: 1.35;
      padding: 1.08rem 1.5rem 1.12rem 0 !important;

      &:first-child:not(:only-child) {
        border-right: 18px solid #fff !important;

        @at-root .bg-gray-100 & {
          border-right-color: #fbfbfb !important;
        }
      }

      &:last-child {
        padding-right: 0 !important;
      }

      @include media-breakpoint-down(md) {
        padding: 0.75rem 0 !important;
      }

      @media (max-width: 440px) {
        font-size: 0.82rem;
        line-height: 1.25;
      }
    }

    th,
    .th {
      border-top: none;
      vertical-align: top;
      font-weight: 600;

      &:first-child {
        padding-left: 0 !important;
        min-width: 10rem;

        @include media-breakpoint-down(md) {
          width: 35%;
        }
      }
    }

    td {
      &.bool-cell {
        vertical-align: middle;
      }
    }

    .invisible {
      border-bottom: none;
      border-width: 0;
      padding: 0.5rem !important;
    }

    br {
      content: '';
      display: block;
      font-size: inherit;
      height: 0.5em;
    }
  }

  .more-features-row {
    th,
    td {
      transition: all 0.2s;
      will-change: font-size;
    }

    &.collapsing {
      th,
      td {
        color: #fff;
        font-size: 0%;
        padding: 0 !important;
        border-bottom: 0 !important;
      }

      img,
      svg {
        width: 0;
        height: 0;
      }
    }
  }

  .more-features {
    td {
      position: relative;
      border: none !important;
      padding: 0.7rem 0 !important;

      &::after {
        position: absolute;
        top: -1px;
        left: 0px;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        border-top: 2px solid #fff;
        box-sizing: border-box;

        @at-root .bg-gray-100 & {
          border-top-color: #fbfbfb;
        }
      }
    }

    #moreFeatures {
      padding: 0.35rem !important;

      .indicator {
        transform: rotate(-90deg);

        &:after {
          content: '➜';
        }
      }

      &:not(.collapsed) {
        .see-more {
          display: none;
        }
      }

      &.collapsed {
        .indicator {
          transform: rotate(90deg);
        }

        .see-less {
          display: none;
        }
      }
    }
  }

  .learn-more-links {
    th,
    td {
      border: none;
      font-size: 1rem;
    }

    a {
      display: inline-block;
      line-height: 1.1;

      &:not(.btn) {
        font-weight: 500;
      }

      span {
        &:after {
          content: '➜';
        }
      }
    }
  }

  tbody {
    tr:last-child {
      td {
        padding-bottom: 0 !important;
      }
    }
  }
}

.cell-attribute-wrapper {
  display: flex;
  align-items: center;

  svg {
    flex-grow: 0;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 440px) {
    display: block;

    svg {
      width: 16px;
      height: 16px;

      &:not(:only-child) {
        margin-top: 1px;
      }

      + div {
        margin-top: 8px;
      }
    }
  }
}
