// Disable animations for users with epilepsy
// or who prefer reduced motion
@media (prefers-reduced-motion) {
  body #site-search-wrapper,
  body #main > *:not(#mortgage-rate-calculator-wrapper):not(#locations) {
    // General elements
    *,
    *:before,
    *:after,
    .ltr-underline:after {
      transition: none !important;
      animation-duration: 0s !important;
      animation-delay: 0s !important;
    }
    // Replace loading spinner with loading text
    .spinner {
      svg {
        display: none;
      }

      &:before {
        content: 'Loading...';
        font-size: 1.1em;
        position: absolute;
        line-height: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 15px 18px;
        background-color: #494949;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 4px;
      }
    }
    // Disable default hidden state for
    // suggested search terms
    .search-term {
      transform: none !important;
    }

    .suggested-search-terms:not(.hidden) {
      .search-term {
        opacity: 1;
      }
    }
  }
}
