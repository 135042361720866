.component-section-background-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  min-height: 580px;
  display: flex;
  align-items: center;
  padding: 0;

  $baseContentWidth: 480px;
  $gradientOffset: 40%;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0.9;
    z-index: 150;
    width: calc(
      (100% - #{$contentMaxWidth}) / 2 + #{$baseContentWidth} + #{$gradientOffset}
    );
  }

  .section-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    pointer-events: none;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.text-white {
    background-color: #292929;

    a {
      color: inherit;
    }

    .btn[class*='btn-outline'] {
      color: #fff;
      border-color: #fff;

      &:hover {
        color: $teal;
        background-color: #fff;
      }
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: 300;
    padding: 0;
  }

  .content {
    @include sectionPadding;
    @include contentPadding;
    position: relative;
    margin: 20px 0;
    width: $baseContentWidth;
    max-width: 100%;
  }

  &.content-right {
    &:before {
      left: auto;
      right: 0;
    }

    .content-wrapper {
      justify-content: flex-end;
    }
  }

  &.full-opacity-gradient {
    $offset: 10%;

    &:before,
    .section-bg {
      left: $offset;
    }

    &.content-right {
      &:before {
        right: $offset;
        left: auto;
      }

      .section-bg {
        left: 0;
        right: $offset * 3;
      }
    }
  }

  .text {
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .buttons-wrapper {
    align-items: center;
    margin-top: 25px;

    &:first-child {
      margin-top: 0;
    }
  }

  @include tablet {
    min-height: 0 !important;

    &:before {
      min-width: 820px;
    }

    .content {
      max-width: 420px;
    }

    &.full-opacity-gradient {
      $offset: 20%;

      &:before,
      .section-bg {
        left: $offset;
      }

      &:before {
        min-width: 650px;
      }

      &.content-right {
        &:before {
          right: $offset;
        }

        .section-bg {
          right: $offset * 2;
        }
      }
    }
  }

  @include tabletPortrait {
    display: flex;
    flex-direction: column;

    &:before {
      display: none;
    }

    .content {
      width: 100% !important;
      max-width: none !important;
      margin: 0;
    }

    .section-bg {
      position: relative;
      width: 100% !important;
      max-width: none !important;
      min-width: 0 !important;
      height: 330px;
      left: auto !important;
      right: auto !important;
    }

    &.mobile-image-above {
      flex-direction: column-reverse;
    }

    &.has-mobile-gradient {
      .section-bg {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 250px;
        }
      }

      &:not(.mobile-image-above) {
        .content {
          padding-bottom: 0;
          margin-bottom: -30px;
        }
      }

      &.mobile-image-above {
        .content {
          margin-top: -20px;
        }

        .section-bg {
          &:before {
            top: auto;
            bottom: 0;
            height: 260px;
          }
        }
      }
    }
  }
}
