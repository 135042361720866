.locations-map {

  .sticky-map {
    @include media-breakpoint-up(lg) {
      top: initial !important;
    }
    @include media-breakpoint-down(md) {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }

  #location-map {
    width: 100%;
    height: 100%;
    min-height: 800px;
    @include media-breakpoint-down(md) {
      min-height: 275px;
      border: 2px solid $gray-400;
    }

    .gm-style .gm-style-iw-c {
      padding: 0 !important;
      border-radius: 0 !important;
    }

    // google map overwrites
    .gm-style {
      .gm-style-iw-c {
        padding: 0 !important;
        border-radius: 0 !important;
      }
      .gm-style-iw-d {
        overflow: auto !important;
      }
      .gm-style-iw-t {
        &:after {
          display: none;
        }
      }
    }
  }

  .location-window {
    .branch-info {
      a {
        font-weight: bold;
      }
    }
    .branch-thumbnail {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .location-listings {
    ul {
      max-height: 550px;
      overflow-y: scroll;
      padding-right: .6rem;

      /* scrollbar */
      &::-webkit-scrollbar {
      width: 6px;                           /* chrome, edge, safari */
      }
      &::-webkit-scrollbar-track {
        background: transparent;            /* chrome, edge, safari */
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;           /* chrome, edge, safari */
      }

      scrollbar-width: thin;                /* firefox */
      scrollbar-color: $green transparent;  /* firefox */
    }

    li {
      margin: 1rem 0;
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      .distance-from {
        min-height: 21px;
      }
    }
    button {
      font-size: .875rem;
      .location-name {
        font-family: Noe Display Medium,serif;
        font-weight: 500;
        font-size: 1.35rem;
        line-height: 1;
      }
      .location-links {
        display: none;
      }
      &.active {
        box-shadow: 0 .75rem 1rem 0 #dadada;
        .location-name {
          color: $green;
        }
        .location-links {
          display: flex;
        }
      }
      &:hover {
        box-shadow: 0 .75rem 1rem 0 #dadada;
        .location-name {
          color: $green;
        }
      }
    }
  }
}

.location-details {
  .location-search-form {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  #location-map {
    min-height: 315px;
    @include media-breakpoint-up(lg) {
      min-height: 475px;
    }
    // google map overwrites
    .gm-style {
      .gm-style-iw-c {
        padding: 0 !important;
        border-radius: 0 !important;
      }
      .gm-style-iw-d {
        overflow: auto !important;
      }
      .gm-style-iw-t {
        &:after {
          display: none;
        }
      }
    }
  }
}
