// Standard styles for commonly-used HTML elements and framework classes

h1,
h2,
h3 {
  font-family: $headingFont;
  font-weight: 500;

  sup {
    font-size: initial;
    letter-spacing: 0;
  }
}

h1,
.h1 {
  color: $gray-900;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 1.725rem;
    line-height: 1.2;

    sup {
      top: -0.5rem;
      left: 0.25rem;
    }
  }

  @include media-breakpoint-up(lg) {
    sup {
      top: -1.75rem;
      left: 0.5rem;
    }
  }
}

h2,
.h2 {
  line-height: 1.1;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
    sup {
      top: -0.4rem;
      left: 0.35rem;
    }
  }

  @include media-breakpoint-up(lg) {
    sup {
      top: -1.1rem;
      left: 0.35rem;
    }
  }
}

h3,
.h3 {
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 1.6rem;
    sup {
      top: -0.5rem;
      left: 0.25rem;
    }
  }

  @include media-breakpoint-up(lg) {
    sup {
      top: -0.75rem;
      left: 0.25rem;
    }
  }
}

// p {
//   @include media-breakpoint-down(md) {
//     font-size: 0.875rem;
//   }
// }

table {
  caption {
    color: #4c4c4c;
    caption-side: top;
    margin-bottom: 0.5rem;
  }
}

button {
  &.close {
    padding: 0.5rem;
  }
}

ul,
ol {
  padding-left: 1rem;
}

a[href^='mailto'] {
  word-wrap: anywhere;
}

iframe {
  border: none;
}

.card-group {
  @include media-breakpoint-up(md) {
    display: flex !important;
  }
}

.media {
  @include media-breakpoint-down(sm) {
    flex-direction: row;
  }

  svg {
    color: $green;
    width: 1.75rem;
    height: 1.75rem;
  }
}

.accordion {
  & > .card {
    overflow: initial;
  }
}

// index page
.page-templates {
  li[class*='home'],
  li[class*='page-'] {
    font-weight: bold;
  }
}

// content box
.content-box {
  &.bg-white {
    .card,
    .product-item {
      background-color: $gray-100;
      &:hover {
        background-color: $white;
        box-shadow: 0 0 1rem $gray-300;
      }
    }
  }

  &.bg-gray-100 {
    .card,
    .product-item {
      background-color: $white;
      &:hover {
        background-color: $gray-100;
        box-shadow: 0 0 1rem $gray-300;
      }
    }
  }
}

// modal
.modal {
  transition: all 0.3s;

  [data-dismiss] {
    color: $black;
    opacity: 1;
  }
}

.update-modal-bg {
  .modal-backdrop {
    background-color: $gray-200;
    transition: all 0.3s ease-in-out;
  }
}

.card {
  .modal-open {
    .component-client-stories {
      .card {
        &:hover {
          z-index: auto !important;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 725px;
    [data-dismiss] {
      margin-right: -18px;
      color: $white;
      border-radius: 50%;
    }
  }

  .video-modal {
    bottom: 0;
    right: 0;
    margin: auto;
    top: 15%;
    [data-dismiss] {
      background-color: $black;
    }
  }

  .video-container {
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #fff;
      color: #000;
      font-weight: 500;
      font-size: 0.8125rem;
    }
  }
}

.social-icons {
  li {
    margin: 0 0.275rem;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  svg {
    width: 30px;
    height: 30px;
  }

  a:hover svg {
    color: $yellow;
  }
}

.mobile-only {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
.desktop-only {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.ltr-underline {
  @extend %link-underline;
}

.link-underline-left {
  @extend %link-underline-left;
}

main {
  .ltr-underline {
    display: inline-block !important;
    font-size: 0.875rem;
  }
}

.top-bottom-border-with-shadow {
  border-top: 2px solid $gray-200;
  border-bottom: 2px solid $gray-200;
  box-shadow: 0 0.75rem 1rem -1rem #dadada;
}

.w-80 {
  @include media-breakpoint-up(lg) {
    width: 80% !important;
    margin: auto;
  }
}

.custom-checkbox {
  .custom-control-label:after,
  .custom-control-label:before {
    top: 0.1rem;
  }
}

body.KenticoAdmin:not(.landing-page) #header {
  position: relative !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

body.KenticoAdminPageBuilder:not(.landing-page) #header {
  pointer-events: none;
}
