%link-underline-base {
  display: inline-block;
}

%link-underline {
  &:after {
    display: block;
    content: '';
    border-bottom: solid 2px currentColor;
    padding-bottom: 0.275rem;
    transform: scaleX(0);
    transform-origin: 0% 50%;
    transition: transform 250ms ease-in-out;
  }
  &:hover {
    text-decoration: none;
    &:after {
      transform: scaleX(1);
    }
  }
  span {
    &:after {
      content: ' ➜'; /* (U+279C) */
    }
  }
  &.inline {
    display: inline-block;
  }
  &.font-adjust {
    font-size: 0.875rem;
    font-weight: 500;
  }
  &.with-arrow {
    font-size: 0.875rem;
    font-weight: 500;
    &:before {
      content: ' ➜';
      float: right;
      padding-left: 0.25rem;
    }
  }
}

%link-underline-left {
  &:after {
    display: block;
    content: '';
    border-bottom: solid 2px currentColor;
    padding-bottom: 0.275rem;
    transform: scaleX(0);
    transform-origin: 100% -0%;
    transition: transform 250ms ease-in-out;
  }
  &:hover {
    text-decoration: none;
    &:after {
      transform: scaleX(1);
    }
  }
  span {
    transform: rotate(180deg);
    display: inline-block;
    &:after {
      content: '➜'; /* (U+279C) */
    }
  }
  &.inline {
    display: inline-block;
  }
  &.font-adjust {
    font-size: 0.875rem;
    font-weight: 500;
  }
}
