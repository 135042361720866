#report-fraud {
  counter-reset: fraud-steps;
  font-size: 16px;

  p,
  li,
  .sc-boxes-wrapper {
    max-width: var(--max-text-width, 1015px);
  }

  &:not(:last-child) {
    padding-bottom: 0;
  }
}

.report-fraud-step {
  --padding-left: 35px;
  --gap: 45px;
  --num-size: 30px;
  --num-offset: 0px;

  counter-increment: fraud-steps;
  position: relative;
  padding-left: calc(var(--padding-left) + var(--num-size));
  padding-bottom: var(--gap);

  &:before {
    content: counter(fraud-steps);
    position: absolute;
    color: var(--teal-color);
    top: 3px;
    left: var(--num-offset);
    font-size: 15px;
    padding-bottom: 1px;
    font-weight: 600;
    border-radius: 50%;
    display: flex;
    width: var(--num-size);
    height: var(--num-size);
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 3px solid var(--teal-color);
    background-color: #fff;
    z-index: 100;
  }

  &:after {
    content: '';
    position: absolute;
    top: 6px;
    bottom: 0;
    width: 3px;
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 2 2' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cline x1='1' y1='0' x2='1' y2='2' stroke='%23000' stroke-width='2' stroke-dasharray='8 8' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E");
    background-color: #cdd3dc;
    left: calc(var(--num-size) / 2 - 2px + var(--num-offset));
    z-index: 1;
  }

  &:last-child {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  @at-root p + & {
    margin-top: 40px;
  }

  *:last-child {
    margin-bottom: 0;
  }

  h2 {
    font-size: 32px;
  }

  .sc-box-item-gray,
  .sc-box-item-shadow {
    ol {
      list-style: none;
      margin-left: 0;
      counter-reset: num-list;

      li {
        counter-increment: num-list;
        margin-bottom: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
        border-bottom: 1px solid #e3e4eb;
        position: relative;

        &:before {
          content: counter(num-list) '.';
          text-align: right;
          margin-right: 10px;
          position: absolute;
          top: 0;
          left: 0;
          color: #929398;
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }

      ::marker {
        color: #797979;
      }
    }
  }
}

.sc-credit-logo {
  margin-top: calc(var(--box-padding) * -1 + 25px);
  margin-bottom: 18px;
  position: relative;

  &.equifax {
    top: 2px;
  }

  &.innovis {
    top: 3px;
  }

  &.transunion {
    top: -2px;
  }
}

.ktc-widget {
  .report-fraud-step {
    padding-bottom: 15px;

    &:after {
      display: block;
    }
  }

  &:nth-last-child(2) {
    .report-fraud-step {
      &:after {
        display: none;
      }
    }
  }
}

@media (max-width: 1090px) {
  .report-fraud-step {
    --padding-left: 30px;
  }
}

@media (max-width: 700px) {
  .report-fraud-step {
    --padding-left: 15px;
    --num-offset: -5px;
  }
}
