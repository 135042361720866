.agent-bio-hero-banner {
  z-index: 1;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    @include tabletPortrait {
      display: none;
    }
  }

  &:before {
    width: calc(50% + 200px);
    z-index: -1;
    opacity: 0.89;
    $gradientColor: #f8f8f8;
    background: linear-gradient(
      90deg,
      $gradientColor 50%,
      rgba($gradientColor, 0.985499) 54.98%,
      rgba($gradientColor, 0.948373) 59.34%,
      rgba($gradientColor, 0.892121) 63.16%,
      rgba($gradientColor, 0.820247) 66.52%,
      rgba($gradientColor, 0.736251) 69.49%,
      rgba($gradientColor, 0.643636) 72.17%,
      rgba($gradientColor, 0.545904) 74.63%,
      rgba($gradientColor, 0.446554) 76.96%,
      rgba($gradientColor, 0.349091) 79.22%,
      rgba($gradientColor, 0.257015) 81.52%,
      rgba($gradientColor, 0.173827) 83.92%,
      rgba($gradientColor, 0.10303) 86.51%,
      rgba($gradientColor, 0.0481257) 89.37%,
      rgba($gradientColor, 0.012615) 92.57%,
      rgba($gradientColor, 0) 96.21%
    );
  }

  &:after {
    right: 0;
    background-image: url('/Content/breakout-fremont-bank/dist/images/sf-bg-white.svg');
    background-size: auto calc(100% + 60px);
    background-position: right bottom;
    z-index: -2;
    opacity: 0.6;
  }

  // Override existing interior banner styles
  &.interior-split-banner {
    .content {
      flex-grow: 1;
      width: auto;
      min-height: 300px;
    }

    .text {
      padding-top: 30px;
      padding-bottom: 30px;

      @media (max-width: 1300px) {
        padding-top: 20px;
        padding-bottom: 40px;
      }

      @include mobile {
        padding-top: $contentPaddingMobile;
        padding-bottom: $contentPaddingMobile;
      }
    }

    h1 {
      margin-bottom: 0.25em;
      margin-top: 0;

      @include mobile {
        margin-top: -18px;
      }
    }
  }

  @media (max-width: 1130px) and (min-width: #{$tabletPortraitMaxWidth + 1px}) {
    .content-wrapper {
      padding-right: 0;
    }
  }
}

.agent-photo-wrapper {
  width: 500px;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  object-position: center center;

  @media (max-width: 1130px) {
    width: 400px;
  }

  @include tabletPortrait {
    width: calc(100% + #{$contentPaddingTabletPortrait * 2});
    margin-left: -$contentPaddingTabletPortrait;
    height: 450px;
  }

  @media (max-width: 620px) {
    height: 400px;
  }

  @media (max-width: 490px) {
    height: 300px;
  }
}

.agent-photo {
  margin: auto;
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-position: inherit;
}

.agent-info {
  color: #707070;
  line-height: 1.2;
  font-size: 0.95em;

  > *:not(:last-child) {
    margin-right: 13px;
    padding-right: 11px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 1px;
      right: 0;
      width: 2px;
      background-color: #c2c2c2;
    }
  }

  @media (max-width: 420px) {
    > * {
      display: block;

      &:after {
        display: none;
      }

      &:not(:last-child) {
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 3px;
      }
    }
  }
}

.agent-contact-info {
  font-size: 1.05em;
  font-weight: 500;
  padding: 18px 40px;
  background-color: rgba(#fff, 0.9);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02), 0px 0px 10px rgba(0, 0, 0, 0.02),
    0px 15px 30px rgba(0, 0, 0, 0.02);
  margin-top: 40px;
  color: #625f5f;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 550px;

  @at-root h1 + .description &:first-child {
    margin-top: 30px !important;
  }

  &:first-child {
    margin-top: 0;
  }

  > * {
    position: relative;
    padding: 12px 0 12px 36px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:before {
      content: '';
      flex: none;
      background-size: cover;
      display: block;
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      top: 15px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #eaeaea;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  @media (max-width: 1300px) {
    margin-top: 30px;
    padding: 12px 28px;
    font-size: 1em;

    > * {
      padding-left: 32px;

      &:before {
        width: 18px;
        height: 18px;
      }
    }
  }

  @include mobile {
    margin-top: 20px;
  }

  @media (max-width: 460px) {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-bottom: -12px;
    padding: 7px 25px 7px 25px;
  }

  @media (max-width: 395px) {
    width: auto;
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: -15px;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
}

.agent-phone:before {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.012 15.32l3.124-2.883c.6-.6.84-2.042.12-2.882L9.97 4.03c-.84-.961-2.042-1.081-2.883-.361C5.645 4.99 2.763 7.633 2.523 9.675-.119 18.324 20.181 40.066 28.95 38.024c2.042-.12 4.805-2.763 6.247-4.205.84-.72.84-1.922-.12-2.883l-5.166-5.645c-.72-.72-2.163-.72-2.763-.12l-3.123 2.882c-5.286-2.162-10.211-7.446-12.013-12.732zm16.577 6.007c-.12 1.08-1.922.96-1.802-.24.6-4.806-2.642-8.29-7.567-8.05-1.201 0-1.201-1.8-.12-1.8 6.126-.242 10.09 4.083 9.489 10.09zM18.498 3.789c-1.202.12-1.202-1.681 0-1.681 12.372-.24 20.18 8.169 19.098 20.54-.12 1.082-1.801.962-1.68-.24.96-11.172-6.127-18.74-17.418-18.619zm.36 4.564c-1.08 0-1.2-1.801 0-1.801 8.77-.361 15.376 6.726 14.295 15.375-.12 1.201-1.802.96-1.682-.24.84-7.448-4.924-13.695-12.612-13.334z' fill='%23C5C5C5'/%3E%3C/svg%3E");

  @media (max-width: 1300px) {
    width: 19px;
    height: 19px;
  }
}

.agent-email {
  text-transform: lowercase;

  &:before {
    width: 21px;
    height: 21px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.693 29.105V8.918c.03-.079.055-.16.075-.242.391-2.236 2.337-3.908 4.61-3.91 9.543-.009 19.086-.009 28.63 0 2.165 0 4.038 1.52 4.547 3.622.044.178.096.353.14.53l-.001 20.187a.988.988 0 00-.066.167c-.353 1.97-1.723 3.488-3.67 3.853-.91.172-1.868.13-2.805.13-8.887.01-17.775.01-26.663 0-2.185 0-4.02-1.381-4.614-3.443-.067-.229-.122-.469-.183-.707zm12.93-10.44c-.595.585-1.156 1.14-1.708 1.7l-6.047 6.03c-.365.362-.535.774-.38 1.273.274.907 1.336 1.125 2.048.419 2.515-2.503 5.023-5.013 7.53-7.529a2.75 2.75 0 00.251-.347c.208.19.346.309.481.43l3.022 2.747c.575.52 1.174.513 1.752-.008 1.045-.945 2.09-1.894 3.135-2.842.095-.09.2-.177.317-.285.946.947 1.87 1.876 2.794 2.802l5.035 5.035c.341.343.732.503 1.208.376.924-.25 1.188-1.325.489-2.028-1.682-1.694-3.37-3.383-5.062-5.065-.9-.9-1.805-1.796-2.72-2.71.12-.113.217-.207.317-.297l3.677-3.34 3.761-3.42c.362-.33.506-.733.386-1.204v-.001a1.125 1.125 0 00-.846-.855c-.475-.126-.86.047-1.214.37-3.947 3.596-7.9 7.19-11.859 10.779-.095.089-.197.172-.303.264L14.03 15.82c-2.177-1.972-4.355-3.95-6.534-5.928-.344-.314-.727-.464-1.187-.336l-.002.002a1.13 1.13 0 00-.839.855c-.114.475.038.874.4 1.202 1.557 1.412 3.114 2.826 4.668 4.242l3.086 2.807z' fill='%23C5C5C5'/%3E%3C/svg%3E");

    @media (max-width: 1300px) {
      width: 18px;
      height: 18px;
    }
  }
}

.agent-fax:before {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.333 15H1.667A1.672 1.672 0 000 16.667V28.3a1.673 1.673 0 001.667 1.667h5v-4.975h26.666v4.975h5A1.673 1.673 0 0040 28.3V16.667A1.672 1.672 0 0038.333 15zm-11.666 5.833a1.667 1.667 0 11-.001-3.335 1.667 1.667 0 01.001 3.335zm5 0a1.667 1.667 0 11-.001-3.335 1.667 1.667 0 01.001 3.335z' fill='%23C4C4C4'/%3E%3Cpath d='M9.167 29.967v6.7a1.669 1.669 0 001.666 1.666h18.334a1.669 1.669 0 001.666-1.666v-9.175H9.167v2.475zM30.833 7.5H27.5A2.507 2.507 0 0125 5V1.667H10.833a1.67 1.67 0 00-1.666 1.666v10h21.666V7.5z' fill='%23C4C4C4'/%3E%3Cpath d='M27.5 5.833h3.333l-4.166-4.166V5c.002.46.374.831.833.833z' fill='%23C4C4C4'/%3E%3C/svg%3E");

  @media (max-width: 1300px) {
    width: 17px;
    height: 17px;
  }
}

.agent-booking {
  &:before {
    width: 21px;
    height: 21px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' class='calendar-svg' width='46' height='46' viewBox='0 0 46 46' fill='none'%3e%3cpath d='M37.72 4.6h-1.84V2.76a1.839 1.839 0 1 0-3.68 0V4.6h-7.36V2.76a1.839 1.839 0 1 0-3.68 0V4.6H13.8V2.76a1.839 1.839 0 1 0-3.68 0V4.6H8.28a7.36 7.36 0 0 0-7.36 7.36v25.76a7.36 7.36 0 0 0 7.36 7.36h29.44a7.36 7.36 0 0 0 7.36-7.36V11.96a7.36 7.36 0 0 0-7.36-7.36zM8.28 8.28h1.84v1.84a1.839 1.839 0 1 0 3.68 0V8.28h7.36v1.84a1.839 1.839 0 1 0 3.68 0V8.28h7.36v1.84a1.839 1.839 0 1 0 3.68 0V8.28h1.84a3.68 3.68 0 0 1 3.68 3.68v3.68H4.6v-3.68a3.68 3.68 0 0 1 3.68-3.68zM37.72 41.4H8.28a3.68 3.68 0 0 1-3.68-3.68v-18.4h36.8v18.4a3.68 3.68 0 0 1-3.68 3.68z' fill='%23c4c4c4'%3e%3c/path%3e%3cpath d='M13.8 26.68c0 2.453-3.68 2.453-3.68 0s3.68-2.453 3.68 0zm0 7.36c0 2.453-3.68 2.453-3.68 0s3.68-2.453 3.68 0zm7.36-7.36c0 2.453-3.68 2.453-3.68 0s3.68-2.453 3.68 0zm0 7.36c0 2.453-3.68 2.453-3.68 0s3.68-2.453 3.68 0zm7.36-7.36c0 2.453-3.68 2.453-3.68 0s3.68-2.453 3.68 0zm0 7.36c0 2.453-3.68 2.453-3.68 0s3.68-2.453 3.68 0zm7.36-7.36c0 2.453-3.68 2.453-3.68 0s3.68-2.453 3.68 0zm0 7.36c0 2.453-3.68 2.453-3.68 0s3.68-2.453 3.68 0z' fill='%23c4c4c4'%3e%3c/path%3e%3c/svg%3e");
    @media (max-width: 1300px) {
      width: 18px;
      height: 18px;
    }
  }
  button {
    padding: 0px;
    background: none;
    border: none;
    color: #057676;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Bottom tile boxes
.agent-bio-hero-banner ~ .full-width-feature-highlights-content-boxes {
  .card-body {
    padding: 0 !important;
  }

  .card-footer {
    padding: 10px 0 0 !important;
  }

  .svg-wrapper svg {
    top: 50% !important;
  }

  .link-arrow {
    top: 0.02em !important;
  }
}
