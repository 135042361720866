.tile-widget-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 30px;
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;

  &.content-full-width {
    max-width: 1300px;
  }
}

a.tile-widget {
  text-decoration: none;
  cursor: pointer;
  color: $bodyColor;
}

.tile-widget {
  background-color: #f9f9f9;
  transition: all 0.45s;
  transition-property: box-shadow, background-color;
  display: flex;
  flex-direction: column;
  max-width: 560px;

  @at-root .bg-gray-100 & {
    background-color: #fff;
    box-shadow: $cardShadow;
  }

  &:hover {
    background-color: #fff !important;
    box-shadow: $cardShadowHover !important;
  }

  *:last-child {
    margin-bottom: 0;
  }

  @at-root {
    .get-started &:not(:hover),
    .bg-white &:not(:hover),
    .section:not(.bg-white):not(.bg-gray-100) & {
      box-shadow: none;
    }
  }

  .tile-widget-content {
    padding: 35px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .tile-widget-top {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1.1;

    &:last-child {
      margin-bottom: 0;
    }

    @at-root a.tile &:hover {
      text-decoration: underline;
      color: #858585;
    }

    * {
      font-size: inherit;
      line-height: inherit;
    }

    h3 {
      margin-top: -0.2em;
    }
  }

  .tile-widget-image {
    height: 170px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .svg-wrapper {
    padding: 45px 35px 0px 35px;
    margin-bottom: -5px;

    svg {
      height: 60px;
    }

    svg,
    path {
      fill: $tealColor;
    }
  }

  .tile-widget-footer {
    margin-top: 30px;
  }
}

.KenticoAdminPageBuilder {
  .tile-widget-wrapper {
    justify-items: normal;
    > .ktc-widget-zone {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      grid-gap: 30px;

      &:before,
      &:after {
        display: none;
      }

      &.ktc-widget-zone--empty {
        > .ktc-admin-ui {
          display: block;
        }
      }

      > .ktc-admin-ui {
        display: none;
      }

      > .ktc-widget {
        max-width: 560px;

        > div:not(.ktc-admin-ui) {
          height: 100%;

          > div {
            height: 100%;

            > .ktc-widget-body-wrapper {
              height: 100%;

              .tile-widget {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
