.component-mortgage-rates {
  @include tickerTape;
  padding: 15px 10px 16px 10px;
  position: relative;

  .container {
    max-width: 1230px;
  }

  .tape-title {
    display: block !important;
    font-weight: 600;
    color: #494949;
    font-size: 1.05em !important;
    margin-top: -2px;
    margin-bottom: 2px;
  }

  .rate-content {
    display: flex;
    position: relative;

    @media (min-width: 661px) {
      min-height: 60px;
      align-items: center;
    }
  }

  .rate-left {
    margin-right: 5px;

    h2 {
      margin-top: -1px !important;
      margin-bottom: 6px;
      font-size: 1.9rem;
    }
  }

  .rate-disclaimer {
    font-size: 0.8rem;
    color: #767676;
    line-height: 1.4;

    a,
    span {
      font-size: inherit !important;
      line-height: inherit !important;
    }

    a {
      font-size: inherit !important;
    }

    .ltr-underline {
      position: relative;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        display: none !important;
      }
    }

    > span:not(:last-child) {
      margin-right: 3px;
    }

    .highlight {
      background-color: #e0f6e9;
      color: #287636 !important;
      font-weight: 600;
      border-radius: 30px;
      padding: 0.36em 0.85em;
      margin: 1px 2px 2px -2px;
      text-transform: uppercase;
      font-size: 0.88em !important;
      flex-grow: 0;
      display: inline-block !important;
      line-height: 1 !important;
      position: relative;
      text-decoration: none !important;

      @media (max-width: 1190px) {
        margin-top: 0;
        margin-right: 5px;
      }

      @media (max-width: 660px) {
        margin-left: -4px;
        margin-top: -2px;
        margin-bottom: 7px;
      }
    }

    a.highlight {
      &:hover {
        background-color: #d6ecdf;
      }
    }

    @media (max-width: 980px) {
      > span:first-child {
        right: 0;
        top: 2px;
        margin-bottom: 2px;
      }
    }
  }

  &.has-third-rate {
    .rate-left {
      margin-right: 6px;
    }

    .rate-disclaimer {
      span {
        display: block;
      }
    }
  }

  .rate-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .rate-item {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 26px;
    padding-left: 28px;
    border-left: 1px solid #e1e1e1;
  }

  &.has-third-rate {
    .rate-item {
      margin-left: 18px;
      padding-left: 20px;
    }
  }

  .rate-type,
  .rate-data {
    position: relative;
    top: 1px;
  }

  .rate-type {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    color: #747474;
    line-height: 1;
    margin-bottom: 4px;
    letter-spacing: 0.02em;
    white-space: nowrap;
  }

  .rate-data {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  .rate-amount {
    font-weight: 600;
    font-size: 2.35rem;
    color: #373737;
    line-height: 1;
    letter-spacing: -0.03em;
  }

  .rate-right {
    font-weight: 600;
    font-size: 0.98rem;
    line-height: 1;
    color: #5f5e5e;
    margin-left: 4px;
    position: relative;

    sup {
      font-size: 0.7em;
    }

    span {
      display: block;
    }
  }

  .rate-links {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 20px;

    > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  &.has-third-rate {
    @media (max-width: 1200px) {
      .rate-item {
        &:first-child {
          padding-left: 0;
          margin-left: 20px;
          border-left: none;
        }
      }
    }
  }

  @mixin layoutMedium {
    .rate-content {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .rate-left {
      margin-right: 0;
      flex-grow: 1;
      order: 1;

      h2 {
        margin-bottom: 15px;
        font-size: 1.9rem !important;
      }
    }

    .rate-item {
      &:first-child {
        border-left: none !important;
        margin-left: 0 !important;
        padding-left: 0 !important;
      }
    }

    .rate-disclaimer {
      position: absolute;
      right: 0;
      bottom: 2px;
      text-align: right;

      span {
        &:first-child {
          right: -3px;
          position: relative;
        }
      }

      sup {
        margin-left: 1px;
        font-size: 0.72em;
      }
    }

    .rate-wrapper {
      order: 3;
      width: 100%;
      flex-shrink: 0;
      flex-wrap: wrap;
    }

    .rate-links {
      order: 1;
    }
  }

  &.has-third-rate {
    @media (max-width: 1190px) {
      @include layoutMedium;
    }
  }

  @media (max-width: 1180px) {
    .rate-item {
      margin-left: 17px;
      padding-left: 18px;
    }
  }

  @media (max-width: 1030px) {
    .rate-left {
      h2 {
        font-size: 1.7rem !important;
        margin-bottom: 4px;
      }
    }

    .rate-disclaimer {
      span {
        display: block;
      }
    }
  }

  @media (max-width: 980px) {
    @include layoutMedium;
  }

  @mixin layoutSmall {
    .rate-disclaimer {
      bottom: auto;
      top: 3px;
    }

    .rate-wrapper {
      order: 2;
    }

    .rate-item {
      margin-bottom: 14px;
    }

    .rate-links {
      order: 3;
      margin-left: 0;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }

  &.has-third-rate {
    @media (max-width: 855px) {
      @include layoutSmall;
    }
  }

  @media (max-width: 685px) {
    @include layoutSmall;
  }

  @mixin mobileLayout {
    .rate-left {
      h2 {
        font-size: 1.8rem !important;
      }
    }

    .rate-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .rate-item {
      width: 100%;
      margin-left: 0 !important;
      padding-left: 0 !important;
      border-left: none !important;

      &:not(:last-child) {
        margin-bottom: 18px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e1e1e1;
      }
    }

    .rate-type {
      font-size: 14px;
      margin-bottom: 6px;
    }

    .rate-amount {
      font-size: 35px;
    }

    .rate-right {
      font-size: 14px;
    }

    .rate-links {
      margin-top: 10px;
    }
  }

  &.has-third-rate {
    @media (max-width: 650px) {
      @include mobileLayout;
    }
  }

  @media (max-width: 623px) {
    .rate-content {
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }

    .rate-left {
      display: contents;

      h2 {
        margin-bottom: 17px;
      }
    }

    .rate-disclaimer {
      position: static !important;
      text-align: left;
      order: 30;

      span {
        display: inline !important;
        right: 0 !important;
      }
    }

    &.has-third-rate {
      @media (max-width: 650px) {
        .rate-item {
          padding-left: 0;
          margin-left: 0;
        }
      }
    }

    .rate-links {
      margin-bottom: 18px !important;
      margin-top: 3px;
      width: 100%;
      order: 20 !important;

      > a {
        flex-grow: 1;
      }
    }
  }

  @media (max-width: 545px) {
    @include mobileLayout;
  }

  @media (max-width: 450px) {
    .rate-links {
      display: block;

      > a {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  // Compressed Rates

  // Many of these styles require the parent component
  // to have a .has-third-rate class, which is
  // not configurable from inside Kentico.
  @mixin compressedRateItem {
    @at-root {
      #main .component-mortgage-rates {
        &.has-third-rate .rate-item,
        &.compressed-rates .rate-item,
        .rate-item.compressed {
          @content;
        }
      }
    }
  }

  &.has-third-rate,
  &.compressed-rates {
    .rate-disclaimer span:not(.tape-title) {
      display: inline !important;
    }

    @include compressedRateItem {
      margin-left: 18px;
      padding-left: 20px;
    }

    @media (min-width: 1250px) {
      .rate-left {
        margin-right: 13px;
      }

      .rate-item.compressed:first-child {
        margin-right: 7px;
      }

      @at-root #main .component-mortgage-rates:not(.compressed-rates) {
        .rate-item.compressed:first-child {
          margin-left: 26px;
        }
      }
    }

    @media (max-width: 1200px) {
      @include compressedRateItem {
        &:first-child {
          padding-left: 0;
          margin-left: 20px;
          border-left: none;
        }
      }
    }

    @media (max-width: 1190px) {
      .tape-title {
        right: 5px;
      }

      .rate-disclaimer a {
        margin-left: 5px;
      }
    }

    @media (max-width: 920px) and (min-width: 856px) {
      @include compressedRateItem {
        margin-left: 10px;
        padding-left: 13px;

        .rate-amount {
          font-size: 2rem;
        }

        .rate-right {
          font-size: 0.88rem;
        }
      }
    }

    @media (max-width: 885px) {
      .rate-disclaimer {
        top: auto;
        bottom: 6px;
        right: -6px;
      }

      .rate-disclaimer a {
        position: relative;
        top: 2px;
      }

      .tape-title {
        margin-bottom: 3px;
      }
    }

    @media (max-width: 660px) {
      .rate-left {
        display: contents;
      }

      .rate-disclaimer {
        position: static;
        order: 30;
        text-align: left;
      }

      .tape-title {
        right: 0;
      }

      .rate-links {
        margin-bottom: 18px;
      }
    }

    @media (max-width: 400px) {
      .rate-disclaimer {
        span:first-child {
          display: block !important;
        }

        a {
          margin-left: 0 !important;
        }
      }
    }
  }

  // Both Rate & APR
  .rate-item.rate-and-apr {
    padding-left: 8px;
    border-left: none;
    margin-left: 6px;

    &:first-child {
      margin-left: 8px;
    }

    .rate-type {
      font-size: 14px;
      margin-bottom: 6px;
    }

    .rate-amount {
      font-size: 2.1rem;
    }

    .rate-right {
      font-size: 0.9rem;
    }

    @media (min-width: 856px) and (max-width: 912px) {
      .rate-amount {
        font-size: 1.7rem;
      }
    }
  }
}
