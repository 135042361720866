.predictive-faq-search {
  border-bottom: 1px solid #efefef;
  background-color: #fdfdfd;

  &:last-child {
    background-color: #fbfbfb;
    border-bottom: none;
  }
}

.predictive-search {
  input {
    font-weight: 500;
    font-size: 1rem;
  }

  #autoComplete_results {
    font-size: 0.875rem;
    background-color: $white;
    border-right: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    margin: 0 !important;

    &:empty {
      display: none;
    }

    span {
      font-weight: 500;
    }

    li {
      a {
        padding: 0.5rem 1rem;
        display: block;
        color: inherit;

        &:hover,
        &:focus {
          background-color: $green;
          color: $white;
          text-decoration: none;
        }
      }
    }

    .no_result {
      padding: 0.5rem 1rem;
    }
  }
}

.faq-search-results {
  position: relative;

  #autoComplete_results {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 300;
  }
}
