.comp-faq {
  .accordion {
    a.btn {
      font-size: 0.625rem;
      padding: 0.25rem 0.25rem;
      letter-spacing: 0.25px;
      min-width: 12rem;
      &:hover {
        color: $white;
      }
    }
    button.btn {
      text-transform: capitalize;
      font-weight: 900;
      font-size: 0.9375rem;
      .open {
        display: block;
        margin: -4px auto 0;
      }
      .close {
        display: block;
        margin: 3px auto 0;
        align-self: flex-start;
        transform: rotate(90deg);
        opacity: 1;
      }
      strong {
        @include media-breakpoint-up(lg) {
          margin-right: 12rem;
        }
      }

      &[aria-expanded='true'] .open {
        display: none;
      }
      &[aria-expanded='false'] .close {
        display: none;
      }
      span {
        &:after {
          content: '➜';
          color: $green;
          opacity: 1;
          font-size: 1.25rem;
        }
      }
    }
  }

  img {
    max-width: 100%;
    height: auto !important;
    display: block;
  }
}
