.topics {
  .row {
    > div > a:first-child {
      margin-bottom: 8px;
    }
  }

  ul {
    margin-left: 0;
  }

  li {
    &.py-1 {
      margin-bottom: 0.25rem;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
