.component-full-width-cta-banner {
  .container {
    background-size: cover;

    @include media-breakpoint-up(xl) {
      min-height: 570px;
    }

    @include media-breakpoint-between(md, lg) {
      min-height: 350px;
      &.desktop-left {
        background-position: 100% 50% !important;
      }
      &.desktop-right {
        background-position: 0% 50% !important;
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .content {
        @include media-breakpoint-up(xl) {
          margin-left: calc(100% - 33.5rem);
        }
      }
      &.desktop-right {
        .cta-banner {
          flex-direction: row-reverse;
        }
        .content {
          @include media-breakpoint-up(xl) {
            margin-right: calc(100% - 33.5rem);
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      ul {
        font-size: 0.875rem;
      }
    }
    @include media-breakpoint-down(sm) {
      &.mobile-bottom {
        display: flex;
        .row {
          flex-direction: column-reverse;
        }
        .section-content div:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
    .text-white,
    &.text-white {
      .lead {
        color: $gray-400;
      }
      .btn {
        color: $white;
      }
    }
  }

  .section-content {
    @include tablet {
      padding-left: $contentPaddingTablet;
      padding-right: $contentPaddingTablet;
    }

    @include tabletPortrait {
      padding-left: $contentPaddingTabletPortrait;
      padding-right: $contentPaddingTabletPortrait;
    }

    @include mobile {
      padding-left: $contentPaddingMobile;
      padding-right: $contentPaddingMobile;
    }
  }

  .mobile-top .section-content {
    @include tabletPortrait {
      padding-top: $sectionPaddingTabletPortrait !important;
      padding-bottom: 0 !important;
    }

    @include mobile {
      padding-top: $sectionPaddingMobile !important;
    }
  }

  .mobile-bottom .section-content {
    @include tabletPortrait {
      padding-top: 0 !important;
      padding-bottom: $sectionPaddingTabletPortrait !important;
    }

    @include mobile {
      padding-bottom: $sectionPaddingMobile !important;
    }
  }

  .section-cta {
    padding-top: 10px;
  }
}

.full-width-bg {
  background-color: transparent !important;

  &.text-white {
    background-color: #1b1f1f !important;
  }
}
