.blog-top {
  margin-top: 30px;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .breadcrumb-wrapper {
    padding-top: 0;
    margin-top: 0;
  }
}

.blog-share {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  strong {
    margin-right: 10px;
    position: relative;
  }
}

.a2a_kit {
  a {
    float: left;
    line-height: 16px;
    padding: 0 2px;
  }
}

.newsroom-meta {
  color: #5a5a5a;
  margin-top: 15px;
  font-size: 0.92rem;
}

@media (max-width: 930px) {
  .blog-top {
    .content-wrapper {
      flex-wrap: wrap;
    }

    .breadcrumb-wrapper {
      width: 100%;
    }
  }

  .blog-share {
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: -10px;
  }
}

@include tabletPortrait {
  .blog-top {
    margin-top: 10px;
  }
}

@media (max-width: 620px) {
  .blog-share {
    margin-left: -2px;
    margin-bottom: 0;
    margin-top: 15px;

    strong {
      display: none;
    }
  }
}

#blog-intro {
  margin-bottom: 35px;
}

// Article Styles
.blog-article {
  .category {
    margin-bottom: 20px;
    display: inline-block;
    font-size: 14px;
    padding-bottom: 4px;
  }

  .article-data {
    font-weight: 600;
    padding-bottom: 6px;
    padding-left: 6px;
  }
}

.post-meta {
  font-size: 13px;
  font-weight: 500;
  color: #6f6f6f;
  margin-top: 10px;
}

.blog-section {
  --content-max-width: calc(var(--max-width, 780px) + var(--content-padding) * 2);
}

.ktc-section:has(.blog-section) {
  margin-bottom: var(--section-padding);

  &:last-child {
    margin-bottom: 0;
  }
}

.post-content-wrapper {
  position: relative;
}

.post-content {
  --section-spacing: 40px;

  line-height: 1.6;
  font-size: 16px;

  > .section {
    &:first-child {
      padding-top: 0;
    }

    &:last-child:not(.testimonial) {
      padding-bottom: 0;
    }
  }

  .blog-section {
    margin-top: var(--section-spacing);
    margin-bottom: var(--section-spacing);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .btn-print {
      --btn-margin: 35px;

      margin-top: var(--btn-margin);
      margin-bottom: var(--btn-margin);

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 550px) {
        --btn-margin: 30px;
      }
    }
  }

  .section-placeholder {
    color: #9f9f9f !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--section-spacing);
    margin-bottom: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 24px;
    font-family: $bodyFont;
    letter-spacing: 0 !important;
    font-weight: 600;
    line-height: 1.25;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 12px;
    font-family: $bodyFont;

    + ol,
    + ul {
      margin-top: 20px;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 10px;
  }

  h5 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
  }

  p,
  li {
    // font-size: 1rem;
    font-size: inherit;
  }

  p {
    margin-bottom: 15px;
  }

  ul,
  ol {
    margin-bottom: 18px;
    margin-left: 20px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 18px;

    ul {
      margin-top: 12px;
    }

    &:not(:last-child) {
      > ul {
        padding-bottom: 10px;
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  blockquote {
    margin: 85px 0 50px 0;
    font-family: var(--font-heading);
    font-size: 24px;
    line-height: 1.6;
    text-wrap: balance;
    position: relative;

    blockquote {
      margin: 0 !important;
    }

    &:first-child {
      margin-top: 45px;
    }

    @at-root .post-content > div:first-child blockquote:first-child {
      margin-top: 85px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='32' fill='none' viewBox='0 0 40 32'%3E%3Cpath fill='%23EEB123' d='m39.52.934.128.256c-4.864 2.688-7.936 8.192-7.936 13.312 0 .896.384 1.28 1.408 1.408 3.712.512 6.528 3.712 6.528 7.936 0 4.352-3.328 7.936-7.936 7.936-4.992 0-8.704-3.584-8.704-9.472 0-8.832 6.4-18.688 16.512-21.376Zm-22.016 0 .128.256c-4.864 2.688-7.936 8.192-7.936 13.312 0 .896.256 1.28 1.408 1.408 3.712.512 6.528 3.712 6.528 7.936 0 4.352-3.456 7.936-8.064 7.936-4.864 0-8.704-3.584-8.704-9.472 0-8.832 6.528-18.688 16.64-21.376Z'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      width: 35px;
      display: block;
      height: 35px;
      top: -2px;
      left: 7px;
      transform: translate(-50%, -100%);
      pointer-events: none;
    }

    p {
      font-family: inherit;
      margin-bottom: 0.7em;

      + cite {
        margin-top: -2px;
      }
    }
  }

  cite {
    font-family: var(--font-body);
    font-style: normal;
    color: #707070;
    font-size: 17px;
    line-height: 1.2;
    display: block;

    &:before {
      content: '— ';

      @at-root .tox-menu & {
        display: none;
      }
    }
  }

  @include tablet {
    --section-spacing: 35px;
  }

  @include tabletPortrait {
    margin-top: 20px;

    // h2 {
    //   font-size: 31px;
    // }

    // h3 {
    //   font-size: 25px;
    // }
  }
}

.blog-column {
  max-width: 880px;
}

.blog-callout-box {
  padding: 35px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin: 30px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.25em;
    margin-bottom: 15px;

    &:first-child {
      margin-top: -0.1em;
    }
  }
}

.blog-box-wrapper {
  --blog-box-gap: 30px;
  --bg-color: var(--blog-box-color, #f9f9f9);

  .content-wrapper {
    display: flex;

    @at-root .ktc-section & {
      display: block;
    }
  }

  .blog-box {
    background-color: var(--bg-color);
    flex-grow: 1;
    width: 100%;
    padding: 35px;
    border-radius: 5px;
    margin-right: var(--blog-box-gap);

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .ktc-widget-zone {
    display: flex;
    gap: var(--blog-box-gap);
  }

  .ktc-admin-ui {
    display: contents;
  }

  .ktc-widget {
    flex-grow: 1;
    width: 100%;
    background-color: var(--bg-color);

    .blog-box {
      border-radius: 0;
    }
  }

  @media (max-width: 820px) {
    .content-wrapper,
    .ktc-widget-zone {
      flex-direction: column;
    }

    .blog-box {
      margin-right: 0;
      margin-bottom: var(--blog-box-gap);
    }
  }
}

#blog-back {
  padding-top: 0;
  border-top: 1px solid transparent;

  .content {
    border-top: 1px solid #e1e1e1;
    padding-top: 30px;

    a {
      font-size: inherit !important;
    }
  }
}

.blog-article {
  iframe,
  .mce-preview-object.mce-object-iframe {
    width: 100%;
    display: block;
    margin: 2.3em 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  iframe {
    height: 315px;

    @media (min-width: 881px) {
      height: 455px;
    }
  }

  .mce-preview-object.mce-object-iframe {
    iframe {
      margin: 0;
      pointer-events: none;
    }
  }
}

.blog-bottom {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: var(--section-padding);
  }

  @media (max-width: 585px) {
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    .section-top {
      margin-bottom: 35px;
    }
  }
}

.blog-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;

  .ltr-underline,
  .link-underline-left {
    display: flex !important;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 0;

      &:after {
        top: 0.12em;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .ltr-underline {
    text-wrap: balance;
    justify-content: flex-end;
    text-align: right;

    span {
      margin-left: 0.5em;
    }
  }

  .link-underline-left {
    span {
      margin-right: 0.5em;
    }
  }

  @media (min-width: 585px) {
    border-top: 1px solid #dedede;
    padding-top: 20px;
  }

  @media (max-width: 584px) {
    @at-root #main & {
      display: block;
      font-size: 16px;

      .ltr-underline,
      .link-underline-left {
        text-align: left;
        justify-content: space-between;
        padding: 15px 0 14px 0;
        border-bottom: 1px solid #dedede;

        &:first-child {
          border-top: 1px solid #dedede;
        }

        &:after {
          display: none !important;
        }

        span {
          transform: none !important;
          margin-right: 0 !important;
          margin-left: 0.5em;

          &:after {
            transform: none !important;
            margin: 0;
            transition: none;
          }
        }
      }

      .link-underline-left {
        span {
          order: 2;
        }
      }
    }
  }
}

// Table of contents
.post-toc {
  margin: 45px 0;
  background-color: #fdfdfd;
  border: 1px solid #e3e4e8;
  border-radius: 5px;
  padding: 37px 40px 34px 40px;
}

.post-toc-title {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  line-height: 1;
  align-items: center;
  margin-bottom: 30px;

  &:before {
    content: '';
    background-color: var(--toc-icon-color, #8c8c8c);
    display: block;
    width: var(--toc-icon-size, 1.1875em);
    height: var(--toc-icon-size, 1.1875em);
    flex: none;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 19 19'%3E%3Cpath fill='%238C8C8C' fill-rule='evenodd' d='M9.492 3.958a.8.8 0 0 1 .8-.8h6.333a.8.8 0 0 1 0 1.6h-6.333a.8.8 0 0 1-.8-.8M9.492 7.125a.8.8 0 0 1 .8-.8h3.958a.8.8 0 0 1 0 1.6h-3.958a.8.8 0 0 1-.8-.8M9.492 11.875a.8.8 0 0 1 .8-.8h6.333a.8.8 0 0 1 0 1.6h-6.333a.8.8 0 0 1-.8-.8M9.492 15.042a.8.8 0 0 1 .8-.8h3.958a.8.8 0 0 1 0 1.6h-3.958a.8.8 0 0 1-.8-.8M1.575 4.167a1.8 1.8 0 0 1 1.8-1.8h2.75a1.8 1.8 0 0 1 1.8 1.8v2.75a1.8 1.8 0 0 1-1.8 1.8h-2.75a1.8 1.8 0 0 1-1.8-1.8zm1.8-.2a.2.2 0 0 0-.2.2v2.75c0 .11.09.2.2.2h2.75a.2.2 0 0 0 .2-.2v-2.75a.2.2 0 0 0-.2-.2zM1.575 12.083a1.8 1.8 0 0 1 1.8-1.8h2.75a1.8 1.8 0 0 1 1.8 1.8v2.75a1.8 1.8 0 0 1-1.8 1.8h-2.75a1.8 1.8 0 0 1-1.8-1.8zm1.8-.2a.2.2 0 0 0-.2.2v2.75c0 .11.09.2.2.2h2.75a.2.2 0 0 0 .2-.2v-2.75a.2.2 0 0 0-.2-.2z' clip-rule='evenodd'/%3E%3C/svg%3E");
    margin-right: var(--toc-icon-margin, 10px);
  }
}

.post-toc-items {
  --item-margin: 14px;

  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 15px;

  li {
    padding: var(--item-margin) 0;
    border-bottom: 1px dotted #ced0d5;
    margin: 0 !important;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  a {
    text-underline-offset: 2px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.post-toc-intro {
  margin-top: 35px;
  font-size: 16px;
}

@mixin isReadingArticle {
  @at-root .post-side-toc-wrapper.has-started:not(.has-finished) & {
    @content;
  }
}

.post-side-toc-wrapper {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: var(--header-height, 0px);
  bottom: 0;
  display: flex;
  z-index: 500;
  display: none;

  &.initialized {
    display: block;
  }

  @at-root body.is-mobile & {
    display: none !important;
  }

  @include pageBuilder {
    display: none !important;
  }
}

.post-side-toc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 600;

  @include isReadingArticle {
    pointer-events: auto;
  }
}

.post-toc-progress {
  --transition-duration: 0.1s;
  --toc-icon-size: 19px;
  --toc-icon-color: #97989e;

  margin-left: 20px;
  border-radius: 10px;
  padding: 25px 25px 30px 25px;
  align-content: center;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-duration),
    visibility step-end var(--transition-duration);

  @include isReadingArticle {
    --transition-duration: 0.3s;

    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition-duration),
      visibility step-start var(--transition-duration);
  }

  @at-root .post-side-toc:hover & {
    opacity: 0 !important;
  }

  &:before {
    content: '';
    background-color: var(--toc-icon-color, #8c8c8c);
    display: block;
    width: var(--toc-icon-size, 1.1875em);
    height: var(--toc-icon-size, 1.1875em);
    flex: none;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 19 19'%3E%3Cpath fill='%238C8C8C' fill-rule='evenodd' d='M9.492 3.958a.8.8 0 0 1 .8-.8h6.333a.8.8 0 0 1 0 1.6h-6.333a.8.8 0 0 1-.8-.8M9.492 7.125a.8.8 0 0 1 .8-.8h3.958a.8.8 0 0 1 0 1.6h-3.958a.8.8 0 0 1-.8-.8M9.492 11.875a.8.8 0 0 1 .8-.8h6.333a.8.8 0 0 1 0 1.6h-6.333a.8.8 0 0 1-.8-.8M9.492 15.042a.8.8 0 0 1 .8-.8h3.958a.8.8 0 0 1 0 1.6h-3.958a.8.8 0 0 1-.8-.8M1.575 4.167a1.8 1.8 0 0 1 1.8-1.8h2.75a1.8 1.8 0 0 1 1.8 1.8v2.75a1.8 1.8 0 0 1-1.8 1.8h-2.75a1.8 1.8 0 0 1-1.8-1.8zm1.8-.2a.2.2 0 0 0-.2.2v2.75c0 .11.09.2.2.2h2.75a.2.2 0 0 0 .2-.2v-2.75a.2.2 0 0 0-.2-.2zM1.575 12.083a1.8 1.8 0 0 1 1.8-1.8h2.75a1.8 1.8 0 0 1 1.8 1.8v2.75a1.8 1.8 0 0 1-1.8 1.8h-2.75a1.8 1.8 0 0 1-1.8-1.8zm1.8-.2a.2.2 0 0 0-.2.2v2.75c0 .11.09.2.2.2h2.75a.2.2 0 0 0 .2-.2v-2.75a.2.2 0 0 0-.2-.2z' clip-rule='evenodd'/%3E%3C/svg%3E");
    margin-bottom: 25px;
  }
}

.toc-sentinel {
  position: absolute;
  left: 0;
  width: 1px;
  height: 1px;
  visibility: hidden;
}

.toc-sentinel-top {
  top: 0;
}

.toc-sentinel-bottom {
  bottom: -10vh;
}

.post-toc-progress-item {
  --line-color: #d9d9d9;
  --line-width: 3px;
  --circle-size: 10px;

  height: 3px;
  width: 46px;
  margin-bottom: 25px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
  }

  &:before {
    width: var(--circle-size);
    height: var(--circle-size);
    border: calc(var(--line-width) - 1px) solid var(--line-color);
    transform: translateY(-50%) scale(0.9);
    transform-origin: center center;
    border-radius: 50%;
    background-color: var(--line-color);
    z-index: 10;
  }

  &:after {
    height: var(--line-width);
    background-color: var(--line-color);
    width: 25px;
    border-radius: 0 10px 10px 0;
    left: calc(var(--circle-size) - 2px);
    transform: translateY(-50%);
    transition: width 0.25s cubic-bezier(0.34, 0.72, 0.49, 1);
  }

  &.active {
    --line-color: var(--teal-color);

    &:before {
      background-color: #fff;
      box-shadow: 0 0 0 1px var(--line-color);
      transform: translateY(-50%) scale(1);
    }

    &:after {
      width: calc(100% - var(--circle-size) + 2px);
    }
  }
}

.post-toc-overlay {
  --overlay-offset: 35px;
  --padding: 36px;
  --slide-in: cubic-bezier(0.19, 0.77, 0.31, 0.97);
  --slide-out: cubic-bezier(0.27, 0.04, 0.14, 1);
  --slide-in-duration: 0.3s;
  --slide-out-duration: 0.25s;

  position: absolute;
  top: 50%;
  width: calc(((100vw - 780px) / 2) - (var(--overlay-offset) * 2));
  max-width: max-content;
  min-width: 350px;
  left: var(--overlay-offset);
  padding: var(--padding);
  background-color: #fff;
  border-radius: 7px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  // box-shadow: 0px 20px 80px 0px rgba(43, 50, 82, 0.04),
  //   0px 13px 47px 0px rgba(43, 50, 82, 0.03), 0px 7px 25px 0px rgba(43, 50, 82, 0.02),
  //   0px 4px 13px 0px rgba(43, 50, 82, 0.02), 0px 1px 7px 0px rgba(43, 50, 82, 0.02),
  //   0px 1px 3px 0px rgba(43, 50, 82, 0.01);
  box-shadow: 0 13px 42px 0 rgba(43, 50, 82, 0.08), 0 7px 25px 0 rgba(43, 50, 82, 0.02),
    0 4px 13px 0 rgba(43, 50, 82, 0.02), 0 1px 7px 0 rgba(43, 50, 82, 0.02),
    0 1px 3px 0 rgba(43, 50, 82, 0.01);
  z-index: 550;
  transform: translate(calc(-100% - var(--overlay-offset) - 30px), -50%);
  // visibility: hidden;
  transition: transform var(--slide-out-duration) var(--slide-out);
  will-change: transform;
  // will-change: transform, visibility;
  // transition: transform var(--slide-out-duration) var(--slide-out),
  //   visibility step-end var(--slide-out-duration);

  @at-root .post-side-toc:hover & {
    transform: translate(0px, -50%);
    transition: transform var(--slide-in-duration) var(--slide-in);
    // visibility: visible;
    // transition: transform var(--slide-in-duration) var(--slide-in),
    //   visibility step-start var(--slide-in-duration);
  }

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: calc(var(--overlay-offset) * -1);
    z-index: -1;
  }

  .post-toc-title {
    font-size: 15px;
    margin-bottom: 25px;
  }

  .post-toc-items {
    font-size: 14px;
  }

  a {
    color: #747579;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      bottom: 3px;
      left: calc(var(--padding) / -2 + 2px);
      width: 2px;
      border-radius: 5px;
      background-color: var(--teal-color);
      transform: scaleY(0);
      opacity: 0;
      transition: all 0.2s ease;
      transition-property: transform, opacity;
    }

    &.active {
      color: var(--teal-color);

      &:before {
        transform: scaleY(1);
        opacity: 1;
      }
    }
  }
}

@media (max-width: 1055px) {
  .post-side-toc-wrapper {
    display: none !important;
  }
}

@media (max-width: 750px) {
  .post-toc {
    padding: 32px 35px 29px 35px;
  }

  .post-toc-title {
    margin-bottom: 25px;
  }

  .post-toc-items {
    --item-margin: 12px;
  }
}

@media (max-width: 500px) {
  .post-toc {
    margin: 35px 0;
    padding: 27px 30px 24px 30px;
  }

  .post-toc-intro {
    margin-top: 25px;
  }

  .post-toc-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {
  .post-toc {
    padding: 22px 25px 19px 25px;
  }
}

// Temporarily hide post meta until
// component is updated to make optional
.blog-article .post-meta {
  display: none;
}
