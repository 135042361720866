.header-logo {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: center;
  width: 270px;
  height: auto;
}

// Navigation
.navbar-wrapper {
  @include contentPadding;
  max-width: $contentMaxWidth;
  margin: 0 auto;

  .navbar {
    height: $menuHeight;
    display: flex;
    align-items: stretch;
  }

  a.navbar-brand {
    padding: 0;

    svg {
      fill: initial !important;
    }
  }

  .main-nav > .nav-container > .navbar-nav,
  .utility-nav > .navbar-nav {
    > li {
      font-weight: 600 !important;
      text-transform: uppercase;

      > a {
        &:not(.btn) {
          font-weight: 600 !important;
        }
      }

      .dropdown-menu {
        text-transform: none;
        font-weight: 500;
        top: 100%;
        margin-top: 0;
      }
    }
  }

  .nav-link {
    position: relative;

    &:after {
      position: absolute !important;
      left: 8px;
      right: 6px;
      width: auto !important;
    }
  }

  .utility-nav {
    position: relative;

    > .navbar-nav {
      align-items: center;
    }

    .nav-link {
      &:after {
        left: 7px;
        right: 6px;
      }
    }

    li > .svg-icon {
      width: 1.2em;
      height: 1.2em;
      margin-top: 0;
      // margin-right: 0.15em;
      margin-right: -3px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  @at-root .homepage-hero {
    margin-top: -$menuHeight;
    padding-top: $menuHeight - 27px;
  }

  @media (max-width: #{$contentMaxWidth}) {
    padding-left: 25px;
    padding-right: 20px;
    max-width: none;
  }

  @media (max-width: 1180px) and (min-width: #{$desktopMinWidth}) {
    $menuHeight: $menuHeightLarge;
    padding-left: 30px;
    padding-right: 25px;

    .navbar {
      height: $menuHeight;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 25px !important;
    }

    .logo {
      position: relative;
      z-index: 900;
      margin-bottom: 5px;
    }

    #main-nav-wrapper {
      width: 100%;
    }

    .main-nav {
      flex-grow: 1;
      margin-top: 0 !important;
      margin-left: -10px;

      .dropdown {
        .dropdown-menu {
          top: 100%;
          margin-top: 0;

          .m-lg-5 {
            margin: 10px !important;
          }
        }
      }
    }

    .utility-nav {
      margin-top: 0 !important;
      top: -30px;
    }

    @at-root .homepage-hero {
      margin-top: -$menuHeight;
      padding-top: $menuHeight - 30px;
    }
  }

  @include desktop {
    .main-nav > .nav-container > .navbar-nav,
    .utility-nav > .navbar-nav {
      > li {
        font-size: 13px;
        margin: 0 0.15rem !important;

        > a {
          padding-top: 10px;
          padding-bottom: 10px;

          &:not(.btn) {
            font-size: inherit !important;
          }
        }
      }
    }

    .main-nav,
    .utility-nav {
      margin-top: 0 !important;
    }

    .main-nav {
      .dropdown {
        .list-unstyled:not(:first-child) {
          margin-top: 12px !important;
        }

        .header-nav > li > a {
          font-weight: 600 !important;
        }

        a {
          font-weight: 500 !important;
          font-size: 0.95rem !important;
        }

        // Add a pseudoelement to increase the
        // hover target area
        &:hover {
          .dropdown-menu {
            &:before {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              height: 30px;
              top: -30px;
            }
          }
        }
      }
    }

    .utility-nav {
      margin-left: 5px;

      .btn-primary {
        margin-top: 0;
      }

      .navbar-nav {
        li:last-child {
          margin-right: 0 !important;
        }
      }
    }

    .search-trigger {
      width: 35px;
      height: 35px;
      padding: 0 !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none !important;
      margin-right: 5px;
      margin-left: -4px;
      margin-top: -1px;

      svg {
        width: 17px;
        height: 17px;
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: -1px;
        margin-top: -1px;
      }
    }
  }
}

#main-nav-wrapper {
  flex-grow: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 550px) {
  .navbar-wrapper {
    .navbar {
      height: $menuHeightMobile;

      @at-root .homepage-hero {
        margin-top: -$menuHeightMobile;
        padding-top: $menuHeightMobile - 27px;
      }
    }
  }

  .header-logo {
    width: 240px !important;
  }
}
