.header-section {
  background-color: white;
  background-size: cover;
  z-index: 1040;

  &.fixed-header {
    position: sticky;
    top: 0;
    box-shadow: 0px 1px 6px rgba(#000, 0.04), 0px 10px 11px rgba(#000, 0.01);
  }

  &.content-cover-bg {
    background-size: cover !important;
  }

  &.content-tile-bg {
    background-size: initial;
    background-repeat: repeat;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .header-with-phone {
    justify-content: space-between;
    align-items: center;
  }

  .header-logo-wrapper {
    &.logo-with-text {
      display: flex;
      align-items: flex-end;
    }

    .header-logo {
      width: 270px;
      height: auto;
    }

    .header-logo-rev {
      display: none;
    }

    .trusted-text {
      margin-left: 15px;
      padding-left: 10px;
      border-left: 2px solid #eeb123;
      text-transform: uppercase;
      color: #4c4c4c;
    }
  }

  .header-logo-link {
    &:hover {
      text-decoration: none;
    }
  }

  .header-phone-wrapper {
    font-size: 1.3rem;

    .desktop-phone {
      width: 1.2em;
      height: 1.2em;
      margin-top: 0;
      flex-grow: 0;
      flex-shrink: 0;
    }

    svg,
    path {
      fill: #4c4c4c;
    }
  }

  &.text-white {
    a {
      color: #fff;
    }

    .header-logo-wrapper {
      .header-logo-default {
        display: none;
      }

      .header-logo-rev {
        display: block;
      }

      .trusted-text {
        color: #fff;
      }
    }

    .header-phone-wrapper {
      svg,
      path {
        fill: #fff;
      }
    }
  }
}

@media (max-width: 750px) {
  .header-section {
    .header-logo-wrapper {
      .header-logo {
        width: 245px;
      }
    }

    .header-phone-wrapper {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 700px) {
  .header-section {
    .phone-and-text {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .header-logo-wrapper {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 540px) {
  .header-section {
    .header-with-phone {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .header-logo-wrapper {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .header-section {
    .header-logo-wrapper {
      &.logo-with-text {
        flex-direction: column;
        align-items: center;
      }

      .trusted-text {
        margin-top: 15px;
      }
    }
  }
}

.KenticoAdminPageBuilder {
  header {
    .ktc-admin-ui kentico-widget-header {
      left: 46px;
      width: calc(100% - 46px);

      &.ktc-widget-header--bottom {
        .ktc-widget-header {
          border-radius: 0px 0px 8px 8px;
        }
      }
    }

    .ktc-widget {
      kentico-add-component-button {
        display: none;
      }
    }
  }
}
