.mkto-form-wrapper {
  margin-left: auto;
  margin-right: auto;
  min-height: 42px;

  @at-root .content-col & {
    margin-left: 0;
    margin-right: 0;
  }

  @include pageBuilder {
    max-width: none !important;
  }
}

.mkto-form {
  width: 100% !important;
  visibility: hidden;

  &[data-styles-ready='true'] {
    visibility: visible;
  }

  &:not([data-styles-ready='true']) * {
    position: absolute;
    visibility: hidden;
  }

  button[type='submit'] {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
  }

  .mktoError {
    padding-top: 6px;
    padding-left: 4px;
    color: #991010;
    font-weight: 500;
    font-size: 0.9rem;
  }
}

.mktoFormRow {
  margin-bottom: 22px;

  input,
  select {
    width: 100% !important;
    border: 1px solid #d7d9dd;
    border-radius: 2px;
    padding: 9px 11px;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(22, 77, 77, 0.6);
    }
  }

  input,
  select,
  radio,
  label {
    color: #4c4c4c;
  }

  input,
  select,
  radio {
    font-size: 1rem;
    font-weight: 500;
  }
}

.mktoAsterix {
  display: inline-block;
  padding-right: 3px;
}

.mkto-newsletter {
  display: flex;
  min-height: 42px;

  label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  input {
    font-size: 0.9rem;
    border: none;
    padding: 0.615rem 1rem;
    padding-right: 0px;
    width: 100%;
    height: 100%;
    border: 1px solid #d7d9dd;
    border-right: none;
    font-weight: 500;
    color: #4c4c4c;
    border-radius: 0px;
    &:focus-visible {
      outline: none !important;
    }
  }

  button[type='submit'] {
    font-size: 0.85rem;
    flex-shrink: 0;
    white-space: nowrap;
    padding-top: 0.615rem;
    padding-bottom: 0.615rem;
  }

  .mktoFormRow {
    position: relative;
    margin-bottom: 0px;

    &:nth-child(2) {
      flex: 1;

      .mktoFormCol {
        height: 100%;
      }

      .mktoFieldWrap {
        height: 100%;
      }
    }
  }

  .mktoErrorMsg {
    display: block;
    margin-top: 7px;
    border-radius: 5px;
    line-height: 1.2em;
    max-width: none;
    text-align: left;
    background-color: rgba(62, 79, 92, 0.73);
    color: white;
    font-size: 13px;
    padding: 8px 12px;
  }

  .mktoError {
    position: absolute;
    top: 100%;
    bottom: auto !important;
    left: 0px;
    right: auto !important;
    width: auto;
    padding-top: 0px;
    padding-left: 0px;
    color: white;
  }

  .mktoErrorArrowWrap {
    display: none;
  }
}

.mkto-message-wrapper {
  display: flex;
  justify-content: center;
}

.mkto-message {
  background-color: rgba(62, 79, 92, 0.73);
  color: white;
  border-radius: 5px;
  padding: 10px 18px;
  text-align: center;
  font-size: 16px;
}
