.quick-links-bar {
  border-top: 2px solid $gray-200;
  border-bottom: 2px solid $gray-200;
  box-shadow: 0 0.75rem 1rem -1rem #dadada;

  h2 {
    white-space: nowrap;
  }
  .icon-links-list {
    font-size: 0.875rem;
    li {
      display: flex;
      align-items: start;
      min-width: 12rem;
      @include media-breakpoint-down(sm) {
        border-bottom: 1px solid $gray-400;
      }
      &:last-of-type {
        @include media-breakpoint-down(sm) {
          border-bottom: none;
        }
      }
      &:hover {
        svg {
          color: $green;
          & * {
            color: $green;
            fill: $green;
          }
        }
      }
      .svg-wrapper {
        margin-right: 0.4rem;
        svg {
          margin-right: 0;
        }
      }
      svg {
        width: 2rem;
        height: 2rem;
        margin-right: 0.4rem;
        color: $gray-700;
        @include media-breakpoint-down(sm) {
          margin-right: 0.75rem;
        }

        & * {
          color: $gray-700;
          fill: $gray-700;
        }
      }
      img {
        width: 2rem;
        margin-right: 0.4rem;
        @include media-breakpoint-down(sm) {
          margin-right: 0.75rem;
        }
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
}
