/* --------------------------- */
/* MS Bookings Pages */
/* --------------------------- */

@include targetIE {
  .ie-banner {
    background-color: #fef0f0;

    .content-wrapper {
      max-width: 1100px;
    }
  }

  .ie-banner-content {
    display: flex;
    padding: 45px 0px 40px 0px;
  }

  .ie-banner-img {
    img {
      width: 75px;
      height: 75px;
    }
  }

  .ie-banner-text {
    padding-left: 30px;

    h2 {
      font-size: 28px;
      color: #5a2929;
    }

    p {
      color: rgba(90, 41, 41, 0.9);
      margin-top: 4px;
    }
  }

  @media (max-width: 560px) {
    .ie-banner-content {
      padding: 35px 0px 30px 0px;
    }

    .ie-banner-text {
      padding-left: 0px;
    }

    .ie-banner-img {
      display: none;
    }
  }
}
