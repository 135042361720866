.homepage-hero {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 580px;

  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 400px;
    z-index: 2;
    opacity: 0.5;
    @include smoothGradient(#fff, 120deg);
  }

  .content-wrapper {
    position: relative;
    z-index: 10;
  }

  .content {
    width: calc(100% - 470px);
  }

  h1 {
    font-size: 4.3rem;
    letter-spacing: -0.008em !important;
    margin-bottom: 0;
    margin-top: -0.26em;
    color: #3e3e3e;
  }

  .text {
    margin-top: 20px;
    font-size: 1.3rem;

    &:first-child {
      margin-top: 0;
    }

    p {
      font-size: inherit;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .description {
    max-width: 600px;
  }

  .subhead {
    font-size: 1.12em;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    margin-top: 10px;
  }

  .button-wrapper {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }

    a {
      font-size: 0.95rem;
      padding: 0.7em 1.5em;
    }
  }

  .mobile-hero-utility {
    display: none;
  }

  @media (max-width: 1380px) {
    .content {
      padding-left: 40px;
    }

    h1 {
      font-size: 3.8rem;
    }

    .description {
      font-size: 1.2rem;
      max-width: 530px;
    }
  }

  @media (max-width: 1200px) {
    background-position: top right -290px;
    min-height: 520px;

    .content {
      padding-left: 20px;
    }
  }

  @media (max-width: 1090px) {
    background-position: top right -280px;
    min-height: 490px;

    .content {
      padding-left: 0;
    }

    h1 {
      font-size: 3.7rem;
    }

    .description {
      font-size: 1.14rem;
      max-width: 500px;
    }

    .button-wrapper {
      margin-top: 25px;

      a {
        font-size: 0.85rem;
        padding: 0.7em 1.5em;
      }
    }
  }

  @media (max-width: 1040px) {
    background-position: top right -270px;
    min-height: 450px;

    .content {
      width: calc(100% - 350px);
    }

    h1 {
      font-size: 3.5rem;
    }
  }

  c {
    background-position: top right -50vw;
    background-size: auto calc(100% + 70px);
    min-height: 0;

    .content {
      width: calc(100% - 250px);
      padding: 20px;
    }

    .description {
      font-size: 1.1rem;
      max-width: none;
    }
  }

  @media (max-width: 870px) {
    background-size: cover;
    background-position: bottom center;
  }

  @include tabletPortrait {
    background-position: bottom right -280px;

    &:before {
      opacity: 0.8;
      right: 200px;
      @include smoothGradient(#fff, 'to right');
    }

    .content {
      width: calc(100% - 230px);
      padding: 20px 0;
    }

    h1 {
      font-size: 2.5rem;
    }

    .text {
      margin-top: 10px;
    }

    .description {
      max-width: 440px;
      font-size: 1.05rem;
    }

    .button-wrapper {
      a {
        font-size: 0.8rem;
      }
    }
  }

  @media (max-width: 590px) {
    background-position: bottom -60px center;

    .content {
      width: 100%;
      margin-bottom: 210px;
    }

    .description {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    background-position: bottom center;

    &:before {
      right: 0;
      bottom: 200px;
      @include smoothGradient(#fff, 'to bottom');
    }
  }

  @media (max-width: 450px) {
    .mobile-hero-utility {
      display: flex;
      justify-content: space-between;
      margin-top: -5px;
      margin-bottom: 10px;
    }

    .mobile-phone,
    .mobile-login {
      display: flex;
      align-items: center;
      font-weight: 600;
      text-transform: uppercase;
      color: #4c4c4c;
      font-size: 15px;
      padding: 8px 12px;
      line-height: 1;

      svg {
        width: 17px;
        height: 17px;
        margin-right: 6px;
      }

      path {
        fill: currentColor;
      }
    }

    .mobile-login {
      @include disableNativeButton;
      margin-right: -20px;
    }

    .mobile-phone {
      margin-left: -15px;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        top: 1px;
        position: relative;
      }
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 2.15rem;
    }

    .content {
      margin-bottom: 150px;
    }

    .button-wrapper {
      a {
        width: 100%;
      }
    }
  }
}

.dark-bg-adjustment {
  .homepage-hero {
    color: $white;

    h1 {
      color: $white;
    }
  }
}
