.abstract-modal {
  .modal-title {
    font-family: $headingFont;
    font-weight: 500;
  }
}

.video-modal {
  .embed-responsive {
    background-color: #000;
  }
}
